import classes from './style/EditedRequestInfo.module.scss';
import { Request } from './types/type';
import { useState } from 'react';
import Modal from '../../../components/base/Modal';
import { useTranslation } from '../../../providers/locale-provider';
import minus from '../../../assets/image/Icons/minus.svg';
import info from '../../../assets/image/Icons/info-orange.svg';
import BaseButton from '../../../components/base/BaseButton';
import { convertTo24HourTime } from '../../../core/helpers/FormatDate';
import { different } from '../../../core/helpers/FormatDate';

interface IEditedRequestInfo {
  request: Request;
}
const EditedRequestInfo = ({ request }: IEditedRequestInfo) => {
  const { t9n } = useTranslation();
  const [editedInfoModal, setEditedInfoModal] = useState(false);

  const convertEpochToDate = (epochTime: number): string => {
    const date = new Date(epochTime * 1000);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Format the date as "YYYY-M-D"
    return `${year}-${month}-${day}`;
  };

  const dayDuration = (start: number, end: number): number => {
    return Math.floor((start - end) / 86400) + 1;
  };

  return (
    <>
      {request.edited && (
        <img onClick={() => setEditedInfoModal(true)} className={classes.infoCircle} src={info} alt="info" />
      )}
      {editedInfoModal && (
        <Modal
          header={t9n.details_text}
          exitAction={() => setEditedInfoModal(false)}
          size="ml"
          body={
            <div className={`w-100`}>
              <div className={`d-flex gap-3`} key={1}>
                <p className={classes.title}>{t9n.previous_time_off_request_date} :</p>
                <span className={classes.content}>
                  {request.parent.type === 'daily' ? 'From' : 'On'}
                  {' ' + convertEpochToDate(+request.parent.start_datetime)}
                  {request.parent.type === 'daily' ? ' for ' : ' from '}
                  {request.parent.type === 'daily'
                    ? dayDuration(+request.parent.end_datetime, +request.parent.start_datetime) +
                      (dayDuration(+request.parent.end_datetime, +request.parent.start_datetime) === 1
                        ? ' day'
                        : ' days')
                    : convertTo24HourTime(request.parent.start_datetime) +
                      ' to ' +
                      convertTo24HourTime(request.parent.end_datetime)}
                </span>
              </div>
              <div className={`d-flex gap-3`} key={2}>
                <p className={classes.title}>{t9n.new_time_off_request_date} :</p>
                <span className={classes.content}>
                  {request.type === 'daily' ? 'From' : 'On'}
                  {' ' + convertEpochToDate(+request.start_datetime)}
                  {request.type === 'daily' ? ' for ' : ' from '}
                  {request.type === 'daily'
                    ? dayDuration(+request.end_datetime, +request.start_datetime) +
                      (dayDuration(+request.end_datetime, +request.start_datetime) === 1 ? ' day' : ' days')
                    : convertTo24HourTime(request.start_datetime) + ' to ' + convertTo24HourTime(request.end_datetime)}
                </span>
              </div>
              <div className={`d-flex gap-3`} key={3}>
                <p className={classes.title}>{t9n.previous_description} :</p>
                <span className={classes.content} title={request.parent.description}>
                  {request.parent.description === '' ? <img src={minus} alt="minus" /> : request.parent.description}
                </span>
              </div>
              <div className={`d-flex gap-3`} key={4}>
                <p className={classes.title}>{t9n.new_description} :</p>
                <span className={classes.content} title={request.description}>
                  {request.description === '' ? <img src={minus} alt="minus" /> : request.description}
                </span>
              </div>
            </div>
          }
          action={
            <div className={classes.buttonBox}>
              <BaseButton
                title={t9n.ok_text}
                color="primary"
                hasRightIcon={false}
                hasLeftIcon={false}
                hasBlock={true}
                onClickHandler={() => setEditedInfoModal(false)}></BaseButton>
            </div>
          }></Modal>
      )}
    </>
  );
};
export default EditedRequestInfo;
