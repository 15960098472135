import Form from 'react-bootstrap/Form';
interface MyComponentProps {
  lable?: string;
  size?: 'sm' | 'lg';
  disabled?: boolean;
  value?: boolean;
  type?: string;
  id?: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const Checkbox = (props: MyComponentProps) => {

  return <Form.Check 
            type="checkbox" 
            id={props.id} 
            checked={props.checked} 
            label={props.lable} 
            onChange={props.onChange} 
        />;
};
export default Checkbox;
