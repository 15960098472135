import { createSlice } from '@reduxjs/toolkit';

interface RequestState {
  selectedTabNumber: string;
  newRequestAdded: boolean;
}

const initialState: RequestState = {
  selectedTabNumber: 'pending',
  newRequestAdded: false,
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setSelectedTabNumber(state: RequestState, action) {
      state.selectedTabNumber = action.payload;
    },
    setNewRequestAdded(state: RequestState, action) {
      state.newRequestAdded = action.payload;
    },
  },
});
export const requestActions = requestSlice.actions;
export default requestSlice.reducer;
