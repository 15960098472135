import moment from 'jalali-moment';
import { LeaveType } from '../../modules/Requests/types/type';
import { IAppLocale } from '../../locales';

export const convertDate = (format: string, date: string | number): string => {
  const myDate = moment(date);
  return myDate.format(format);
};
export const different = (timeA: number, timeB: number, type: string) => (t9n: IAppLocale) => {
  const moment1 = moment(timeA);
  const moment2 = moment(timeB);

  let daysDifference = 0;
  let hours = 0;
  let minutes = 0;
  if (type === LeaveType.Daily) {
    daysDifference = moment2.diff(moment1, 'days');
    return daysDifference === 0 ? `1 ${t9n.day_text}` : `${daysDifference + 1} ${t9n.days_text}`;
  } else {
    const duration = moment.duration(moment2.diff(moment1));
    hours = Math.floor(duration.asHours());
    minutes = Math.floor(duration.asMinutes()) % 60;
    return hours && minutes
      ? hours + ' h' + ' ' + minutes + ' m'
      : hours && !minutes
      ? hours + ' hours'
      : minutes + ' m';
  }
};
export const diffTime = (timeA: number, timeB: number) => {
  const moment1 = moment(timeA);
  const moment2 = moment(timeB);
  const duration = moment.duration(moment2.diff(moment1));
  let hours = 0;
  let minutes = 0;
  hours = Math.floor(duration.asHours());
  minutes = Math.floor(duration.asMinutes()) % 60;
  return { hours: hours, minutes: minutes };
};
export const setTime = (hour: number, minute: number, date_: string) => {
  const date = moment(date_);
  date.set({ hour: hour, minute: minute });
  return date.unix();
};
export const convertTime = (time: number, sep: string) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  return `${hours}h ${sep} ${minutes}m`;
};
export const nextDays = (currentDate: number, days: number) => {
  const currentDay = moment(currentDate);
  const toDate = currentDay.clone().add(days, 'day');

  return toDate.unix();
};
export const timeReg = (time: string) => {
  const matches = time.match(/(\d+)h\s*(\d*)m?/);

  if (matches) {
    var hours = parseInt(matches[1], 10);
    var minutes = matches[2] ? parseInt(matches[2], 10) : 0;
    return timeToSeconds(hours, minutes);
  } else {
    return 0;
  }
};
export const minutesToTime = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return hours + 'h' + ' ' + remainingMinutes + 'm';
};
export const convertToTime = (time: number | string) => {
  const hours = Math.floor(Number(time) / 60);
  const remainingMinutes = Number(time) % 60;

  return { hours: hours, minutes: remainingMinutes };
};
export const timeToSeconds = (hours: number, minutes: number) => {
  return hours * 3600 + minutes * 60;
};
export const getCurrentYearDate = () => {
  const currentDate = moment();
  return { start: currentDate.startOf('jYear').unix(), end: currentDate.endOf('jYear').unix() };
};

export const getLastYearDate = () => {
  const currentDate = moment();
  const lastYearDate = currentDate.subtract(1, 'jYear');
  return { start: lastYearDate.startOf('jYear').unix(), end: lastYearDate.endOf('jYear').unix() };
};

export const convertTo24HourTime = (timestamp: string) => {
  let timeString = new Date(+timestamp * 1000).toLocaleTimeString();
  let timeParts = timeString.split(':');

  if (timeString.includes('PM') && timeParts[0] !== '12') {
    timeParts[0] = (+timeParts[0] + 12).toString();
  } else if (timeString.includes('AM') && timeParts[0] === '12') {
    timeParts[0] = '00'; // Adjust for midnight hour
  }
  let time = timeParts.slice(0, 2).join(':');

  return time;
};