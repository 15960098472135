import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/shifts';

interface IDay {
  day: number;
  duration: number;
  start_time: string;
  flextime: string;
}

interface IShift {
  name: string;
  companyId: number;
    weekly_off_days: number[],
    days: IDay[];
}

const ShiftRepository = {
  createShift(body: IShift) {
    return BaseRepository.post(`${resource}`, body);
  },
  editShift(body: IShift, id: string) {
    return BaseRepository.patch(`${resource}/${id}`, body);
  },
  getShift(id: string) {
    return BaseRepository.get(`${resource}/${id}`);
  },
  getEmployeeTeams() {
    return BaseRepository.get(`/users${resource}`);
  },
  deleteShift(id: string) {
    return BaseRepository.delete(`${resource}/${id}`);
  },
  getAllShifts(params: { companyId: number }) {
    return BaseRepository.get(`${resource}`, {
      params,
    });
  },
  getSetting(id: string) {
    return BaseRepository.get(`companies/${id}/setting`);
  },

  updateSetting(
    id: string,
    body: {
      name: string;
      address: string;
      calender_type: string;
      duration: number; //mili second
      weekly_off_days: number[];
      start_time: string; // hh:mm
      flextime: string;
      holidays: string[];
    }
  ) {
    return BaseRepository.patch(`companies/${id}/setting`, body);
  },
};
export default ShiftRepository;
