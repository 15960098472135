import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import dayjs, {Dayjs} from 'dayjs';

interface SelectTimeProps {
    fromDate: string;
    setDate: (date: string) => void;
    width?: number;
}

const SelectTime = (props: SelectTimeProps) => {

    const handleTimeChange = (newValue: Dayjs | null) => {
        // Format the Dayjs object to a string in 'HH:mm' format and update the state
        const formattedTime = newValue ? newValue.format('HH:mm') : ''; // Clear the time if the picker selection is cleared
        props.setDate(formattedTime);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                slotProps={{
                    actionBar: {
                        actions: ['cancel', 'accept'],
                    },
                }}
                sx={{
                    width: props.width ? props.width : '100%',
                }}
                name="startTime"
                timeSteps={{
                    hours: 1,
                    minutes: 1,
                }}
                selectedSections="all"
                closeOnSelect={false}
                ampm={false}
                value={props.fromDate ? dayjs(props.fromDate, 'HH:mm') : null}
                onAccept={(value) => {
                    if (!value) {
                        return;
                    }
                    handleTimeChange(value)
                }}
            />
        </LocalizationProvider>
    );
};
export default SelectTime;
