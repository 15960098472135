import { Route, Routes } from 'react-router-dom';
import Team from '../pages/Team';
import EditTeam from '../components/EditTeam/EditTeam';

const TeamRouter = () => {
  return (
    <Routes>
      <Route path="/:pageNumber" exact element={<Team />} />
      <Route path="/edit/:id" element={<EditTeam />} />
    </Routes>
  );
};
export default TeamRouter;
