import { Route, Routes } from 'react-router-dom';
import Leave from '../pages/Leave';
const LeaveRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Leave />} />
    </Routes>
  );
};
export default LeaveRouter;
