export const computePageNumber = (
  deleteItem: boolean,
  newItemAdded: boolean,
  itemsListLength: number,
  pageNumber: number,
  totalRecords: number,
  totalPage: number,
  itemsPerPage: number,
  isRequest?: boolean,
) => {
  if (deleteItem && itemsListLength === 1) {
    return pageNumber;
  } else if (newItemAdded && isRequest) {
    return 1;
  } else if (newItemAdded && totalRecords % itemsPerPage === 0 && totalRecords !== 0) {
    return totalPage + 1;
  } else if (newItemAdded && totalRecords % itemsPerPage > 0) {
    return totalPage;
  } else {
    return pageNumber;
  }
};