import { useState, useEffect } from 'react';
import TeamsList from '../components/TeamsList';
import classes from './style/Team.module.scss';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { useDispatch } from 'react-redux';
import { Employee, RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import SettingLayout from '../../../view/layouts/SettingLayout';

interface EmployeesResponse {
  data: Employee[];
}

const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);

const Team = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const companyId = userInfo.user.defaultCompanyId;
  const [loading, setLoading] = useState<boolean>(false);
  const [employeesList, setEmployeesList] = useState<Employee[]>([]);
  const [newTeamAdded, setNewTeamAdded] = useState<boolean>(false);

  const handleNewTeam = (status: boolean) => setNewTeamAdded(status);

  const getAllEmployees = () => {
    let param = {
      page: 1,
      pageSize: 10,
    };
    setLoading(true);
    EmployeesRepository()
      .getCompanyUsers(param, companyId)
      .then((res: AxiosResponse<EmployeesResponse>) => {
        const employeeList: Employee[] = [];
        const data = res.data.data;
        if (data) {
          data.forEach((employee: Employee) => {
            employeeList.push(employee);
          });
        }
        setEmployeesList(employeeList);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllEmployees();
  }, []);

  return (
    <SettingLayout>
      <Loading loading={loading} />
      <div className={classes.projectLayout}>
        <TeamsList newTeamAdded={newTeamAdded} handleNewTeam={handleNewTeam} companyId={companyId} employeesList={employeesList}/>
      </div>
    </SettingLayout>
  );
};
export default Team;
