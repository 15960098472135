import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosResponse, AxiosError } from 'axios';
import { errorActions } from '../../../store/error-slice';
import { useTranslation } from '../../../providers/locale-provider';
import { capitalizeFirstOfEach } from '../../../core/helpers/utils';
import { RepoType } from '../../../types/sharedTypes';
import classes from './style/Company.module.scss';
import SettingLayout from '../../../view/layouts/SettingLayout';
import Loading from '../../../components/base/Loading';
import upload from '../../../assets/image/Icons/upload-cloud.svg';
import Checkbox from '../../../components/base/Checkbox';
import AvatarDetails from '../../../components/base/AvatarDetails';
import Toast from '../../../components/base/Toast';
import Avatar from '@mui/material/Avatar';

const companyRepository = () => RepoFactory.get(RepoType.Company);

const Company = () => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state: RootState) => state.auth.userInfo.user.defaultCompanyId);
  const [loading, setLoading] = useState<boolean>(false);
  const [companyDetails, setCompanyDetails] = useState({
    companyName: '',
    annualTimeOff: '',
    tresholdLimit: '',
    editPunchTimeLimit: '',
    editAttendanceLimit: '',
    allowNameModification: false,
    photoUrl: null as string | null,
  });
  const [file, setFile] = useState<File | null>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showAvatarDetails, setShowAvatarDetails] = useState<boolean>(false);

  const inputFileRef = useRef<HTMLInputElement>(null);
  const maxFileSize = 4 * 1024 * 1024;

  const handleInputChange = (field: string, value: string) => {
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const validateFile = (file: File) => {
    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/svg'];
    if (!validFileTypes.includes(file.type)) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          state: 'error',
          message: 'Only SVG, PNG, JPG, or GIF files are allowed.',
        })
      );
      return false;
    }

    if (file.size > maxFileSize) {
      dispatch(errorActions.setHasError(true));
      dispatch(
        errorActions.setError({
          state: 'error',
          message: 'File size must not exceed 4MB.',
        })
      );
      return false;
    }
    return true;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | DataTransfer) => {
    const files = event instanceof DataTransfer ? event.files : event.target.files;
    if (files && files[0] && validateFile(files[0])) {
      const file = files[0];
      setFile(file);
      const reader = new FileReader();
      reader.onload = () => {
        handleInputChange('photoUrl', reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    const dt = e.dataTransfer;
    handleFileChange(dt);
  };

  const onBtnClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleError = (error: AxiosError) => {
    setLoading(false);
    dispatch(errorActions.setHasError(true));
    dispatch(errorActions.setError(error.response?.data));
  };

  const getCompany = () => {
    setLoading(true);
    companyRepository()
      .getCompany(companyId)
      .then((res: AxiosResponse<any>) => {
        const company = res.data.company;
        setCompanyDetails({
          companyName: company.name,
          annualTimeOff: company.annual_leaves,
          tresholdLimit: company.off_shift_threshold && (+company.off_shift_threshold / 3600000).toString(),
          editPunchTimeLimit: company.new_punch_dayslimit,
          editAttendanceLimit: company.update_punch_dayslimit,
          allowNameModification: company.allowNameModification,
          photoUrl: 'https://iclock.online/api/' + company.photo,
        });
        setLoading(false);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          handleError(error);
        }
      });
  };

  const handleSaveClick = () => {
    const bodyFormData = new FormData();
    bodyFormData.append('annual_leaves', companyDetails.annualTimeOff);
    bodyFormData.append(
      'off_shift_threshold',
      companyDetails.tresholdLimit ? (+companyDetails.tresholdLimit * 3600000).toString() : 'null'
    );
    console.log(companyDetails.tresholdLimit);
    bodyFormData.append(
      'update_punch_dayslimit',
      companyDetails.editAttendanceLimit ? companyDetails.editAttendanceLimit : 'null'
    );
    bodyFormData.append(
      'new_punch_dayslimit',
      companyDetails.editPunchTimeLimit ? companyDetails.editPunchTimeLimit : 'null'
    );
    if (companyDetails.allowNameModification) {
      bodyFormData.append('allowNameModification', companyDetails.allowNameModification.toString());
    }
    if (file) {
      bodyFormData.append('photo', file);
    }
    setLoading(true);
    companyRepository()
      .editCompany(bodyFormData, companyId)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setShowToast(true);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          handleError(error);
        }
      });
  };

  const handleCheckboxChange = () => {
    handleInputChange('allowNameModification', (!companyDetails.allowNameModification).toString());
  };

  const onAvatarClick = () => setShowAvatarDetails(true);
  const closeAvatarModal = () => setShowAvatarDetails(false);

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <SettingLayout>
      <Loading loading={loading} />
      {showToast && (
        <Toast
          message={t9n.the_company_setting_has_been_updated}
          onClose={() => {
            setShowToast(false);
          }}
        />
      )}
      {showAvatarDetails && <AvatarDetails src={companyDetails.photoUrl || undefined} exitModal={closeAvatarModal} />}
      <section className={classes.settingSection}>
        <div className={classes.setting}>
          <div className={classes.info}>
            <span className={classes.infoHeader}>{t9n.company_settings_text}</span>
            <div className={classes.avatarSection}>
              <Avatar
                alt={capitalizeFirstOfEach(companyDetails.companyName)}
                src={companyDetails.photoUrl || undefined}
                sx={{ width: 64, height: 64, cursor: 'pointer' }}
                onClick={onAvatarClick}
              />
              <div
                className={`${classes.uploadSection} ${isDragOver ? classes.dragOver : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                <img className={classes.uploadIcon} onClick={onBtnClick} src={upload} alt="upload icon" />
                <div className={classes.uploadDes}>
                  <span onClick={onBtnClick} className={classes.uploadText}>
                    Click to upload
                  </span>
                  or drag and drop
                </div>
                <div className={classes.uploadDes}>SVG, PNG, JPG or GIF (max. 4MB)</div>
              </div>
            </div>
            <div className={classes.editInfo}>
              <div className={classes.infoFields}>
                <span className={classes.fieldTitle}>{t9n.annual_time_off_days_text}</span>
                <input
                  className={classes.settingInput}
                  type="text"
                  placeholder={'18 d'}
                  value={companyDetails.annualTimeOff}
                  onChange={(e) => handleInputChange('annualTimeOff', e.target.value)}
                  autoComplete="new-password"
                />
              </div>
              <div className={classes.infoFields}>
                <span className={classes.fieldTitle}>{t9n.Off_shift_treshold_limit}</span>
                <input
                  className={classes.settingInput}
                  type="text"
                  placeholder={'20 h'}
                  value={companyDetails.tresholdLimit}
                  onChange={(e) => handleInputChange('tresholdLimit', e.target.value)}
                  autoComplete="new-password"
                />
              </div>
              <div className={classes.infoFields}>
                <span className={classes.fieldTitle}>{t9n.Employees_can_edit_their_attendance_until}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    className={classes.settingInput}
                    type="text"
                    placeholder={'1'}
                    value={companyDetails.editAttendanceLimit}
                    onChange={(e) => handleInputChange('editAttendanceLimit', e.target.value)}
                    autoComplete="new-password"
                    style={{
                      borderRight: 'none',
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                      width: '245px',
                    }}
                  />
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      padding: '9px 8px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                    }}>
                    <span>Days</span>
                  </div>
                </div>
              </div>
              <div className={classes.infoFields}>
                <span className={classes.fieldTitle}>{t9n.Employees_can_add_their_attendance_until}</span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    className={classes.settingInput}
                    type="text"
                    placeholder={'60'}
                    value={companyDetails.editPunchTimeLimit}
                    onChange={(e) => handleInputChange('editPunchTimeLimit', e.target.value)}
                    autoComplete="new-password"
                    style={{
                      borderRight: 'none',
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                      width: '245px',
                    }}
                  />
                  <div
                    style={{
                      border: '1px solid #ced4da',
                      padding: '9px 8px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                    }}>
                    <span>Days</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.mandatoryName}>
              <Checkbox onChange={handleCheckboxChange} checked={companyDetails.allowNameModification} />
              <div className={classes.mandatoryNameTxt}>Ability to change First Name and Last Name by employees </div>
            </div>
          </div>
          <div className={classes.action}>
            <button className={classes.actionbtn} onClick={handleSaveClick}>
              {t9n.save_btn_text}
            </button>
          </div>
        </div>
      </section>
      <input
        className={classes.fileInput}
        type="file"
        accept=".jpg, .jpeg, .png, .gif, .svg"
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </SettingLayout>
  );
};

export default Company;
