import trash from '../../../../assets/image/Icons/trash.svg';
import disabledTrash from '../../../../assets/image/Icons/trash-disabled.svg';

import {useDispatch} from 'react-redux';
import {useState} from 'react';
import {AxiosError, AxiosResponse} from 'axios';
import {RepoFactory} from '../../../../baseRepository/Factory';
import {errorActions} from '../../../../store/error-slice';

import Loading from '../../../../components/base/Loading';
import DeleteModal from '../../../../components/base/DeleteModal';
import IconButton from '@mui/material/IconButton/IconButton';
import {RepoType} from '../../../../types/sharedTypes';

const timeSheetRepository = () => RepoFactory.get(RepoType.Attendance);

interface props {
    attendanceId: number;
    handleDeleteAttendance: () => void;
    disabled: boolean;
}

const DeleteAttendance = ({attendanceId, disabled, handleDeleteAttendance}: props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteAttendanceModal, setDeleteAttendanceModal] = useState(false);

    const openDeleteAttendanceModal = (status: boolean) => {
        setDeleteAttendanceModal(status);
    };

    const exitModal = () => setDeleteAttendanceModal(false);
    const deleteAttendance = () => {
        setLoading(true);
        timeSheetRepository()
            .deleteAttendance(attendanceId)
            .then((_: AxiosResponse<any>) => {
                handleDeleteAttendance();
            })
            .catch((error: unknown) => {
                if (error instanceof AxiosError) {
                    dispatch(errorActions.setHasError(true));
                    dispatch(errorActions.setError(error.response?.data));
                }
            }).finally(() => {
            setLoading(false)
            exitModal();
        })
    };

    return (
        <>
            <Loading loading={loading}/>
            {
                !disabled ? (
                    <IconButton onClick={() => openDeleteAttendanceModal(true)}>
                        <img src={trash} alt="delete punch"/>
                    </IconButton>
                ) : (
                    <IconButton>
                        <img src={disabledTrash} alt="delete punch"/>
                    </IconButton>
                )
            }

            {deleteAttendanceModal && (
                <DeleteModal
                    handleDelete={deleteAttendance}
                    exitModal={exitModal}
                    title={'Delete Punch Time'}
                    text={'Are you sure you want to delete this Punch time? This action cannot be undone.'}
                />
            )}
        </>
    );
};
export default DeleteAttendance;
