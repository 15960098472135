import { Route, Routes } from 'react-router-dom';
import TimeSheet from '../pages/TimeSheet';
import PunchOut from '../pages/PunchOut';
const TimeSheetRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<TimeSheet />} />
      <Route path="/punch" exact element={<PunchOut />} />
    </Routes>
  );
};
export default TimeSheetRouter;
