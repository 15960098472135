import { Route, Routes } from 'react-router-dom';
import Report from '../pages/Report';

const ReportRouter = () => {
  return (
    <Routes>
      <Route path="/:pageType/:pageNumber" exact element={<Report />} />
    </Routes>
  );
};
export default ReportRouter;
