import trash from '../../../../assets/image/Icons/trash.svg';
import projectClasses from '../style/ProjectBaseStyles.module.scss';
import '../../../../assets/style/scss/_variables.scss';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { errorActions } from '../../../../store/error-slice';
import { RepoType } from '../../../../types/sharedTypes';
import DeleteModal from '../../../../components/base/DeleteModal';
import Loading from '../../../../components/base/Loading';
import {useTranslation} from "../../../../providers/locale-provider";

const projectRepository = () => RepoFactory.get(RepoType.Project);

interface props {
  projectId: number;
  employeeId: number;
  handleDeleteEmployee?: (status: boolean) => void;
}

const DeleteEmployee = (props: props) => {
  const {t9n} = useTranslation()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteEmployeeModal, setDeleteEmployeeModal] = useState(false);

  const openDeleteEmployeeModal = (status: boolean) => setDeleteEmployeeModal(status);
  const exitModal = () => setDeleteEmployeeModal(false);

  const deleteEmployee = () => {
    let body = {
      userId: props.employeeId,
    };

    setLoading(true);
    projectRepository()
      .deleteEmployee(body, props.projectId)
      .then((res: AxiosResponse<any>) => {
        exitModal();
        props.handleDeleteEmployee && props.handleDeleteEmployee(true);
      })
      .catch((error: unknown) => {

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading loading={loading} />

      <img
        className={projectClasses.icon}
        onClick={() => openDeleteEmployeeModal(true)}
        src={trash}
        alt="Delete icon"
      />

      {deleteEmployeeModal && (
        <DeleteModal
          handleDelete={deleteEmployee}
          exitModal={exitModal}
          title={t9n.delete_employee_text}
          text={t9n.delete_employee_desc_text}
        />
      )}
    </>
  );
};
export default DeleteEmployee;
