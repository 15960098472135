/**
 * <Modal
          size="sm"
          header="test"
          exitAction={exitModal}
          body={
            <div className={classes.modalBody}>
              <span>Are you sure you want to delete this Punch time? This action cannot be undone.</span>
            </div>
          }
          action={
            <div className={classes.buttonBox}>
              <BaseButton title="Delete" color="secondary" hasRightIcon={false} hasLeftIcon={false}></BaseButton>
              <BaseButton title="Cancel" color="primary" hasRightIcon={false} hasLeftIcon={false}></BaseButton>
            </div>
          }></Modal>
 */
import classes from './style/Modal.module.scss';
import close from '../../assets/image/Icons/close.svg';
interface ModalComponents {
  size?: string;
  header: string | React.ReactNode;
  body?: React.ReactNode;
  action?: React.ReactNode;
  exitAction: () => void;
  bodyOverFlow?: boolean;
  disableHeader?: boolean;
}
const Modal = (props: ModalComponents) => {
  const exitHandler = () => {
    if (props.exitAction) return props.exitAction();
  };
  return (
    <div className={classes.backgroundModal}>
      <div
        className={`${
          props.size === 'sm' ? classes.smallModal : props.size === 'lg' ? classes.largModal : props.size === 'ml' ? classes.mediumLarg : classes.medModal
        } mh-100`}>
        {props.disableHeader ? null : (
          <div className={classes.modalHeader}>
            <h1 className={classes.headerText}>{props.header}</h1>
            <div className={classes.exitButton} onClick={(e) => exitHandler()}>
              <img src={close} alt="close icon" />
            </div>
          </div>
        )}
        {
          <div style={props.bodyOverFlow ? { overflowY: 'visible' } : { overflowY: 'auto' }} className={classes.body}>
            {props.body}
          </div>
        }

        {props.action ? <div className={classes.action}>{props.action}</div> : undefined}
      </div>
    </div>
  );
};
export default Modal;
