import trash from '../../../assets/image/Icons/trash.svg';
import projectClasses from './style/ProjectBaseStyles.module.scss';
import '../../../assets/style/scss/_variables.scss';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { RepoType } from '../../../types/sharedTypes';
import { useTranslation } from '../../../providers/locale-provider';

import DeleteModal from '../../../components/base/DeleteModal';
import Loading from '../../../components/base/Loading';

const projectRepository = () => RepoFactory.get(RepoType.Project);

interface props {
  projectId: number;
  handleDeleteProject?: (status: boolean) => void;
}

const DeleteProject = (props: props) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);

  const openDeleteProjectModal = () => setDeleteProjectModal(true);

  const exitModal = () => setDeleteProjectModal(false);

  const deleteProject = () => {
    setLoading(true);
    projectRepository()
      .deleteProject(props.projectId)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        exitModal();
        props.handleDeleteProject && props.handleDeleteProject(true);
      })
      .catch((error: unknown) => {
        setLoading(false);
        exitModal();
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  return (
    <>
      <Loading loading={loading} />

      <img className={projectClasses.icon} onClick={openDeleteProjectModal} src={trash} alt="Delete icon" />

      {deleteProjectModal && (
        <DeleteModal
          handleDelete={deleteProject}
          exitModal={exitModal}
          title={t9n.delete_project_text}
          text={t9n.delete_project_desc_text}
        />
      )}
    </>
  );
};
export default DeleteProject;
