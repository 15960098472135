export const ENLocale = {
  logout_btn_text: 'Log out',
  edit_profile_btn_text: 'Edit Profile',
  change_language_btn_text: 'Language',
  lang_english: 'English',
  lang_farsi: 'Farsi',
  first_punch_in: 'First Punch In',
  last_punch_out: 'Last Punch Out',
  Today_work_time: 'Today’s Work Time',
  current_month_work_time: 'Current Month Work Time',
  punch_in_btn_text: 'Punch In',
  punch_in_date: 'In Date',
  punch_out_btn_text: 'Punch Out',
  punch_out_date: 'Out Date',
  loading_text: 'Please wait ...',
  status: 'Status',
  user_status_in: 'In',
  user_status_out: 'Out',
  navbar_link_my_time_sheet: 'My Timesheet',
  navbar_link_timeOff: 'Time Off',
  navbar_link_leave_status: 'Colleagues Status',
  navbar_link_companies: 'Companies',
  navbar_link_employees: 'Employees',
  navbar_link_projects: 'Projects',
  tabs_link_projects: 'Active',
  navbar_link_reports: 'Reports',
  navbar_link_teams: 'Teams',
  navbar_link_settings: 'Settings',
  edit_text: 'Edit',
  add_text: 'Add',
  duration_text: 'Duration',
  save_changes_btn_text: 'Save Changes',
  work_duration_text: 'Work Duration',
  user_settings_text: 'User settings',
  company_settings_text: 'Company settings',
  name_text: 'Name',
  email_text: 'Email',
  email_placeholder_text: 'Enter your Email',
  receive_email_text: 'Didn’t receive email?',
  reset_password_text: 'Reset Password',
  reset_password_desc: 'Enter the email you use to login and we will send you instructions to reset your password',
  strong_password_text: 'Enter the code we just sent you via email.',
  reset_password_btn_text: 'Reset Password',
  reset_password_request_btn: 'Request password reset',
  back_to_login_btn_text: 'Back to login',
  email_placeholder: 'Please Enter Your Email',
  username_placeholder: 'Please Enter Your username',
  password_text: 'Password',
  password_placeholder: 'Please Enter Your Password',
  save_btn_text: 'Save',
  annual_timeOff_text: 'Annual Time Off',
  used_annual_leave_text: 'Used Annual Time Off',
  requests_text: 'Requests',
  new_request_btn_text: 'New Time Off Request',
  edit_request: 'Edit Time Off Request',
  send_request_btn_text: 'Send the Request',
  no_request_text: 'There’s no Request!',
  no_off_week_text: 'No one is off this week!',
  time_off_reason_text: 'Time Off Reason',
  leave_reason_vacation: 'Vacation',
  leave_reason_sick: 'Sick Time Off',
  leave_reason_academic: 'Academic',
  leave_reason_other: 'Other',
  leave_reason_placeholder_text: 'Choose your Time Off reason',
  leave_reason_error_text: 'Please choose your Time Off reason!',
  timeOff_duration_text: 'Time Off Duration',
  leave_description_text: 'Description',
  leave_description_placeholder_text: 'Enter the description',
  timeOff_type_text: 'Time Off Type',
  leave_type_hourly: 'Hourly',
  leave_type_daily: 'Daily',
  leave_from_text: 'From',
  leave_to_text: 'To',
  leave_for_text: 'For',
  day_text: 'day',
  days_text: 'days',
  team_text: 'Team',
  cancel_text: 'Cancel',
  apply_text: 'Apply',
  team_select_placeholder_text: 'Select a team',
  select_date_text: 'Select Date',
  yes_text: 'Yes',
  close_text: 'Close',
  cancel_request_text: 'Cancel Request',
  cancel_request_modal_desc:
    'Are you sure you want to cancel this request? You can see this request in canceled requests tab.',
  cancel_request_success: 'Your time off request has been canceled successfully.',
  request_date_text: 'Request Time',
  requestDate_text: 'Request Date',
  timeOff_Date_text: 'Time Off Date',
  start_text: 'Start',
  end_text: 'End',
  sent_request: 'Your time off request has been sent successfully.',
  edit_request_success: 'Your time off request has been edited successfully.',
  table_pagination_prev: 'Previous',
  table_pagination_next: 'Next',
  leave_request_tab_pending: 'Pending',
  leave_request_tab_denied: 'Denied',
  leave_request_tab_approved: 'Approved',
  leave_request_tab_canceled: 'Canceled',
  Leave_request_denied: 'Denied',
  leave_request_approve: 'Approve',
  leave_request_deny: 'Deny',
  denied_text: 'Denied',
  details_text: 'Details',
  date_text: 'Date',
  add_company_btn_text: 'Add Company',
  edit_company_btn_text: 'Edit Company',
  delete_company_text: 'Delete Company',
  delete_manager_text: 'Delete Manager',
  company_name_placeholder_text: 'Company Name',
  company_name_invalid_error_text: 'Enter Your Company name!',
  managers_text: 'Managers',
  manager_text: 'Manager',
  employee_text: 'Employee',
  regular_text: 'Regular',
  system_admin_text: 'System Administrator',
  select_managers_text: 'Select Managers',
  address_text: 'Address',
  address_placeholder_text: 'Enter your address',
  no_companies: 'There’s no Company',
  no_managers: 'There’s no Manager',
  no_employees: 'There’s no Employee',
  invite_manager_btn_text: 'Invite Manager',
  username_text: 'Username',
  delete_company_desc_text: 'Are you sure you want to delete this company? This action cannot be undone.',
  delete_manager_desc_text: 'Are you sure you want to delete this manager? This action cannot be undone.',
  delete_text: 'Delete',
  manager_username_placeholder_text: 'Enter Manager Username',
  manager_username_invalid_error_text: 'Enter manager username!',
  manager_email_placeholder_text: 'Enter Manager Email',
  manager_email_invalid_error_text: 'Enter manager email!',
  manager_Fname_placeholder_text: 'Enter Manager First Name',
  manager_Lname_placeholder_text: 'Enter Manager Last Name',
  manager_Fname_invalid_error_text: 'Enter manager first name!',
  manager_Lname_invalid_error_text: 'Enter manager last name!',
  manager_password_invalid_error_text: 'Enter manager password!',
  manager_invite_with_username: 'Invite with username',
  manager_invite_with_email: 'Invite with email',
  hour_text: 'hour',
  minute_text: 'minute',
  minutes_text: 'minutes',
  add_employee_btn_text: 'Add Employee',
  invite_employee_btn_text: 'Invite Employee',
  invite_success_msg_text: 'The invitation has been sent successfully!',
  role_text: 'Role',
  employee_username_invalid_error_text: 'Enter employee username!',
  employee_email_invalid_error_text: 'Enter employee email!',
  employee_Fname_invalid_error_text: 'Enter employee first name!',
  employee_Lname_invalid_error_text: 'Enter employee last name!',
  employee_password_invalid_error_text: 'Enter employee password!',
  role_invalid_error_text: 'Please select the role!',
  role_select_placeholder: 'Select Role',
  state_text: 'State',
  punch_in_time_text: 'Punch in time',
  punch_out_time_text: 'Punch out time',
  pending_requests_text: 'Pending Time Off Requests',
  history_text: 'History',
  approve_btn_text: 'Approve',
  deny_btn_text: 'Deny',
  request_approved_msg_text: 'The Request has been approved!',
  request_denied_msg_text: 'The Request has been denied!',
  login_page_title: 'Login to Your  Rayka Clock  Account',
  login_page_subtitle: 'Welcome Back to Rayka Clock!',
  login_with_email: 'Login with email',
  login_with_username: 'Login with username',
  invalid_email_error: 'Please Enter Correct Email!',
  invalid_username_email_error: 'Please Enter Correct UserName or Email',
  login_page_email_password_error: 'Enter Your Email and Password!',
  description_text: 'Description',
  Employee_text: 'Employee',
  calendar_setting_text: 'Calendar settings',
  calendar_type_text: 'Calendar Type',
  weekend_text: 'Weekend',
  public_holidays: 'Public Holidays',
  work_time_stting_text: 'Work time settings',
  work_time_text: 'Work Time',
  start_from_text: 'Start from',
  select_on_calendar: 'Select On Calendar',
  setting_successful_update: 'Setting Updated Successfuly',
  create_new_project_btn_text: 'Create New Project',
  create_btn_text: 'Create',
  project_name_text: 'Project Name',
  enter_description_text: 'Enter description',
  select_employees_text: 'Select Employees',
  project_name_invalid_msg_text: 'Enter Your Project name!',
  no_project: 'There’s no Project',
  created_date_text: 'Created Date',
  search_text: 'Search',
  delete_project_text: 'Delete Project',
  delete_project_desc_text: 'Are you sure you want to delete this project? This action cannot be undone.',
  delete_project_success_msg: 'The project has been deleted successfully!',
  archive_project_text: 'Archive Project',
  archive_project_desc_text: 'Are you sure you want to archive this project? This action cannot be undone.',
  assign_employee_text: 'Assign Employee',
  assign_btn_text: 'Assign',
  require_work_desc_text: 'Require Work Description',
  delete_employee_text: 'Delete Employee',
  delete_employee_desc_text: 'Are you sure you want to delete this employee? This action cannot be undone.',
  create_new_team_text: 'Create New Team',
  team_name_text: 'Team Name',
  team_name_invalid_msg_text: 'Enter Your Team name!',
  no_teams: 'There’s no Team',
  delete_team_text: 'Delete Team',
  delete_team_desc_text: 'Are you sure you want to delete this team? This action cannot be undone.',
  monthly_report_text: 'Monthly Report',
  projects_report_text: 'Projects Report',
  manual_report_text: 'Advanced Report',
  export_btn_text: 'Export',
  enter_offset_month_text: 'Enter the Offset Month',
  enter_password_text: 'Enter Your Password',
  enter_username_text: 'Enter Your Username',
  enter_name_text: 'Enter Your Name',
  forgot_password_text: 'Forgot Password',
  remember_me_text: 'Remember Me',
  login_btn_text: 'Login to Rayka Clock',
  All_teams_text: 'All Teams',
  name_contain_apaces_error: 'Name cannot contain spaces.',
  solar_text: 'solar',
  hijri_text: 'hijri',
  gergorian_text: 'gergorian',
  sunday_text: 'Sunday',
  monday_text: 'Monday',
  tuesday_text: 'Tuesday',
  wednesday_text: 'Wednesday',
  thursday_text: 'Thursday',
  friday_text: 'Friday',
  saturday_text: 'Saturday',
  email_not_found: 'The email address you entered was not found.',
  emial_successfully_sent: 'Password reset email sent successfully.',
  enter_emial_text: 'Enter Your email!',
  new_password_text: 'New Password',
  enter_new_password_text: 'Enter Your New Password',
  confirm_new_password_text: 'Confirm New Password',
  confirm_new_password_placeholder: 'Enter Your Confirm Password',
  enter_confirm_password_text: 'Enter Your Confirm Password',
  password_not_match_text: 'Passwords do not match!',
  check_strong_password_text:
    'Password must be at least 8 characters long, contain a number, a symbol, an uppercase letter, and a lowercase letter.',
  otp_error_text: 'Enter Your OTP!',
  name_contain_apaces_error_text: 'Name cannot contain spaces.',
  username_contain_apaces_error_text: 'Username cannot contain spaces.',
  flextime_text: 'Flextime',
  working_hours_placeholder: 'Input Working Hours',
  filter_text: 'Filter',
  reset_filter_text: 'Reset Filter',
  register_account_text: 'Register to Your Rayka Clock Account',
  nothing_found_text: 'Nothing Found',
  archived_text: 'Archived',
  archive_text: 'Archive',
  unarchive_text: 'Unarchive',
  archive_project_success_msg: 'The project has been archived successfully!',
  unArchive_project_success_msg: 'The project has been Unarchived successfully!',
  archive_employee_success_msg: 'The employee has been archived successfully!',
  unArchive_employee_success_msg: 'The employee has been Unarchived successfully!',
  select_a_date_error: 'Please select a date.',
  select_time_error: 'Please select time.',
  navbar_link_setting: 'Setting',
  navbar_link_profile: 'Profile',
  navbar_link_shifts: 'Shifts',
  navbar_link_leave_setting: 'Time Off Setting',
  navbar_link_company: 'Company',
  shifts_text: 'Shift',
  active_text: 'Active',
  reload_text: 'Reload',
  shift_settings_text: 'Shift settings',
  add_shift_text: 'Add Shift',
  first_name_text: 'First Name',
  last_name_text: 'Last Name',
  time_duration_error: 'The start time should be earlier than the end time.',
  enter_first_name: 'Enter Your First Name!',
  enter_last_name: 'Enter Your Last Name'!,
  confirm_password_text: 'Confirm password',
  sort_by_text: 'Sort by',
  lastName_sort_descending: 'Last Name A to Z',
  lastName_sort_ascending: 'Last Name Z to A',
  employee_settings_text: 'Employee Settings',
  edit_employee_success_msg: 'The employee has been updated successfully!',
  username_or_email_text: 'Username or email address',
  annual_time_off_days_text: 'Annual Time Off Days',
  start_date_text: 'Start Date',
  complete_profile_text: 'Complete Profile',
  set_time_off_status: 'Set Time Off Status',
  success_time_off_status: 'Your time off status has been added successfully!',
  time_off_request_text: 'Time Off Request',
  approve_text: 'Approve',
  deny_text: 'Deny',
  comment_text: 'Comment',
  enter_comment_text: 'Enter a Comment...',
  and_text: 'and',
  reviewer_text: 'Reviewer',
  previous_time_off_request_date: 'Previous Time Off Request Date',
  new_time_off_request_date: 'New Time Off Request Date',
  previous_description: 'Previous Description',
  new_description: 'New Description',
  ok_text: 'OK',
  Employees_can_add_their_attendance_until: 'Employees can add their attendance until:',
  Employees_can_edit_their_attendance_until: 'Employees can edit their attendance until:',
  Off_shift_treshold_limit: 'Off-shift Treshold Limit',
  the_company_setting_has_been_updated: 'The company setting has been updated.'
};
