import BaseRepository from '../../../baseRepository/BaseRepository';
import { UserRole } from '../../../types/sharedTypes';
const resource = '/users';

const ManagersRepository = {
  allManagers(params: { page: number; pagesize: number; companyId: number; role: UserRole }) {
    return BaseRepository.get(`${resource}`, { params });
  },
  deleteManager(body: { userId: number }, id: string) {
    return BaseRepository.delete(`/companies/${id}${resource}`, { data: body });
  },
  createManager(data: { username: string; name: string; last_name:string; password: string; confirmPassword:string; companies?: number[] }) {
    return BaseRepository.post('/managers', data);
  },
  createManagerByEmail(data: { email: string; name: string; last_name:string; companies?: number[] }) {
    return BaseRepository.post('/managers/invite', data);
  },
  getManagers(params: { pageNumber: number; pageSize: number; role: UserRole }) {
    return BaseRepository.get(`${resource}`, { params });
  },
};
export default ManagersRepository;
