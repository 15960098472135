import { useState, useEffect } from 'react';

import classes from './style/Search.module.scss';
import search from '../../assets/image/Icons/search.svg';
import { useTranslation } from '../../providers/locale-provider';

const Debouncing_DURATION = 500;

interface SearchProps {
  handleDebouncedSearchTerm: (debouncedSearchTerm: string) => void;
  positionAbsolute?: boolean;
}

const Search = (props: SearchProps) => {
  const { t9n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (searchTerm.length === 0 || searchTerm.length > 2) props.handleDebouncedSearchTerm(searchTerm);
    }, Debouncing_DURATION);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  return (
    <div className={`${classes.search} ${props.positionAbsolute ? 'position-absolute' : ''}`}>
      <input
        className={classes.searchInput}
        type="text"
        placeholder={t9n.search_text}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <img src={search} alt="search icone" />
    </div>
  );
};

export default Search;
