import React, { useState, useMemo, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classes from './style/Sidebar.module.scss';
import { IAppLocale } from '../../locales';
import { useTranslation } from '../../providers/locale-provider';
import sidebarSetting from '../../assets/image/Icons/sidebarSetting.svg';
import { ReactComponent as ProfileIcon } from '../../assets/image/Icons/activeProfile.svg';
import { ReactComponent as LeaveIcon } from '../../assets/image/Icons/clock-snooze.svg';
import chevron_down from '../../assets/image/Icons/chevron-down_gray.svg';
import chevron_up from '../../assets/image/Icons/chevron-up_gray.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export interface ILink {
  key: string;
  link?: string;
  otherLink?: string[];
}

const Sidebar = () => {
  const { t9n } = useTranslation();
  const location = useLocation();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const isManager = userInfo?.user?.role.title === 'manager';
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const Sidebar_LINK_T9N_MAP: Record<string, (t9n: IAppLocale) => string> = {
    profile: (t9n) => t9n.navbar_link_profile,
    // leave_setting: (t9n) => t9n.navbar_link_leave_setting,
    setting: (t9n) => t9n.navbar_link_setting,
    shifts: (t9n) => t9n.navbar_link_shifts,
    employees: (t9n) => t9n.navbar_link_employees,
    projects: (t9n) => t9n.navbar_link_projects,
    teams: (t9n) => t9n.navbar_link_teams,
    company: (t9n) => t9n.navbar_link_company,
  };

  const NavLinkItem = ({ key, link }: ILink, isActive: boolean, isSubLink?: boolean) => {
    const icon = icons(key, isActive);
    return (
      <div
        key={key}
        className={`${isActive ? classes.activeMenuItem : classes.menuItem} ${isSubLink && classes.submenu}`}>
        <NavLink className={`${classes.navLink} ${isActive && classes.activeNavLink}`} to={`${link}`}>
          {!isSubLink && icon}
          {Sidebar_LINK_T9N_MAP[key](t9n)}
        </NavLink>
      </div>
    );
  };

  const links = useMemo(() => {
    const baseLinks = [{ key: 'profile', link: '/profile' }];

    // if (isManager) {
    //   baseLinks.push({ key: 'leave_setting', link: '/leave_setting' });
    // }

    return baseLinks;
  }, []);

  const subLinks = useMemo(
    () =>
      uniq(
        [
          { key: 'shifts', link: '/shifts' },
          { key: 'employees', link: '/employees/employeeList/1' },
          { key: 'projects', link: '/projects/projectsList/1' },
          { key: 'teams', link: '/teams/1' },
          { key: 'company', link: '/company' },
        ],
        (item) => item.key
      ),
    []
  );

  const icons = (key: string, isActive: boolean) => {
    switch (key) {
      case 'profile': {
        return isActive ? <ProfileIcon fill="white" /> : <ProfileIcon fill="#757575" />;
      }
      case 'leave_setting': {
        return isActive ? <LeaveIcon fill="white" /> : <LeaveIcon fill="white" />;
      }
      default: {
        //statements;
        break;
      }
    }
  };

  // Helper function to check if the current path is active
  const isActive = (path: string) => location.pathname.includes(path);

  useEffect(() => {
    const activeSubLink = subLinks.some((link) => link.link && isActive(link.link));
    setIsSettingsOpen(activeSubLink);
  }, [location.pathname, subLinks]);

  return (
    <div className={classes.sidebar}>
      <div className={classes.sidebarLinks}>
        {links.map((link) => NavLinkItem(link, isActive(`/${link.key}`)))}
        {isManager && (
          <div
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
            className={`${classes.menuItem} ${classes.setting} ${classes.navLink}`}>
            <img src={sidebarSetting} alt="sidebarSetting" />
            {Sidebar_LINK_T9N_MAP['setting'](t9n)}
            <img className={classes.chevron} src={isSettingsOpen ? chevron_up : chevron_down} alt="chevron" />
          </div>
        )}
        {isManager && isSettingsOpen && subLinks.map((link) => NavLinkItem(link, isActive(`/${link.key}`), true))}
      </div>
    </div>
  );
};

// Helper function to generate unique links
function uniq(data: any[], key: (item: any) => string): ILink[] {
  return Array.from(new Map(data.map((item: any) => [key(item), item])).values());
}

export default Sidebar;
