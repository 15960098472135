import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStatus } from '../modules/Auth/types/type';

interface AttendanceState {
  status: UserStatus;
  punchId: number | null;
}

const initialState: AttendanceState = {
  status: UserStatus.Out,
  punchId: null,
};

export const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<UserStatus>) => {
      state.status = action.payload;
    },
    setPunchId: (state, action: PayloadAction<number | null>) => {
      state.punchId = action.payload;
    },
  },
});

export const { setStatus, setPunchId } = attendanceSlice.actions;

export default attendanceSlice.reducer;
