import classes from './style/BaseTitle.module.scss';
interface MyComponent {
  username: string;
  hasButton?: boolean;
  button?: React.ReactNode;
}
const BaseTitle = (props: MyComponent) => {
  return <div className={classes.userHeader}>{props.hasButton === true && props.button}</div>;
};
export default BaseTitle;
