import { useState, useEffect } from 'react';
import undo from '../../assets/image/Icons/undo.svg';
import disabledUndo from '../../assets/image/Icons/disabledUndo.svg';
import classes from './style/ProgressBarButton.module.scss';

interface ProgressBarButtonProps {
  disabled?: boolean;
  handleProgressStatus: () => void;
  onClick?: () => void;
  startTime?: number;
}

const ProgressBarButton = (props: ProgressBarButtonProps) => {
  const [progress, setProgress] = useState(0);
  const now = new Date();

  useEffect(() => {
    const duration = (1 * 60 * 1000) + 5000;
    const interval = 1000;
    let startTime: Date | undefined;
    props.startTime && (startTime = new Date(props.startTime * 1000));
    let stepCount = (startTime ? (now.getTime() - startTime.getTime())/interval : 0); 
    const steps = duration / interval;

    const timer = setInterval(() => {
      stepCount++;
      const newProgress = (stepCount / steps) * 100;
      setProgress(newProgress);

      if (stepCount >= steps) {
        clearInterval(timer);
        // If 5 minutes have finished, set isFinished to true
        props.handleProgressStatus();
      }
    }, interval);

    return () => clearInterval(timer);
  }, [props.startTime]);

  return props.disabled ? (
    <img src={disabledUndo} alt="disabledUndo" />
  ) : (
    <div className={classes.progressBarContainer} onClick={props.onClick}>
      <div className={classes.progressBarIcon}>
        <img src={undo} alt="undo" />
      </div>
      <div
        className={classes.progressBar}
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default ProgressBarButton;
