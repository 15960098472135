import classes from './style/Auth.module.scss';
import Input from '../../../components/base/Input';
import BaseButton from '../../../components/base/BaseButton';
import { useState } from 'react';
import { RepoFactory } from '../../../baseRepository/Factory';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../store/auth-slice';
import { errorActions } from '../../../store/error-slice';
import { AxiosError, AxiosResponse } from 'axios';
import InputPassword from '../../../components/base/InputPassword';
import Loading from '../../../components/base/Loading';
import { useTranslation } from '../../../providers/locale-provider';
import { isValidEmail } from '../../../core/helpers/FormValidation';
import { RepoType } from '../../../types/sharedTypes';
import { Link } from 'react-router-dom';

const authRepository = () => RepoFactory.get(RepoType.Auth);

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t9n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [usernameOrEmail, setUsernameOrEmail] = useState<string>('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    usernameOrEmail: false,
    password: false,
  });

  const handleInputChange = (field: string, value: string) => {
    switch (field) {
      case 'usernameOrEmail':
        setUsernameOrEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [field]: false });
  };
  const validateForm = () => {
    const newErrors = {
      usernameOrEmail: usernameOrEmail.length === 0,
      password: password.length === 0,
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const resetInfo = () => {
    setUsernameOrEmail('');
    setPassword('');

    setErrors({
      usernameOrEmail: false,
      password: false,
    });
  };
  const goToNextPage = async (link: string) => {
    navigate(link);
  };
  const postLogin = async () => {
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const isEmail = isValidEmail(usernameOrEmail);
    let body;
    if (isEmail) {
      body = {
        email: usernameOrEmail,
        password,
      };
    } else {
      body = {
        username: usernameOrEmail,
        password,
      };
    }

    await authRepository()
      .postLogin(body)
      .then(async (res: AxiosResponse<any>) => {
        setLoading(false);
        localStorage.setItem('access_token', res.data.access_token);
        localStorage.setItem('refresh_token', res.data.refresh_token);
        dispatch(authActions.setUserInfo(res.data.access_token));
        dispatch(authActions.setIsAuthenticated(true));
        localStorage.setItem('isLoggedIn', 'true');
        resetInfo();
      })
      .then(() => {
        handleLoginSuccess();
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      postLogin();
    }
  };

  const handleLoginSuccess = () => {
    const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
    localStorage.removeItem('redirectAfterLogin');
    navigate(redirectUrl);
  };

  return (
    <div className={classes.formSection}>
      <Loading loading={loading} />
      <div className={classes.inputBox}>
        <div className={classes.inputSection}>
          <Input
            name={'usernameOrEmail'}
            type={'text'}
            placeholder={t9n.username_or_email_text}
            title={t9n.username_or_email_text}
            value={usernameOrEmail}
            handleChange={(e) => handleInputChange('usernameOrEmail', e.currentTarget.value)}
            handleEnter={handleKeyDown}
            isInvalid={errors.usernameOrEmail}
            inValidmsg={t9n.invalid_username_email_error}
          />
        </div>
        <InputPassword
          name="Password"
          placeholder={t9n.password_placeholder}
          title={t9n.password_text}
          handleChange={(e) => handleInputChange('password', e.currentTarget.value)}
          handleEnter={handleKeyDown}
          isInvalid={errors.password}
          inValidmsg={t9n.enter_password_text}
        />
      </div>

      <div className={classes.passwordCheck}>
        <Link to="/password-recovery" className={classes.forgotPass}>
          {t9n.forgot_password_text}
        </Link>
      </div>
      <BaseButton title={t9n.login_btn_text} hasBlock={true} onClickHandler={postLogin} />
    </div>
  );
};
export default Login;
