import classes from './style/ErrorHandling.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import closeIcon from '../../assets/image/Icons/close.png';
import { errorActions } from '../../store/error-slice';
import { useEffect, useState, useCallback } from 'react';
import { authActions } from '../../modules/Auth/store/auth-slice';
const ErrorHandling = () => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.error.error);
  const [showNotif, setShowNotif] = useState<boolean>(true);

  useCallback(() => {
    if (!showNotif) {
      closeNotify();
    }
  }, [showNotif]);
  const closeNotify = () => {
    dispatch(errorActions.setHasError(false));
    dispatch(
      errorActions.setError({
        message: '',
        statusCode: 0,
      })
    );
  };
  useEffect(() => {
    let timer = setTimeout(() => {
      setShowNotif(false);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [showNotif]);
  useEffect(() => {
    if (error.message) {
      setShowNotif(true);
    }
    if (error?.statusCode === 401) {
      // dispatch(authActions.logOut());
    }
  }, [error]);
  return (
    <div className={classes.errorHandling}>
      {error && showNotif && (
        <div className={classes.notif}>
          <div className={classes.content}>
            <span className={classes.message}>{error.message}</span>
            <div className={classes.exitButton} onClick={(e) => closeNotify()}>
              <img src={closeIcon} alt="close icon" className={classes.closeIcon} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ErrorHandling;
