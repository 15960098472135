import Table from '../../../components/base/Table';
import EditPen from '../../../assets/image/Icons/EditPen.svg';
import classes from './style/ProjectList.module.scss';
import projectClasses from './style/ProjectBaseStyles.module.scss';
import arrow_narrow_down from '../../../assets/image/Icons/arrow_narrow_down.svg';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import Loading from '../../../components/base/Loading';
import DeleteProject from './DeleteProject';
import ArchiveProject from './ArchiveProject';
import Search from '../../../components/base/Search';
import { convertDate } from '../../../core/helpers/FormatDate';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { computePageNumber } from '../../../core/helpers/pagination';
import { RepoType } from '../../../types/sharedTypes';
import { useTranslation } from '../../../providers/locale-provider';
import { PROJECTSTABS } from '../pages/Project';

const projectRepository = () => RepoFactory.get(RepoType.Project);
interface IProjectsList {
  newProjectAdded?: boolean;
  handleNewProject?: (status: boolean) => void;
  companyId: number;
}

const ProjectList = ({ newProjectAdded, handleNewProject, companyId }: IProjectsList) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const { pageType } = useParams();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [projectsList, setProjectsList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [projectDeleted, setProjectDeleted] = useState<boolean>(false);
  const [projectArchived, setProjectArchived] = useState<boolean>(false);
  const [projectUnArchive, setProjectUnArchived] = useState<boolean>(false);
  const [isNameAscending, setNameIsAscending] = useState<boolean>(true);
  const [isDateAscending, setDateIsAscending] = useState<boolean>(true);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const isArchived = pageType === PROJECTSTABS.ARCHIVED;

  const handleDeleteProject = (status: boolean) => setProjectDeleted(status);
  const handleArchiveProject = (status: boolean) => setProjectArchived(status);
  const handleUnArchiveProject = (status: boolean) => setProjectUnArchived(status);

  const handleDebouncedSearchTerm = (debouncedSearchTerm: string) => {
    setCurrentPage(1);
    setDebouncedSearchTerm(debouncedSearchTerm);
  };

  const deleteItem = projectDeleted || projectArchived || projectUnArchive;

  const getAllProjects = (pageNumber: number, changePageType?: boolean) => {
    setLoading(true);
    const computedPageNumber = computePageNumber(
      deleteItem,
      newProjectAdded || false,
      projectsList.length,
      pageNumber,
      totalRecords,
      totalPage,
      itemsPerPage
    );
    let param = {
      page: changePageType ? 1 : computedPageNumber,
      pageSize: itemsPerPage,
      search: debouncedSearchTerm,
      companyId: companyId,
      status: isArchived ? false : null,
    };
    projectRepository()
      .getAllProjects(param)
      .then((res: AxiosResponse<any>) => {
        setProjectsList(res.data.data);
        setTotalPage(res?.data?.metadata?.totalPages);
        setTotalRecords(res?.data?.metadata?.totalRecords);
        handleNewProject && handleNewProject(false);
        newProjectAdded && setCurrentPage(computedPageNumber);

        if (projectDeleted || projectArchived || projectUnArchive) {
          setCurrentPage(computedPageNumber);
          setProjectDeleted(false);
          setProjectArchived(false);
          setProjectUnArchived(false);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
  };

  const sortListwithName = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let sort = [];
    interface item {
      name: string;
      description: string;
      createdAt: string;
    }

    if (isNameAscending) {
      setNameIsAscending(false);
      sort = projectsList.sort((a: item, b: item) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    } else {
      setNameIsAscending(true);
      sort = projectsList.sort((a: item, b: item) => (a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1));
    }
    setProjectsList([...sort]);
  };

  const sortListwithDate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let sort = [];
    interface item {
      name: string;
      description: string;
      createdAt: string;
    }

    if (isDateAscending) {
      setDateIsAscending(false);
      sort = projectsList.sort((a: item, b: item) => (a.createdAt < b.createdAt ? -1 : 1));
    } else {
      setDateIsAscending(true);
      sort = projectsList.sort((a: item, b: item) => (a.createdAt > b.createdAt ? -1 : 1));
    }
    setProjectsList([...sort]);
  };

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);
  useEffect(() => {
    if (currentPage !== pageNumber) navigate(`/projects/${pageType}/${currentPage}`);
  }, [currentPage]);
  useEffect(() => {
    if (projectDeleted || newProjectAdded || projectArchived || projectUnArchive) {
      getAllProjects(currentPage);
    }
  }, [projectDeleted, newProjectAdded, projectArchived, projectUnArchive]);
  useEffect(() => {
    getAllProjects(currentPage);
  }, [debouncedSearchTerm, companyId, currentPage, pageType]);
  useEffect(() => {
    getAllProjects(1, true);
    setCurrentPage(1);
  }, [pageType]);

  const sendDataForTable = () => {
    interface item {
      id: number;
      name: string;
      description: string;
      createdAt: string;
    }
    let dataArray: any = [];
    if (projectsList.length) {
      projectsList.forEach((item: item) => {
        dataArray.push({
          items: [
            <span>
              <Link className={classes.projectName} to={`/projects/edit/${item.id}`}>
                {item.name}
              </Link>
            </span>,
            <span className={projectClasses.tableText}>{item.description}</span>,
            <span className={projectClasses.tableText}>{convertDate('MMM D, YYYY', item.createdAt)}</span>,
            <div className={classes.actions}>
              {isArchived ? (
                <ArchiveProject projectId={item.id} handleArchiveProject={handleUnArchiveProject} isArchive={false} />
              ) : (
                <>
                  <DeleteProject projectId={item.id} handleDeleteProject={handleDeleteProject} />
                  <div className={classes.editPen}>
                    <Link to={`/projects/edit/${item.id}`}>
                      <img src={EditPen} alt="edit icon" />
                    </Link>
                  </div>
                  <ArchiveProject projectId={item.id} handleArchiveProject={handleArchiveProject} isArchive={true} />
                </>
              )}
            </div>,
          ],
        });
      });
    }
    return dataArray;
  };

  return (
    <>
      <Loading loading={loading} />
      <Search handleDebouncedSearchTerm={handleDebouncedSearchTerm} />
      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        label={''}
        message={debouncedSearchTerm ? t9n.nothing_found_text : t9n.no_project}
        headers={[
          <span className={classes.headerText}>
            <img
              className={`${isNameAscending ? classes.sortIconAsc : classes.sortIcondesc}`}
              src={arrow_narrow_down}
              alt="arrow_narrow_down"
              onClick={(e) => sortListwithName(e)}
            />
            {t9n.project_name_text}
          </span>,
          <span className={classes.headerText}>{t9n.description_text}</span>,
          <span className={classes.headerText}>
            <img
              className={`${isDateAscending ? classes.sortIconAsc : classes.sortIcondesc}`}
              src={arrow_narrow_down}
              alt="arrow_narrow_down"
              onClick={(e) => sortListwithDate(e)}
            />
            {t9n.created_date_text}
          </span>,
          '',
        ]}
        data={sendDataForTable()}
      />
    </>
  );
};
export default ProjectList;
