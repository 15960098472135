import Login from '../components/Login';
import AuthLayout from '../../../view/layouts/AuthLayout';
import { useTranslation } from '../../../providers/locale-provider';

const LoginPage = () => {
  const { t9n } = useTranslation();
  return (
    <AuthLayout headerText={t9n.login_page_title} subHeader={t9n.login_page_subtitle}>
      <Login />
    </AuthLayout>
  );
};
export default LoginPage;
