import { META_Data, IShiftType, Data } from '../types/index';
import { convertDate, convertToTime, getCurrentYearDate } from '../../../core/helpers/FormatDate';
import { DateRange } from 'react-day-picker';
import moment from 'jalali-moment';

export const getBreakTime = (date: string, data: META_Data[]) => {
  for (var i = 0; i < data.length; i++) {
    if (date === data[i].date) {
      return { breakTime: data[i].break, leaveTime: data[i].leaveDuration };
    }
  }
};

export const getShiftTime = (date: string, data: META_Data[], shiftType: IShiftType) => {
  for (var i = 0; i < data.length; i++) {
    if (date === data[i].date) {
      if (shiftType === IShiftType.ON) return data[i].onShiftValue;
      if (shiftType === IShiftType.OFF) return data[i].offShiftValue;
    }
  }
};

export const getProjectDurationTime = (date: string, data: META_Data[]) => {
  for (var i = 0; i < data.length; i++) {
    if (date === data[i].date) {
      return data[i].projectDuration.duration;
    }
  }
};

export const convertEpochToTime = (epoch: string) => {
  const hours = Math.floor(+epoch / 3600);
  const min = Math.floor((+epoch / 60) % 60);
  return hours.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0');
};

export const getDateValue = (timestamp: any) =>
  timestamp !== 'N/A' ? convertDate('YYYY/MM/DD', Number(timestamp) * 1000) : 'N/A';

export const getTimeValue = (timestamp: any) =>
  timestamp !== 'N/A' ? convertDate('HH:mm', Number(timestamp) * 1000) : 'N/A';

export function getTotalTimeOffString(timeInMinutes: number, dailyWorkingHour: number): string {
  const time = convertToTime(timeInMinutes);
  const dailyWorkingHourInHours = dailyWorkingHour / 60;
  let day = Math.floor(time.hours / dailyWorkingHourInHours);
  const minute = time.minutes;

  return day > 1
    ? `${day} Days ${time.hours % dailyWorkingHourInHours} H ${minute} M`
    : `${day} Day ${time.hours % dailyWorkingHourInHours} H ${minute} M`;
}

export const checkDurationSplit = (data: Data): boolean => {
  for (const entry of data.allAtten) {
    if (entry.durationSplit) {
      return true;
    }
  }
  return false;
};

export const handleDate = (reportType: number, dateRange: DateRange | undefined, date: any) => {
  let from, to;
  if (reportType) {
    from = moment(dateRange?.from).format('YYYY-MM-DD');
    to = moment(dateRange?.to).format('YYYY-MM-DD');
  } else {
    from = moment(date?.from).format('YYYY-MM-DD');
    to = moment(date?.to).format('YYYY-MM-DD');
  }

  return {
    from: from,
    to: to,
  };
};