import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../modules/Auth/store/auth-slice';
import requestSlice from '../modules/Requests/store/request-slice';
import dashboardSlice from './dashboard-slice';
import errorSlice from './error-slice';
import attendanceSlice from './attendanceSlice';
import reloadAlertSlice from './reloadAlertSlice';
const store = configureStore({
  reducer: {
    auth: authSlice,
    error: errorSlice,
    request: requestSlice,
    dashboard: dashboardSlice,
    attendanceSlice: attendanceSlice,
    reloadAlert: reloadAlertSlice,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
