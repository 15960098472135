/**
 *   <SelectInput
          label='Name'
          placeHolder='Select an item ...'
          options={
            [
              { value: 'one', title: 'Name1' },
              { value: '2', title: 'Name2' },
              { value: '3', title: 'Name3' },
            ]
          }
        />
 */
import classes from './style/SelectInput.module.scss';
import { useState, useRef, useEffect } from 'react';
import check from '../../assets/image/Icons/check.svg';
import chevron_down from '../../assets/image/Icons/chevron_down.svg';
import { Option } from '../../types/sharedTypes';

interface MyComponentProps {
  label?: string;
  placeHolder?: string;
  options?: Option[];
  defaultValue?: Option;
  handleselectedOption?: (selectedItem: Option) => void;
  isInvalid?: boolean;
  inValidmsg?: string;
}

const SelectInput = (props: MyComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Option>({ value: '', title: '' });
  const changeShowOptionStatus = () => {
    setShowOptions(!showOptions);
  };
  const seletedItemHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: Option) => {
    setSelectedItem(item);
    props.handleselectedOption && props.handleselectedOption(item);
    changeShowOptionStatus();
  };
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };
  useEffect(() => {
    if (props.defaultValue) {
      setSelectedItem(props.defaultValue);
    }
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref, props.defaultValue]);
  return (
    <div className={classes.selectedInput} ref={ref}>
      {props.label && <div className={`${classes.lable} mb-1`}>{props.label}</div>}
      <div className={classes.selectInput} onClick={(e) => changeShowOptionStatus()}>
        <div className={selectedItem.value ? classes.selectedText : classes.textSection}>
          {selectedItem.value ? selectedItem.title : props.placeHolder}
        </div>
        <div className={classes.iconSection}>
          <img src={chevron_down} alt="chevron_down" className={classes.icon} />
        </div>
        {showOptions && (
        <div className={classes.optionsBox}>
          {props.options?.map((item, index) => {
            return (
              <div
                onClick={(e) => seletedItemHandler(e, item)}
                key={index}
                className={`${classes.item}  ${item.value === selectedItem.value ? classes.selectedItem : ''}`}>
                <span style={{ padding: '10px' }}>{item.title}</span>
                {item.value === selectedItem.value && (
                  <div className={classes.checked}>
                    <img src={check} alt="check" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      </div>

      {props.isInvalid && <div className={classes.error}>{props.inValidmsg}</div>}
    </div>
  );
};
export default SelectInput;
