/**
  <DeleteModal
    handleDelete={deleteProject}
    exitModal={exitModal}
    title={'Delete Project'}
    text={'Are you sure you want to delete this project? This action cannot be undone.'}
  />
 */

import classes from './style/DeleteModal.module.scss';
import Modal from './Modal';
import BaseButton from './BaseButton';
import '../../assets/style/scss/_variables.scss';
import { useTranslation } from '../../providers/locale-provider';

interface props {
  handleDelete: () => void;
  exitModal: () => void;
  title: string;
  text: string;
  btnText?: string;
}

const DeleteModal = ({handleDelete, exitModal, title, text, btnText}: props) => {
  const { t9n } = useTranslation();
  const deleteText = btnText ?? t9n.delete_text;
  return (
    <Modal
      size="sm"
      header={title}
      exitAction={exitModal}
      body={
        <div className={classes.modalBody}>
          <span className={classes.deleteText}>{text}</span>
        </div>
      }
      action={
        <div className={classes.buttonBox}>
          <div className={classes.deleteButton}>
            <BaseButton
              hasBlock={true}
              size="lg"
              title={t9n.cancel_text}
              color="white"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={exitModal}></BaseButton>
          </div>
          <div className={classes.cancelButton}>
            <BaseButton
              hasBlock={true}
              size={'lg'}
              title={deleteText}
              color="red"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={() => handleDelete()}></BaseButton>
          </div>
        </div>
      }
    />
  );
};
export default DeleteModal;
