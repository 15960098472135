import { Fragment, useState } from 'react';
import classes from './style/Table.module.scss';
import EmptyTable from './EmptyTable';
import AddRequest from '../../modules/Requests/components/AddRequest';
import Pagination from './pagination';
import arrowDown from '../../assets/image/Icons/arrow-down.svg';
import arrowUp from '../../assets/image/Icons/arrow-up.svg';
import { Request } from '../../modules/RequestsManagement/components/types/type';
import { different } from '../../core/helpers/FormatDate';
import { useTranslation } from '../../providers/locale-provider';
import { setShortText } from '../../core/helpers/utils';
import minus from '../../assets/image/Icons/minus.svg';

interface TableComponent {
  label: string;
  headers: React.ReactNode[];
  buttonHeader: boolean;
  data?: { items: React.ReactNode[] }[];
  message?: string;
  textCenter?: boolean;
  refreshPage?: (data: boolean) => void;
  leftAlignedColumnIndex?: number;
  changePage?: (selectedPage: { selected: number }) => void;
  totalPage?: number;
  currentPage?: number;
  disableHeader?: boolean;
  showMore?: boolean;
  requests?: Request[];
}

const Table = ({
  label,
  headers,
  buttonHeader,
  data,
  message,
  textCenter,
  refreshPage,
  leftAlignedColumnIndex,
  changePage,
  totalPage = 0,
  currentPage,
  disableHeader = false,
  showMore = false,
  requests,
}: TableComponent) => {
  const { t9n } = useTranslation();

  const [toggledRows, setToggledRows] = useState<{ [key: number]: boolean }>({});

  const handleToggle = (index: number) => {
    setToggledRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleRefresh = (data: number) => {
    if (refreshPage) {
      refreshPage(true);
    }
  };

  const handleChangePage = (selectedPage: { selected: number }) => {
    if (changePage) {
      changePage(selectedPage);
    }
  };

  return (
    <>
      <table className={`${classes.tableBox} ${!disableHeader && classes.tableBorder} mb-2`}>
        <thead className={`${classes.fixHeader} position-relative`}>
          {(buttonHeader || label !== '') && (
            <tr className={`d-flex `}>
              {buttonHeader ? (
                <td className={`${classes.buttonHeader} position-absolute end-0`}>
                  <AddRequest onRefresh={handleRefresh} />
                </td>
              ) : undefined}

              <td className={`position-relative`}>
                <h1 className={`${classes.labelText}`}>{label}</h1>
              </td>
            </tr>
          )}

          {!disableHeader && data && data.length > 0 && (
            <tr className={classes.tableHeader}>
              {headers.map((item, index) => {
                if (index === leftAlignedColumnIndex) {
                  return (
                    <td key={index} className={`${classes.cellText} ${textCenter ? '' : 'text-center'}`}>
                      {item}
                    </td>
                  );
                } else {
                  return (
                    <td key={index} className={`${classes.cellText} ${textCenter ? 'text-center' : ''}`}>
                      {item}
                    </td>
                  );
                }
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {data && data?.length > 0 ? (
            <>
              {data.map((row, index) => {
                const isToggled = toggledRows[index];
                return (
                  <Fragment key={index}>
                    <tr className={classes.rowColor}>
                      {row.items.map((item, itemIndex) => (
                        <td key={itemIndex} className={`${textCenter ? 'text-center' : ''}`}>
                          {item}
                        </td>
                      ))}
                      {showMore && requests && (
                        <td onClick={() => handleToggle(index)} className={`${textCenter ? 'text-center' : ''}`}>
                          <img className={classes.arrow} src={isToggled ? arrowUp : arrowDown} alt="" />
                        </td>
                      )}
                    </tr>

                    {showMore && requests && isToggled && (
                      <tr>
                        <td align="center" colSpan={9} className={classes.dropdownRow}>
                          <div className={classes.dropdownContainer}>
                            <div className={classes.dropdownHeaders}>
                              <span className={classes.dropDownCell}>Reviewer</span>
                              <span className={classes.dropDownCell}>Time off Duration</span>
                              <span className={classes.dropDownCell}>Comment</span>
                            </div>
                            <div className={classes.dropdownContent}>
                              <span
                                className={classes.dropDownCell}
                                title={
                                  requests[index].reviewer
                                    ? (requests[index].reviewer.name ?? '') +
                                      ' ' +
                                      (requests[index].reviewer.last_name ?? '')
                                    : ''
                                }>
                                {requests[index].reviewer ? (
                                  setShortText({
                                    text:
                                      (requests[index].reviewer.name ?? '') +
                                      ' ' +
                                      (requests[index].reviewer.last_name ?? ''),
                                    length: 20,
                                  }) ?? <img src={minus} />
                                ) : (
                                  <img src={minus} />
                                )}
                              </span>
                              <span className={classes.dropDownCell}>
                                {different(
                                  +requests[index].start_datetime * 1000,
                                  +requests[index].end_datetime * 1000,
                                  requests[index].type
                                )(t9n)}
                              </span>
                              <span className={classes.dropDownCell} title={requests[index].comment}>
                                {setShortText({ text: requests[index].comment, length: 30 }) ?? <img src={minus} />}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </>
          ) : (
            <EmptyTable message={message ? message : ''} />
          )}
        </tbody>
      </table>
      {totalPage > 0 && (
        <Pagination currentPage={currentPage} totalPage={totalPage!} handlePageChange={handleChangePage} />
      )}
    </>
  );
};
export default Table;
