import { useEffect, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import AuthRouter from '../modules/Auth/routes/AuthRouter';
import { authActions } from '../modules/Auth/store/auth-slice';
import { RootState } from '../store';
import CompaniesRouter from '../modules/companies/routes/CompaniesRouter';
import TimeSheetRouter from '../modules/TimeSheet/routes/TimeSheetRouter';
import ProjectRouter from '../modules/project/routes/ProjectRouter';
import Page404Router from '../modules/Page404/routes/Page404Router';
import RequestRouter from '../modules/Requests/routes/RequestRouter';
import RequestsManagementRouter from '../modules/RequestsManagement/routes/RequestsManagementRouter';
import EmployeeRouter from '../modules/Employees/routes/EmployeesRouter';
import ProfileRouter from '../modules/Profile/routers/ProfileRouter';
import LeaveRouter from '../modules/Leave/routes/LeaveRouter';
import ReportRouter from '../modules/Reports/routes/ReportRouter';
import TeamRouter from '../modules/Team/routes/TeamRouter';
import SettingRouter from '../modules/Setting/routes/SettingRouter';
import ShiftRouter from '../modules/Shifts/routes/ShiftRouter';
import { UserRole } from '../types/sharedTypes';
import PasswordRecovery from '../modules/Auth/components/ResetPassword/PasswordRecovery';
import ResetPassword from '../modules/Auth/components/ResetPassword/ResetPassword';
import RegisterAccount from '../modules/Auth/components/RegisterAccount';
import Company from '../modules/Company/pages/Company';
import { setLogoutAlert, setLoginAlert, setShowAlert } from '../store/reloadAlertSlice';

const AppRoutes = () => {
  const dispatch = useDispatch();
  const isAuthanticated = localStorage.getItem('access_token') ? true : false;
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  let userRole: string = userInfo.user.role.title;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'isLoggedIn') {
        if (event.newValue === 'true') {
          dispatch(setLoginAlert(true));
          dispatch(setShowAlert(true));
        } else if (event.newValue === 'false') {
          dispatch(setLogoutAlert(true));
          dispatch(setShowAlert(true));
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      dispatch(authActions.setUserInfo(localStorage.getItem('access_token')));
      dispatch(authActions.setIsAuthenticated(true));
    }
  }, [dispatch]);

  useLayoutEffect(() => {
    let isNotExpired = false;
    if (userInfo && userInfo.exp > 0 && localStorage.getItem('access_token')) {
      let nowDate: number = Math.floor(new Date().getTime() / 1000);

      if (nowDate < userInfo.exp) {
        isNotExpired = true;
      } else {
        isNotExpired = true;
      }
    }

    dispatch(authActions.setIsAuthenticated(isAuthanticated && isNotExpired));
  }, [isAuthanticated, dispatch, userInfo]);

  useEffect(() => {
    // Store the requested URL before redirecting to login
    if (!isAuthanticated) {
      const unprotectedRoutes = [
        '/login',
        '/time-sheet',
        '/reset-password',
        '/password-recovery',
        '/confirm-invitation',
      ];

      if (!unprotectedRoutes.includes(location.pathname)) {
        localStorage.setItem('redirectAfterLogin', location.pathname);
      }
    }
  }, [isAuthanticated, location]);

  const handleLoginSuccess = () => {
    const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/';
    localStorage.removeItem('redirectAfterLogin');
    navigate(redirectUrl);
  };

  const justLoginRoute = () => {
    return (
      <Routes>
        <Route exact path="/login/*" element={<AuthRouter />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/confirm-invitation" element={<RegisterAccount />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );
  };

  const isAuthenticatedRoutes = () => {
    return (
      <Routes>
        <Route exact path="/profile/*" element={<ProfileRouter />} />
        {userRole === UserRole.REGULAR && <Route exact path="/time-off/*" element={<RequestRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/requests/*" element={<RequestsManagementRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/reports/*" element={<ReportRouter />} />}
        {userRole === UserRole.SYSADMIN && <Route exact path="/companies/*" element={<CompaniesRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/projects/*" element={<ProjectRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/employees/*" element={<EmployeeRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/setting/*" element={<SettingRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/teams/*" element={<TeamRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/shifts/*" element={<ShiftRouter />} />}
        {userRole === UserRole.MANAGER && <Route exact path="/company/*" element={<Company />} />}
        {(userRole === UserRole.REGULAR || userRole === UserRole.MANAGER) && (
          <Route exact path="/colleagues-status/*" element={<LeaveRouter />} />
        )}
        {(userRole === UserRole.REGULAR || userRole === UserRole.MANAGER) && (
          <Route exact path="/time-sheet/*" element={<TimeSheetRouter />} />
        )}
        {(userRole === UserRole.REGULAR || userRole === UserRole.MANAGER) && (
          <Route exact path="/" element={<Navigate replace to="/time-sheet" />} />
        )}
        {(userRole === UserRole.REGULAR || userRole === UserRole.MANAGER) && (
          <Route exact path="/login" element={<Navigate replace to="/time-sheet" />} />
        )}
        {userRole === UserRole.SYSADMIN && <Route exact path="/" element={<Navigate replace to="/companies/1" />} />}
        <Route path="/*" element={<Page404Router />} />
      </Routes>
    );
  };

  return (
    <>
      {!isAuthanticated && justLoginRoute()}
      {isAuthanticated && userRole && isAuthenticatedRoutes()}
    </>
  );
};

const Router = () => {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default Router;
