/*
 <Badge
    title="Learn More"
    type='warrning'
  ></Badge>
*/
import { useEffect, useState } from "react";
interface MyComponentProps {
    type?: String,
    title: String,
}
const Badge = (props: MyComponentProps) => {
    const [name, setName] = useState("badge ");
    useEffect(() => {
        classNameComputed();
    }, [props.title, props.type]);
    const classNameComputed = () => {
        let tempName = "badge ";

        if (props.type === "warrning") {
            tempName = tempName + " warrning-badge ";
        } else if (props.type === "error") {
            tempName = tempName + " error-badge ";
        } else {
            tempName = tempName + " succed-badge ";
        }
        setName(tempName);
    };
    return (
        <div className={name} >
            <span>{props.title}</span>
        </div>
    );
};
export default Badge;
