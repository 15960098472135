import classes from './style/RequestList.module.scss';
import { useTranslation } from '../../../providers/locale-provider';
import BaseButton from '../../../components/base/BaseButton';
import DatePicker from '../../../components/base/DatePicker';
import ToggleSwitch from '../../../components/base/ToggleSwitch';
import { useState, useEffect } from 'react';
import { RequestStatus, FilterData } from './types/type';

interface RequestsListProps {
  handleFilter: (filterState: FilterData) => void;
  hasLeaveStatus?: boolean;
}

enum FilterDataKeys {
  RequestDate = 'requestDate',
  LeaveDateRange = 'leaveDateRange',
  StatusFilter = 'statusFilter',
}

const RequestFilter = ({ handleFilter, hasLeaveStatus = false }: RequestsListProps) => {
  const { t9n } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [requestVisibility, setRequestVisibility] = useState(false);
  const [leaveVisibility, setLeaveVisibility] = useState(false);

  const [filterData, setFilterData] = useState<FilterData>({
    requestDate: undefined,
    leaveDateRange: undefined,
    statusFilter: undefined,
  });

  const handleToggleChange = (checked: boolean) => {
    const status = checked ? RequestStatus.Approved : RequestStatus.UnApproved;
    setChecked(checked);
    handleInputChange(FilterDataKeys.StatusFilter, status);
  };

  const handleInputChange = (name: keyof FilterData, value: any) => {
    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const resetFilter = () => {
    setIsResetting(true);
    handleToggleChange(false);
    setFilterData({
      requestDate: undefined,
      leaveDateRange: undefined,
      statusFilter: undefined,
    });
  };

  const toggleDatePicker = (picker: 'request' | 'leave') => {
    if (picker === 'request') {
      setRequestVisibility(true);
      setLeaveVisibility(false);
    } else {
      setLeaveVisibility(true);
      setRequestVisibility(false);
    }
  };

  // Effect to call handleFilter only after resetting the filter data
  useEffect(() => {
    if (isResetting) {
      handleFilter(filterData);
      // Reset the flag after calling handleFilter
      setIsResetting(false);
    }
  }, [isResetting]);

  return (
    <>
      <div className={`d-flex flex-row justify-content-between`}>
        <div className={`d-flex flex-row ${classes.filter}`}>
          <div className={classes.dateFilter}>
            <DatePicker
              title={t9n.requestDate_text}
              selectedDate={filterData.requestDate}
              handleSelectedDate={(date) => handleInputChange(FilterDataKeys.RequestDate, date)}
              isVisible={requestVisibility}
              onClick={() => toggleDatePicker('request')}
            />
          </div>
          <div className={classes.dateFilter}>
            <DatePicker
              title={t9n.timeOff_Date_text}
              selectedDate={filterData.leaveDateRange}
              handleSelectedDateRange={(date) => handleInputChange(FilterDataKeys.LeaveDateRange, date)}
              isDateRange={true}
              isVisible={leaveVisibility}
              onClick={() => toggleDatePicker('leave')}
            />
          </div>
          {hasLeaveStatus && (
            <div className={classes.filterToggle}>
              <ToggleSwitch onChange={handleToggleChange} checked={checked} />
              <span className={classes.filterToggleText}>{t9n.leave_request_tab_approved}</span>
            </div>
          )}
        </div>
        <div className={classes.buttonsSection}>
          <div className={classes.filterButton}>
            <BaseButton
              size="small"
              title={t9n.reset_filter_text}
              color="white-blue"
              onClickHandler={resetFilter}
              hasBlock={true}
            />
          </div>
          <div className={classes.filterButton}>
            <BaseButton
              size="small"
              title={t9n.filter_text}
              color="primary-btn"
              onClickHandler={() => handleFilter(filterData)}
              hasBlock={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default RequestFilter;
