import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { Employee, RepoType, UserRole } from '../../../types/sharedTypes';
import classes from './style/EmployeeList.module.scss';
import minus from '../../../assets/image/Icons/minus.svg';
import Table from '../../../components/base/Table';
import Loading from '../../../components/base/Loading';
import { RootState } from '../../../store';
import { computePageNumber } from '../../../core/helpers/pagination';
import { useTranslation } from '../../../providers/locale-provider';
import { IAppLocale } from '../../../locales';
import Search from '../../../components/base/Search';
import { EMPLOYEESTABS } from '../pages/Employees';
import ArchiveEmployee from './ArchiveEmployee';
import { setShortText } from '../../../core/helpers/utils';
import EditPen from '../../../assets/image/Icons/EditPen.svg';
import { Link } from 'react-router-dom';
import Fullname from '../../../components/base/Fullname';

const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);

interface IEmployeesList {
  newEmployeeAdded?: boolean;
  handleNewEmployee?: (status: boolean) => void;
  companyId?: number;
}

const EmployeesList = ({ newEmployeeAdded, handleNewEmployee, companyId }: IEmployeesList) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const { pageNumber } = useParams();
  const { pageType } = useParams();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [employeeArchived, setEmployeeArchived] = useState<boolean>(false);
  const [employeeUnArchive, setEmployeeUnArchived] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [employeesList, setEmployeesList] = useState<Employee[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const isArchived = pageType === EMPLOYEESTABS.ARCHIVED;

  const handleArchiveEmployee = (status: boolean) => setEmployeeArchived(status);
  const handleUnArchiveEmployee = (status: boolean) => setEmployeeUnArchived(status);

  const handleDebouncedSearchTerm = (debouncedSearchTerm: string) => {
    setCurrentPage(1);
    setDebouncedSearchTerm(debouncedSearchTerm);
  };

  const getAllEmployees = (pageNumber: number, changePageType?: boolean) => {
    const computedPageNumber = computePageNumber(
      employeeArchived || employeeUnArchive,
      newEmployeeAdded || false,
      employeesList.length,
      pageNumber,
      totalRecords,
      totalPage,
      itemsPerPage
    );
    let param = {
      page: changePageType ? 1 : computedPageNumber,
      pageSize: itemsPerPage,
      search: debouncedSearchTerm,
      status: isArchived ? false : null,
    };
    setLoading(true);

    EmployeesRepository()
      .getCompanyUsers(param, companyId)
      .then((res: AxiosResponse<any>) => {
        const data = res.data.data;
        setEmployeesList(data);
        setTotalPage(res?.data?.metadata?.totalPages);
        setTotalRecords(res?.data?.metadata?.totalRecords);
        newEmployeeAdded && setCurrentPage(computedPageNumber);
        handleNewEmployee && handleNewEmployee(false);

        if (employeeArchived || employeeUnArchive) {
          setCurrentPage(computedPageNumber);
          setEmployeeArchived(false);
          setEmployeeUnArchived(false);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
    getAllEmployees(SelectedPage.selected + 1);
  };

  useEffect(() => {
    if (newEmployeeAdded || employeeArchived || employeeUnArchive) {
      getAllEmployees(currentPage);
    }
  }, [newEmployeeAdded, employeeArchived, employeeUnArchive]);
  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/employees/${pageType}/${currentPage}`);
    }
  }, [currentPage]);
  useEffect(() => {
    getAllEmployees(currentPage);
  }, [pageNumber, companyId, debouncedSearchTerm]);
  useEffect(() => {
    getAllEmployees(1, true);
    setCurrentPage(1);
  }, [pageType]);

  const USER_ROLE_T9N_MAP: Record<string, (t9n: IAppLocale) => string> = {
    [UserRole.REGULAR]: (t9n) => t9n.regular_text,
    [UserRole.MANAGER]: (t9n) => t9n.manager_text,
    [UserRole.SYSADMIN]: (t9n) => t9n.system_admin_text,
  };

  const sendDataForTable = () => {
    if (employeesList.length) {
      return employeesList.map((employee: Employee) => {
        return {
          items: [
            <span className={classes.info}>
              <Fullname firstname={employee.name} lastname={employee.last_name} />
              <div className={classes.employeeEmail} title={employee.email}>
                {setShortText({ text: employee.email, length: 15 }) ?? <img src={minus} alt="minus" />}
              </div>
            </span>,
            <span>{employee.username ?? <img src={minus} alt="minus" />}</span>,

            <span className={classes.tableText}>{USER_ROLE_T9N_MAP[employee.role.title](t9n)}</span>,
            <span>01</span>,

            isArchived ? (
              <span className={classes.unArchivedBtn}>
                <ArchiveEmployee
                  employeeId={employee.id}
                  handleArchiveEmployee={handleArchiveEmployee}
                  isArchive={false}
                />
              </span>
            ) : (
              <ArchiveEmployee
                employeeId={employee.id}
                handleArchiveEmployee={handleUnArchiveEmployee}
                isArchive={true}
              />
            ),
            !isArchived && (
              <span className={classes.editBtn}>
                <Link to={`/employees/edit/${employee.id}`}>
                  <img src={EditPen} alt="Edit Employee" />
                </Link>
              </span>
            ),
          ],
        };
      });
    }
  };

  const listHeader = [
    <span className={classes.headerText}>{t9n.employee_text}</span>,
    <span className={classes.headerText}>{t9n.username_text}</span>,
    <span className={classes.headerText}>{t9n.role_text}</span>,
    <span className={classes.headerText}>{t9n.shifts_text}</span>,
    <></>,
  ];

  const archiveHeader = [
    <span className={classes.headerText}>{t9n.employee_text}</span>,
    <span className={classes.headerText}>{t9n.role_text}</span>,
    <></>,
  ];
  return (
    <>
      <Loading loading={loading} />
      <Search handleDebouncedSearchTerm={handleDebouncedSearchTerm} />

      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        textCenter={true}
        leftAlignedColumnIndex={0}
        label={''}
        message={debouncedSearchTerm ? t9n.nothing_found_text : t9n.no_employees}
        headers={isArchived ? archiveHeader : listHeader}
        data={sendDataForTable()}
      />
    </>
  );
};
export default EmployeesList;
