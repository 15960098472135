import { useEffect, useState } from 'react';
import classes from './style/report.module.scss';
import { RepoFactory } from '../../../baseRepository/Factory';
import { OptionalUndefined, RepoType } from '../../../types/sharedTypes';
import { IUser } from '../repository/ReportRepository';
import { AxiosResponse, AxiosError } from 'axios';
import { errorActions } from '../../../store/error-slice';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { DateRange, DayPicker } from 'react-day-picker';
import { convertDate } from '../../../core/helpers/FormatDate';
import calendar from '../../../assets/image/Icons/calendar.svg';
import { useTranslation } from '../../../providers/locale-provider';
import { IAppLocale } from '../../../locales';
import { isDefined } from '../../../core/helpers/utils';
import moment from 'moment';
import { RootState } from '../../../store';
import { CustomOption } from './MonthlyReport';
import Fullname from '../../../components/base/Fullname';
import { setShortText } from '../../../core/helpers/utils';
import minus from '../../../assets/image/Icons/minus.svg';

export interface Option {
  value: string;
  label: string;
}

export interface userOption {
  name: string;
  last_name: string;
  email: string;
}
enum Range {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This week',
  LAST_WEEK = 'Last week',
  THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
  THIS_YEAR = 'This year',
  LAST_YEAR = 'Last year',
}

export interface Exportprops {
  info: (
    reportType: number,
    user: Option[],
    date: Date | undefined,
    projectId?: string,
    dateRange?: DateRange | undefined
  ) => void;
}
const reportRepository = () => RepoFactory.get(RepoType.Report);
const employeesRepository = () => RepoFactory.get(RepoType.Employees);
const projectRepository = () => RepoFactory.get(RepoType.Project);
const ManualReport = (props: Exportprops) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [users, setUsers] = useState<Option[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | DateRange | undefined>(undefined);
  const defaultSelected: DateRange = {
    from: new Date(),
    to: new Date(),
  };
  const [rangeSelected, setRangeSelected] = useState<Range>(Range.TODAY);
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const companyId = userInfo.user.defaultCompanyId;
  const [selectedDateLocal, setSelectedDateLocal] = useState<OptionalUndefined<Date | DateRange>>(
    selectedDate || new Date()
  );
  const [projects, setProjects] = useState<Option[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Option[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Option | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [monthForNormalDatePicker, setMonthForNormalDatePicker] = useState<Date>(
    (selectedDateLocal || selectedDate || new Date()) as Date
  );
  const monthForNormalDatePickerVal = monthForNormalDatePicker || selectedDateLocal || selectedDate || new Date();
  const selectedDateVal = selectedDateLocal || selectedDate || new Date();
  const [monthForRangeDatePicker, setMonthForRangeDatePicker] = useState<Date>(
    ((selectedDateLocal as DateRange)?.from || (selectedDate as DateRange)?.from || new Date()) as unknown as Date
  );
  const [setSelectedDateText, selectedDateText] = useState<string>();
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      searchTerm.length === 0 || (searchTerm.length > 2 && setDebouncedSearchTerm(searchTerm));
    }, 500); // Adjust the delay as needed

    // Cleanup the timeout on component unmount or if searchTerm changes
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const getUsers = (pageNumber: number) => {
    setLoading(true);
    let params = {
      page: pageNumber,
      pageSize: 1000,
      // search: debouncedSearchTerm,
    };
    employeesRepository()
      .getCompanyUsers(params, companyId)
      .then((result: AxiosResponse<any>) => {
        setLoading(false);
        const data = result.data.data;
        let users: Option[] = [];
        data.forEach((user: IUser) => {
          users.push({
            value: String(user.id),
            label: user.name + (user.last_name ? ' ' + user.last_name : ''),
          });
        });
        setUsers(users);
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };
  const getAllProjects = () => {
    setLoading(true);

    let param = {
      page: 1,
      pageSize: 10000,
      companyId: companyId,
    };
    projectRepository()
      .getAllProjects(param)
      .then((res: AxiosResponse<any>) => {
        const data = res.data.data;
        let projects: Option[] = [];
        data.forEach((project: any) => {
          projects.push({
            value: String(project?.id),
            label: project.name,
          });
        });
        setProjects(projects);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRange = (range: Range) => {
    switch (range) {
      case Range.TODAY:
        setRangeDate(new Date(), new Date(), Range.TODAY);
        break;

      case Range.YESTERDAY:
        var yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
        setRangeDate(new Date(yesterday), new Date(yesterday), Range.YESTERDAY);

        break;

      case Range.THIS_WEEK:
        // Get the start of current week
        var startOfWeek = moment().startOf('week').format('YYYY-MM-DD');
        // Get the end of the current week
        var endOfWeek = moment().endOf('week').format('YYYY-MM-DD');
        setRangeDate(new Date(startOfWeek), new Date(endOfWeek), Range.THIS_WEEK);
        break;

      case Range.LAST_WEEK:
        // Get the start of last week
        var startOfLastWeek = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
        // Get the end of last week
        var endOfLastWeek = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
        setRangeDate(new Date(startOfLastWeek), new Date(endOfLastWeek), Range.LAST_WEEK);
        break;

      case Range.THIS_MONTH:
        // Get the start of the current month
        var startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
        // Get the end of the current month
        var endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        setRangeDate(new Date(startOfMonth), new Date(endOfMonth), Range.THIS_MONTH);
        break;

      case Range.LAST_MONTH:
        // Get the start of last month
        var startOfLastMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        // Get the end of last month
        var endOfLastMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        setRangeDate(new Date(startOfLastMonth), new Date(endOfLastMonth), Range.LAST_MONTH);
        break;

      case Range.THIS_YEAR:
        // Get the start of the current year
        var startOfYear = moment().startOf('year').format('YYYY-MM-DD');
        // Get the end of the current year
        var endOfYear = moment().endOf('year').format('YYYY-MM-DD');
        setRangeDate(new Date(startOfYear), new Date(endOfYear), Range.THIS_YEAR);

        break;
      case Range.LAST_YEAR:
        // Get the start of last year
        var startOfLastYear = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
        // Get the end of last year
        var endOfLastYear = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
        setRangeDate(new Date(startOfLastYear), new Date(endOfLastYear), Range.LAST_YEAR);

        break;
      default:
        console.log(':)');
    }
  };
  const setRangeDate = (from: Date, to: Date, range: Range) => {
    let rangeDate: DateRange = {
      from: new Date(),
      to: new Date(),
    };
    rangeDate = { from: from, to: to };
    setRange(rangeDate);
    setRangeSelected(range);
  };
  const handleSelectUser = (selected: any) => {
    setSelectedUsers(selected as Option[]);
  };
  const handleSelectProject = (selected: any) => {
    setSelectedProjects(selected as Option);
  };

  const goToMonth = (input: OptionalUndefined<Date> | DateRange) => {
    setMonthForRangeDatePicker((input as DateRange)?.from || new Date());
  };
  const toggleDatePicker = () => setShowDatePicker(!showDatePicker);

  const cancelDatePickerChanges = () => {
    resetDatePickerChanges();
    toggleDatePicker();
  };

  const resetDatePickerChanges = () => {
    goToMonth(selectedDate);
    setSelectedDateLocal(selectedDate || new Date());
  };

  const applyDatePickerChanges = () => {
    setIsDateSelected(true);
    setShowDatePicker(false);
    setDateRange(selectedDateLocal as DateRange);
  };

  const setDateRange = (selectedDate: DateRange) => {
    const from = selectedDate.from?.getTime() && convertDate('MMM D', selectedDate.from?.getTime());
    const to = selectedDate.to?.getTime() && convertDate('MMM D', selectedDate.to?.getTime());
    if (!isDefined(from) && !isDefined(to)) {
      return convertDate('MMM D', new Date().getTime());
    }
    return `${from ?? '...'} - ${to ?? '...'}`;
  };

  // const handleInputChange = (inputValue: string) => setSearchTerm(inputValue);

  useEffect(() => {
    getUsers(1);
    getAllProjects();
  }, []);
  // useEffect(() => {
  //   getUsers(1);
  // }, [debouncedSearchTerm]);
  useEffect(() => {
    props.info(1, selectedUsers, undefined, selectedProjects?.value, range);
  }, [selectedUsers, selectedProjects, range]);
  return (
    <>
      <section className="w-100 row">
        <div className="col-4">
          <label className={`${classes.label} mb-2`}>Employees</label>
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#D0D5DD' : '#D0D5DD',
                borderRadius: 8,
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                padding: '8px',
                width: '80%',
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                overflowY: 'auto',
                maxHeight: 130,
              }),
              multiValue: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: '#FFF',
                borderRadius: 6,
                border: '1px solid #D0D5DD',
              }),
              multiValueRemove: (baseStyles) => ({
                ...baseStyles,
                color: 'rgba(152, 162, 179, 1)',
              }),
            }}
            closeMenuOnSelect={false}
            isMulti
            isSearchable
            isClearable
            isLoading={loading}
            options={users}
            value={selectedUsers}
            onChange={handleSelectUser}
            placeholder={'Select Employee'}
          />
        </div>
        <div className="col-4 d-flex">
          <div className={classes.projectSection}>
            <label className={`${classes.label} mb-2`}>Project</label>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? '#D0D5DD' : '#D0D5DD',
                  borderRadius: 8,
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  padding: '8px',
                }),
                multiValueRemove: (baseStyles) => ({
                  ...baseStyles,
                  color: 'rgba(152, 162, 179, 1)',
                }),
              }}
              closeMenuOnSelect={true}
              isSearchable
              isClearable
              isLoading={loading}
              options={projects}
              value={selectedProjects}
              onChange={handleSelectProject}
              placeholder={'Select a Project'}
            />
          </div>
        </div>
        <div className={`col-4 d-flex position-relative justify-content-end ${classes.btnDate}`}>
          <button
            onClick={toggleDatePicker}
            className={`${classes.selectDate}  d-flex justify-content-center align-items-center mb-2`}>
            <img src={calendar} alt="calendar icon" className={`me-2`} />
            Select dates
          </button>
        </div>
      </section>
      <section className="w-100 d-flex justify-content-end">
        {showDatePicker && (
          <div className={`${classes.datePicker} d-flex flex-row-reverse end-10`}>
            <div>
              <DayPicker mode="range" selected={range} onSelect={setRange} numberOfMonths={2} />
              <DatePickerFooter
                t9n={t9n}
                apply={applyDatePickerChanges}
                cancel={cancelDatePickerChanges}
                range={range}
              />
            </div>
            <div className={classes.sideBar}>
              <button
                className={`${rangeSelected === Range.TODAY ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.TODAY)}>
                Today
              </button>
              <button
                className={`${rangeSelected === Range.YESTERDAY ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.YESTERDAY)}>
                Yesterday
              </button>
              <button
                className={`${rangeSelected === Range.THIS_WEEK ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.THIS_WEEK)}>
                This week
              </button>
              <button
                className={`${rangeSelected === Range.LAST_WEEK ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.LAST_WEEK)}>
                Last week
              </button>
              <button
                className={`${rangeSelected === Range.THIS_MONTH ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.THIS_MONTH)}>
                This month
              </button>
              <button
                className={`${rangeSelected === Range.LAST_MONTH ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.LAST_MONTH)}>
                Last month
              </button>
              <button
                className={`${rangeSelected === Range.THIS_YEAR ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.THIS_YEAR)}>
                This Year
              </button>
              <button
                className={`${rangeSelected === Range.LAST_YEAR ? classes.activeRange : ''} `}
                onClick={() => handleRange(Range.LAST_YEAR)}>
                Last year
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

interface IDatePickerFooter {
  t9n: IAppLocale;
  apply: () => void;
  cancel: () => void;
  range?: DateRange;
}

export const DatePickerFooter = ({ t9n, apply, cancel, range }: IDatePickerFooter) => {
  return (
    <div className={`${classes.footer} d-flex align-items-center gap-1`}>
      <div className="col-6 d-flex align-items-center">
        <div className="col-5">
          <div className={`${classes.dateBox} d-flex align-items-center justify-content-center gap-1`}>
            {convertDate('MMM D, YYYY', String(range?.from ?? new Date()))}
          </div>
        </div>
        <div className={`${classes.dash} col-1 d-flex justify-content-center`}> - </div>
        <div className="col-5">
          <div className={`${classes.dateBox} d-flex align-items-center justify-content-center gap-1`}>
            {convertDate('MMM D, YYYY', String(range?.to ?? new Date()))}
          </div>
        </div>
      </div>
      <div className="col-6 d-flex align-items-center gap-1 justify-content-end">
        <div className="col-4">
          <button className={`${classes.cancel} w-100`} onClick={cancel}>
            {t9n.cancel_text}
          </button>
        </div>
        <div className="col-5">
          <button className={`${classes.apply} w-100`} onClick={apply}>
            {t9n.apply_text}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualReport;
