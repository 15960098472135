import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../store/error-slice';
import { IUser } from '../repository/ReportRepository';
import Loading from '../../../components/base/Loading';
import Table from '../../../components/base/Table';
import { RootState } from '../../../store';
import { RepoType } from '../../../types/sharedTypes';
import { useTranslation } from '../../../providers/locale-provider';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker, { DatePickerPosition } from '../../../components/base/DatePicker';
import { DateRange } from 'react-day-picker';
import { Option } from '../../../types/sharedTypes';
import Fullname from '../../../components/base/Fullname';
import classes from './style/report.module.scss';
import { setShortText } from '../../../core/helpers/utils';
import minus from '../../../assets/image/Icons/minus.svg';
import Search from '../../../components/base/Search';
import SelectInput from '../../../components/base/SelectInput';

const reportRepository = () => RepoFactory.get(RepoType.Report);
const employeesRepository = () => RepoFactory.get(RepoType.Employees);

export interface Exportprops {
  info: (
    reportType: number,
    user: CustomOption[],
    date: Date | undefined,
    projectId?: string,
    dateRange?: DateRange | undefined
  ) => void;
}

export interface CustomOption {
  value: string;
  label: JSX.Element;
  name: string;
}
const MonthlyReport = (props: Exportprops) => {
  const { pageNumber } = useParams();
  const { pageType } = useParams();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<CustomOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<CustomOption[]>([]);
  const [userChecked, setUserChecked] = useState<CustomOption[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [selectedDate, setSelectedDate] = useState<Date | any>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [order, setOrder] = useState<Option>();

  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const companyId = userInfo.user.defaultCompanyId;

  const orderOptions: Option[] = [
    { value: '1', title: t9n.lastName_sort_descending },
    { value: '2', title: t9n.lastName_sort_ascending },
  ];

  const handleSort = (selectedItem: Option) => setOrder(selectedItem);
  const handleSelectedDate = (date: Date | undefined) => setSelectedDate(date);
  const handleDebouncedSearchTerm = (debouncedSearchTerm: string) => {
    setCurrentPage(1);
    setDebouncedSearchTerm(debouncedSearchTerm);
  };
  const handleClick = (e: React.FormEvent<HTMLInputElement>) => {
    const { id, checked } = e.currentTarget;
    setIsCheck([...isCheck, id]);
    for (let i = 0; i < users.length; i++) {
      if (id === String(users[i].value)) {
        setUserChecked([...userChecked, users[i]]);
      }
    }

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setUserChecked(userChecked.filter((item) => String(item.value) !== id));
      setIsCheckAll(false);
    }
  };

  const sendDataForTable = () => {
    let dataArray: any = [];
    users.forEach((item: any, index) => {
      dataArray.push({
        items: [
          <p className={`mb-0 d-flex align-items-center`}>
            <label className="container-checkbox ">
              <input
                type="checkbox"
                id={String(item.value)}
                checked={isCheck.includes(String(item.value))}
                onChange={handleClick}
              />
              <span className="checkmark"></span>
            </label>
            {item.label}
          </p>,
        ],
      });
    });
    return dataArray;
  };
  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
    getUsers(SelectedPage.selected + 1);
  };
  const getUsers = (pageNumber: number) => {
    setLoading(true);
    let params = {
      page: pageNumber,
      pageSize: itemsPerPage,
      search: debouncedSearchTerm,
      sortBy: 'last_name',
      order: order && order.value === '1' ? 'asc' : 'desc',
    };
    employeesRepository()
      .getCompanyUsers(params, companyId)
      .then((result: AxiosResponse<any>) => {
        setLoading(false);
        const data = result.data.data;
        let users: CustomOption[] = [];
        data.forEach((user: IUser) => {
          users.push({
            value: String(user.id),
            label: (
              <span className={classes.info}>
                <Fullname firstname={user.name} lastname={user.last_name} />
                <div className={classes.employeeEmail} title={user.email}>
                  {setShortText({ text: user.email, length: 15 }) ?? <img src={minus} alt="minus" />}
                </div>
              </span>
            ),
            name: user.name,
          });
        });
        setUsers(users);
        setTotalPage(result.data.metadata.totalPages);
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleSelectAll = (e: React.FormEvent<HTMLInputElement>) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(users.map((user) => String(user.value)));
    setUserChecked(users.map((user) => user));
    if (isCheckAll) {
      setIsCheck([]);
      setUserChecked([]);
    }
  };

  useEffect(() => {
    setCurrentPage(pageNumber);
    getUsers(currentPage);
  }, [pageNumber, order]);

  useEffect(() => {
    setCurrentPage(1);
    getUsers(1);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/reports/${pageType}/${currentPage}`);
    }
  }, [currentPage]);

  useEffect(() => {
    props.info(0, userChecked, selectedDate);
  }, [isCheck, selectedDate]);
  return (
    <>
      <Loading loading={loading} />
      <div className={classes.filter}>
        <div className="enter-date mb-5 w-25 position-relative">
          <DatePicker
            datePickerPosition={DatePickerPosition.TOP_Right}
            handleSelectedDate={handleSelectedDate}
            selectedDate={selectedDate}
            isDateRange={true}
          />
        </div>
        <div className={classes.sortName}>
          <SelectInput
            label=""
            placeHolder={t9n.sort_by_text}
            options={orderOptions}
            handleselectedOption={handleSort}
          />
        </div>
      </div>

      <div className={classes.search}>
        <Search handleDebouncedSearchTerm={handleDebouncedSearchTerm} />
      </div>
      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        textCenter={false}
        label={t9n.navbar_link_employees}
        message={t9n.no_employees}
        headers={[
          <p className="mb-0 d-flex align-items-center">
            <label className="container-checkbox ">
              <input type="checkbox" name="selectAll" id="selectAll" onChange={handleSelectAll} checked={isCheckAll} />
              <span className="checkmark"></span>
            </label>
            <span className="mx-4">{t9n.name_text}</span>
          </p>,
        ]}
        data={sendDataForTable()}
      />
    </>
  );
};
export default MonthlyReport;
