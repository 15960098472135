import { Route, Routes } from 'react-router-dom';
import Employees from '../pages/Employees';
import EditEmployee from '../components/EditEmployee';

const EmployeeRouter = () => {
  return (
    <Routes>
      <Route path="/:pageType/:pageNumber" exact element={<Employees />} />
      <Route path="/edit/:id" element={<EditEmployee />} />
    </Routes>
  );
};
export default EmployeeRouter;
