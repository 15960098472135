import { Event } from 'react-big-calendar';
import classes from './style/EventInfo.module.scss';
import { Tooltip } from '@mui/material';
import { getTimeDifference } from '../../../../core/helpers/utils';

interface IEvent {
  event?: Event;
}

const EventInfo = (event: IEvent) => {
  const createEvent = ({ event }: IEvent) => {
    const eventType = event?.resource?.type;
    const duration = getTimeDifference({ start: event?.start!, end: event?.end! });
    switch (eventType) {
      case 'in_out':
        return (
          <div className={`${classes.in_out} ${event?.resource.edited ? classes.edited : ''}`}>
            <Tooltip className={classes.toolTip} placement={'bottom'} arrow title={duration}>
              <p className={classes.title}>
                {event?.title} <span>{event?.resource.edited ? '*' : ''}</span>
              </p>
            </Tooltip>
          </div>
        );
      // case 'Out':
      //   return (
      //     <div className={classes.out}>
      //       <p className={classes.title}>{event?.title}</p>
      //     </div>
      //   );

      // case 'Duration':
      //   return (
      //     <div className={classes.duration}>
      //       <p className={classes.title}>{event?.title}</p>
      //     </div>
      //   );

      // case 'Break':
      //   return (
      //     <div className={classes.break}>
      //       <p className={classes.title}>{event?.title}</p>
      //     </div>
      //   );

      default:
        return null;
    }
  };

  return <>{createEvent(event)}</>;
};
export default EventInfo;
