import trash from '../../../../assets/image/Icons/trash.svg';
import companyClasses from '../style/CompanyBaseStyles.module.scss';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { errorActions } from '../../../../store/error-slice';

import Loading from '../../../../components/base/Loading';
import DeleteModal from '../../../../components/base/DeleteModal';
import { useTranslation } from '../../../../providers/locale-provider';
import { RepoType } from '../../../../types/sharedTypes';

const managersRepository = () => RepoFactory.get(RepoType.Managers);

interface props {
  managerId: number;
  companyId?: number;
  handleDeleteManager?: (status: boolean) => void;
}

const DeleteManager = (props: props) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteManagerModal, setDeleteManagerModal] = useState(false);

  const openDeleteManagerModal = (status: boolean) => {
    setDeleteManagerModal(status);
  };

  const exitModal = () => setDeleteManagerModal(false);

  const deleteManager = () => {
    let body = {
      userId: props.managerId,
    };

    setLoading(true);
    managersRepository()
      .deleteManager(body, props.companyId)
      .then((res: AxiosResponse<any>) => {
        exitModal();
        props.handleDeleteManager && props.handleDeleteManager(true);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Loading loading={loading} />
      <img
        className={companyClasses.companyIcon}
        onClick={() => openDeleteManagerModal(true)}
        src={trash}
        alt="Delete icon"
      />

      {deleteManagerModal && (
        <DeleteModal
          handleDelete={deleteManager}
          exitModal={exitModal}
          title={t9n.delete_manager_text}
          text={t9n.delete_manager_desc_text}
        />
      )}
    </>
  );
};
export default DeleteManager;
