import {ENLocale} from "./en-locale"
import {FALocale} from "./fa-locale"

export enum Language {
    EN = "en", // English
    FA = "fa", // Farsi
}

export const DEFAULT_LOCALE = Language.EN

export type IAppLocale = typeof ENLocale;

export const AppLocales: Record<Language, IAppLocale> = {
    [Language.EN]: ENLocale,
    [Language.FA]: FALocale,
}

export const LanguageList = [Language.EN, Language.FA]
