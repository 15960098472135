import classes from './style/DeleteRequest.module.scss';
import { useDispatch } from 'react-redux';
import trash from '../../../assets/image/Icons/trash.svg';
import { useState } from 'react';
import Modal from '../../../components/base/Modal';
import BaseButton from '../../../components/base/BaseButton';
import Loading from '../../../components/base/Loading';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../store/error-slice';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';
import Toast from '../../../components/base/Toast';

const requestsRepository = () => RepoFactory.get(RepoType.Requests);

interface props {
  requestId: number;
  handleDeleteRequest?: (status: boolean) => void;
}
const DeleteRequest = (props: props) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteRequestModal, setDeleteRequestModal] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const openDeleteRequestModal = () => setDeleteRequestModal(true);
  const exitModal = () => setDeleteRequestModal(false);
  const handleToastClose = () => setShowToast(false);

  const deleteRequest = () => {
    setLoading(true);
    requestsRepository()
      .cancelRequest(props.requestId)
      .then((result: AxiosResponse<any>) => {
        props.handleDeleteRequest && props.handleDeleteRequest(true);
        setLoading(false);
        exitModal();
        setShowToast(true);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };
  return (
    <>
      <Loading loading={loading} />
      {showToast && <Toast message={t9n.cancel_request_success} onClose={handleToastClose} />}
      <img src={trash} alt="delete icon" onClick={openDeleteRequestModal} />
      {deleteRequestModal && (
        <Modal
          size="sm"
          header={t9n.cancel_request_text}
          exitAction={exitModal}
          body={
            <div className="w-100">
              <p className={`mb-0`}>{t9n.cancel_request_modal_desc}</p>
            </div>
          }
          action={
            <div className={classes.buttonBox}>
              <div className={classes.closeButton} key={1}>
                <BaseButton
                  hasBlock={true}
                  size="lg"
                  title={t9n.close_text}
                  color="white"
                  hasRightIcon={false}
                  hasLeftIcon={false}
                  onClickHandler={exitModal}></BaseButton>
              </div>
              <div className={`${classes.okButton} w-50`} key={2}>
                <BaseButton
                  hasBlock={true}
                  size={'lg'}
                  title={t9n.yes_text}
                  color="red"
                  hasRightIcon={false}
                  hasLeftIcon={false}
                  onClickHandler={deleteRequest}></BaseButton>
              </div>
            </div>
          }></Modal>
      )}
    </>
  );
};
export default DeleteRequest;
