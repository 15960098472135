import { Route, Routes } from 'react-router-dom';
import Setting from '../pages/Setting';

const SettingRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Setting />} />
    </Routes>
  );
};
export default SettingRouter;
