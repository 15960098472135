import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/leaves';

const RequestManagementRepository = {
  updateStatus(body: { status: string; comment?: string; optional_comment_modal: string}, id: string) {
    return BaseRepository.patch(`${resource}/${id}`, body);
  },
  undoStatus(id: string) {
    return BaseRepository.patch(`${resource}/${id}/undo`);
  },
  getAllRequest(params: {
    status: string;
    page: number;
    pageSize: number;
    company: number;
    start?: number;
    end?: number;
    createdAt?: string;
    reason?: string;
  }) {
    return BaseRepository.get(`${resource}`, {
      params,
    });
  },
};
export default RequestManagementRepository;
