import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/users';

export interface IUser {
  id: number;
  username: string;
  email: string;
  name: string;
  last_name:string;
  hourly_rate: number;
  role: { id: number; title: string; is_shared: boolean };
}
const ReportRepository = {
  getCompanies() {
    return BaseRepository.get(`${resource}/companies`);
  },
};
export default ReportRepository;
