import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/companies';

const CompaniesRepository = {
  createCompany(body: FormData) {
    return BaseRepository.postForm(`${resource}`, body);
  },
  updateCompany(body: FormData, id: string) {
    return BaseRepository.put(`${resource}/${id}`, body);
  },
  editCompany(body: FormData, id: string) {
    return BaseRepository.patchForm(`${resource}/${id}`, body);
  },
  getByIdCompany(id: string) {
    return BaseRepository.get(`${resource}/${id}`);
  },
  deleteCompany(id: string) {
    return BaseRepository.delete(`${resource}/${id}`);
  },
  getAllCompanies(params: { page: number; pagesize: number }) {
    return BaseRepository.get(`${resource}`, { params });
  },
};
export default CompaniesRepository;
