import authRepository from '../modules/Auth/repository/authRepository';
import RequestRepository from '../modules/Requests/repository/RequestRepository';
import timeSheetRepository from '../modules/TimeSheet/repository/timeSheetRepository';
import CompaniesRepository from '../modules/companies/repository/CompaniesRepository';
import ManagersRepository from '../modules/companies/repository/ManagersRepository';
import projectRepository from '../modules/project/repository/projectRepository';
import RequestManagementRepository from '../modules/RequestsManagement/repository/RequestRepository';
import EmployeesRepository from '../modules/Employees/repository/EmployeeRepository';
import profileRepository from '../modules/Profile/repository/profileRepository';
import TeamRepository from '../modules/Team/repository/TeamRepository';
import LeaveRepository from '../modules/Leave/repository/LeaveRepository';
import ReportRepository from '../modules/Reports/repository/ReportRepository';
import SettingRepository from '../modules/Setting/repository/SettingRepository';
import ShiftRepository from '../modules/Shifts/repository/ShiftRepository';
import CompanyRepository from '../modules/Company/repository/CompanyRepository';

interface repositoriesTypeMap {
  auth: any;
}
export type repositoriesType = repositoriesTypeMap[keyof repositoriesTypeMap];
const repositories: repositoriesType = {
  auth: authRepository,
  attendance: timeSheetRepository,
  companies: CompaniesRepository,
  managers: ManagersRepository,
  requests: RequestRepository,
  project: projectRepository,
  requestManagement: RequestManagementRepository,
  employees: EmployeesRepository,
  profile: profileRepository,
  teams: TeamRepository,
  leave: LeaveRepository,
  report: ReportRepository,
  setting: SettingRepository,
  shift: ShiftRepository,
  company: CompanyRepository,
};

export const RepoFactory: repositoriesType = {
  get: (name: string) => repositories[name],
};
