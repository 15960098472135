import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/companies';

const CompanyRepository = {
  getCompany(id: number) {
    return BaseRepository.get(`${resource}/${id}/setting`);
  },

  editCompany(body:FormData, id: number) {
    return BaseRepository.patchForm(`${resource}/${id}/setting`, body);
  },
};
export default CompanyRepository;
