import { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../../store/error-slice';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { Project } from '../types/type';
import { Employee, RepoType, OptionalUndefined } from '../../../../types/sharedTypes';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';

import classes from './style/AssignEmployee.module.scss';
import projectClasses from '../style/ProjectBaseStyles.module.scss';

import Plus from '../../../../assets/image/Icons/Plus.svg';
import minus from '../../../../assets/image/Icons/minus.svg';
import Checkbox from '../../../../components/base/Checkbox';
import BaseButton from '../../../../components/base/BaseButton';
import Loading from '../../../../components/base/Loading';
import Modal from '../../../../components/base/Modal';
import Table from '../../../../components/base/Table';
import ToggleSwitch from '../../../../components/base/ToggleSwitch';
import { useTranslation } from '../../../../providers/locale-provider';
import InfiniteScroll from 'react-infinite-scroll-component';
import Fullname from '../../../../components/base/Fullname';
import { setShortText } from '../../../../core/helpers/utils';

interface AssignedEmployeesList {
  id: number;
  description: number;
}
interface AssignEmployeeProps {
  handleNewEmployeeAssign: (status: boolean) => void;
  project: OptionalUndefined<Project>;
}

const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);
const projectRepository = () => RepoFactory.get(RepoType.Project);

const AssignEmployee = (props: AssignEmployeeProps) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state: RootState) => state.auth.userInfo.user.defaultCompanyId);

  const [loading, setLoading] = useState<boolean>(false);
  const [assignEmployeeModal, setAssignEmployeeModal] = useState<boolean>(false);
  const [employeesList, setEmployeesList] = useState<Employee[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<number[]>([]);
  const [descriptionStatus, setDescriptionStatus] = useState<boolean[]>();
  const [assignedEmployeesList, setAssignedEmployeesList] = useState<AssignedEmployeesList[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  let map = new Map<number, boolean>();

  const ButtonIcon: React.FC = () => <img src={Plus} alt="Plus" />;
  const openAssignEmployeeModal = () => setAssignEmployeeModal(true);
  const exitModal = () => setAssignEmployeeModal(false);

  const handleCheckboxChange = (index: number) => {
    setSelectedEmployees((prevSelected) =>
      prevSelected.includes(index) ? prevSelected.filter((item) => item !== index) : [...prevSelected, index]
    );
  };

  const handleToggleChange = (index: number, checked: boolean) => {
    const newstatus = descriptionStatus ? [...descriptionStatus] : [];
    newstatus[index] = checked;
    setDescriptionStatus(newstatus);
  };
  const getAllEmployees = () => {
    if (!hasMore) return;

    EmployeesRepository()
      .getCompanyUsers({ page: currentPage, pageSize: 10 }, companyId)
      .then((res: AxiosResponse<any>) => {
        setLoading(true);
        const data = res.data.data;
        if (data.length === 0) {
          setHasMore(false);
          setLoading(false);
        } else {
          const userIds = props.project?.users.map((user) => user.id);
          const filteredEmployeesList: Employee[] = data.filter((item: Employee) => !userIds?.includes(item.id));

          if (!map.get(currentPage)) {
            setEmployeesList((prevList) => [...prevList, ...filteredEmployeesList]);
            if (filteredEmployeesList.length < 5) {
              setCurrentPage((prevPage) => prevPage + 1);
            }
          }
          map.set(currentPage, true); // Mark this page as fetched

          setLoading(false);
        }
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleAssignEmployeeList = () => {
    let assignedEmployees: AssignedEmployeesList[] = [];
    selectedEmployees.forEach((index) => {
      const employee = employeesList[index];
      const description = descriptionStatus?.[index] ? 1 : 0;
      if (employee) {
        assignedEmployees.push({
          id: employee.id,
          description: description,
        });
      }
    });
    setAssignedEmployeesList(assignedEmployees);
  };

  const addNewEmployees = () => {
    setLoading(true);
    handleAssignEmployeeList();
    const body = {
      users: assignedEmployeesList,
    };
    projectRepository()
      .assignEmployees(body, props.project?.id)
      .then((res: AxiosResponse<any>) => {
        assignedEmployeesList.length && props.handleNewEmployeeAssign(true);
        setSelectedEmployees([]);
        exitModal();
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleAssignEmployeeList();
  }, [selectedEmployees, descriptionStatus]);

  useEffect(() => {
    if (employeesList.length) {
      let status: boolean[] = [];
      employeesList.forEach((employee: Employee) => {
        status.push(false);
      });
      setDescriptionStatus(status);
    }
  }, [employeesList]);

  const sendDataForTable = () => {
    let dataArray: any = [];

    if (employeesList.length) {
      employeesList.forEach((employee: Employee, index) => {
        dataArray.push({
          items: [
            <div className={classes.checkbox}>
              <Checkbox
                id={index.toString()}
                onChange={() => handleCheckboxChange(index)}
                checked={selectedEmployees.includes(index)}
              />
              <div className={classes.checkboxCell}></div>

              <span className={classes.info}>
                <Fullname firstname={employee.name} lastname={employee.last_name} />
                <div className={classes.employeeEmail} title={employee.email}>
                  {setShortText({ text: employee.email, length: 15 }) ?? <img src={minus} alt="minus" />}
                </div>
              </span>
            </div>,

            <div className={classes.actions}>
              <ToggleSwitch onChange={(checked) => handleToggleChange(index, checked)} />
            </div>,
          ],
        });
      });
    }
    return dataArray;
  };

  useEffect(() => {
    props.project && assignEmployeeModal && getAllEmployees();
  }, [currentPage]);

  useEffect(() => {
    if (!assignEmployeeModal) {
      setCurrentPage(1);
      setEmployeesList([]);
      setHasMore(true);
      map.clear();
    } else {
      props.project && assignEmployeeModal && getAllEmployees();
    }
  }, [assignEmployeeModal]);

  const fetchMorePosts = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const employeeListMsg = hasMore ? 'loading...' : t9n.no_employees;

  return (
    <>
      <Loading loading={loading} />

      <BaseButton
        title={t9n.assign_employee_text}
        color="primary"
        hasLeftIcon={true}
        icon={<ButtonIcon />}
        onClickHandler={openAssignEmployeeModal}
      />

      {assignEmployeeModal && (
        <Modal
          header={t9n.assign_employee_text}
          exitAction={exitModal}
          body={
            <div className={classes.employeeContainer} id="scrollableDiv">
              <div className={classes.modalBody}>
                <div className={classes.headerContainer}>
                  <div className={classes.tableHeader}>
                    <span className={classes.headerText + ' ' + classes.headerName}>{t9n.name_text}</span>
                    <span className={classes.headerText + ' ' + classes.headerDescription}>
                      {t9n.require_work_desc_text}
                    </span>
                  </div>
                </div>
                <InfiniteScroll
                  scrollableTarget="scrollableDiv"
                  dataLength={employeesList.length}
                  next={fetchMorePosts}
                  hasMore={hasMore}
                  loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}>
                  <Table
                    disableHeader={true}
                    buttonHeader={false}
                    textCenter={false}
                    label={''}
                    message={employeeListMsg}
                    headers={[
                      <span className={classes.headerText + ' ' + classes.headerName}>{t9n.name_text}</span>,
                      <span className={classes.headerText + ' ' + classes.headerDescription}>
                        {t9n.require_work_desc_text}
                      </span>,
                    ]}
                    data={sendDataForTable()}
                  />
                </InfiniteScroll>
              </div>
            </div>
          }
          action={
            <div className={projectClasses.buttonBox}>
              <BaseButton
                size={'lg'}
                hasBlock={true}
                title={t9n.assign_btn_text}
                color="primary"
                onClickHandler={addNewEmployees}></BaseButton>
            </div>
          }></Modal>
      )}
    </>
  );
};
export default AssignEmployee;
