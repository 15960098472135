import { Route, Routes } from 'react-router-dom';
import Project from '../pages/Project';
import EditProject from '../components/EditProject/EditProject';

const ProjectRouter = () => {
  return (
    <Routes>
      <Route path="/:pageType/:pageNumber" exact element={<Project />} />
      <Route path="/edit/:id" element={<EditProject />} />
    </Routes>
  );
};
export default ProjectRouter;
