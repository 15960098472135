export type Optional<T> = T | null;
export type OptionalOrMissing<T> = T | null | undefined;
export type OptionalUndefined<T> = T | undefined;

export interface Option {
  value: string;
  title: string;
  disable?: boolean;
  selected?: boolean;
}

interface Attendance {
  id: number;
  check_in_datetime: number;
  check_out_datetime: number;
  edited: number;
  edited_datetime: string;
}

export interface Employee {
  id: number;
  username: string;
  email?: string;
  name: string;
  last_name: string;
  hourly_rate: string | null;
  role: { id: number; title: string; is_shared: boolean };
  attendances?: Attendance[];
  employment_time?: string;
}

export interface Manager {
  id: number;
  username: string;
}

export interface Team {
  id: number;
  title: string;
  deletedAt: null | string;
  createdAt: string;
  company: Company;
}

interface Company {
  id: number;
  name: string;
  deletedAt: null | string;
  createdAt: string;
}

export enum UserRole {
  MANAGER = 'manager',
  REGULAR = 'regular',
  SYSADMIN = 'sysadmin',
}

export enum PunchType {
  PUNCHIN = 'punchIn',
  PUNCHOUT = 'punchOut',
}

export enum RepoType {
  Auth = 'auth',
  Attendance = 'attendance',
  Companies = 'companies',
  Managers = 'managers',
  Requests = 'requests',
  Project = 'project',
  RequestManagement = 'requestManagement',
  Employees = 'employees',
  Profile = 'profile',
  Teams = 'teams',
  Leave = 'leave',
  Report = 'report',
  Company = 'company',
}

export enum TimeOffType {
  Pending = 'pending',
  Denied = 'denied',
  Approved = 'approved',
  Canceled = 'canceled',
}
