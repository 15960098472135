import classes from './style/Header.module.scss';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import clock from '../../assets/image/logo/clock-watch-square-white.svg';
import HeaderProfile from './HeaderProfile';
import { IAppLocale } from '../../locales';
import { useTranslation } from '../../providers/locale-provider';
import { useLocation } from 'react-router-dom';
export interface ILink {
  key: string;
  link: string;
  otherLink?: string[];
}
export interface IProps {
  links: ILink[];
}
const Header = (props: IProps) => {
  const { t9n } = useTranslation();
  const location = useLocation();
  const NAVBAR_LINK_T9N_MAP: Record<string, (t9n: IAppLocale) => string> = {
    ['timesheet']: (t9n) => t9n.navbar_link_my_time_sheet,
    ['requests']: (t9n) => t9n.navbar_link_timeOff,
    ['reports']: (t9n) => t9n.navbar_link_reports,
    ['leave']: (t9n) => t9n.navbar_link_leave_status,
    ['companies']: (t9n) => t9n.navbar_link_companies,
  };

  return (
    <div className={classes.header}>
      <Container>
        <div className={classes.headerContainer}>
          <div className={classes.content}>
            <div className={classes.logoWrap}>
              <div className={classes.logo}>
                <img src={clock} alt="clock logo" />
              </div>
              <div className={classes.brandName}>
                <strong>Rayka</strong> clock
              </div>
            </div>
            <div className={classes.menu}>
              {props.links.map(({ link, key, otherLink }, index) => (
                <NavLink
                  className={
                    location.pathname.includes(link.slice(0, link.indexOf(','))) ||
                    (otherLink &&
                      otherLink.some((link) => location.pathname.includes(link.slice(0, link.indexOf(',')))))
                      ? classes.activeMenuItem
                      : classes.menuItem
                  }
                  to={`${link}`}
                  key={index}>
                  {NAVBAR_LINK_T9N_MAP[key](t9n)}
                </NavLink>
              ))}
            </div>
          </div>
          <HeaderProfile />
        </div>
      </Container>
    </div>
  );
};
export default Header;
