import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { AxiosError } from 'axios';

let isRefreshToken = false;

export const authHeader: { Authorization: string } | {} = axios.interceptors.request.use((req) => {
  req.headers.Authorization = localStorage.getItem('access_token')
    ? isRefreshToken
      ? 'Bearer ' + localStorage.getItem('refresh_token')
      : 'Bearer ' + localStorage.getItem('access_token')
    : null;
  return req;
});

async function refreshAccessToken(): Promise<string | null> {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) throw new Error('No refresh token available');
    const response = await axios.post(
      '/auth/refresh',
      { test: 'test' },
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );
    const { access_token, refresh_token } = response.data;
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);

    return access_token;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('isLoggedIn');
    window.location.href = '/login';
    return null;
  }
}

axios.interceptors.response.use(
  async (response: AxiosResponse) => {
    isRefreshToken = false;
    return response;
  },
  async (error: AxiosError) => {
    const status = error.response?.status;
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };

    if (status === 401 && !originalRequest._retry) {
      isRefreshToken = true;
      originalRequest._retry = true;

      // Set the Authorization header with the refresh token
      const refreshToken = localStorage.getItem('refresh_token');

      // Call the refreshAccessToken function
      const accessToken = await refreshAccessToken();

      if (accessToken) {
        localStorage.setItem('access_token', accessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        return axios(originalRequest);
      }
      isRefreshToken = false;
    }
    return Promise.reject(error);
  }
);

const prodBaseUrl = 'https://iclock.online/api/';
const devBaseUrl = 'https://iclock.online/api/';
export const baseURL = process.env.NODE_ENV === 'development' ? devBaseUrl : prodBaseUrl;
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.patch['Content-Type'] = 'application/json';
axios.defaults.headers.Accept = 'application/json';

if (authHeader) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
}

export default axios;
