import { createSlice } from '@reduxjs/toolkit';
const reloadAlertSlice = createSlice({
  name: 'reloadAlert',
  initialState: {
    loginAlert: false,
    logoutAlert: false,
    showAlert: false,
  },
  reducers: {
    setLoginAlert(state, action) {
      state.loginAlert = action.payload;
    },
    setLogoutAlert(state, action) {
      state.logoutAlert = action.payload;
    },
    setShowAlert(state, action) {
      state.showAlert = action.payload;
    },
  },
});

export const { setLoginAlert, setLogoutAlert, setShowAlert } = reloadAlertSlice.actions;

export default reloadAlertSlice.reducer;
