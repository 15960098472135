import { Interface } from 'readline';
import BaseRepository from '../../../baseRepository/BaseRepository';
import { UserStatus } from '../../Auth/types/type';
const resource = '/attendances';
const resourceUser = '/users';

export interface IEvent {
  check_in_datetime: string;
  check_out_datetime: string;
  duration: string;
  break?: string;
  edited?: number;
  id?: number;
  checkIn: string;
  checkOut: string;
  longEvent?: boolean;
  attendanceDur?: string;
  day?: string;
}

export interface IMetaData {
  date: string;
  duration: string;
  break: string;
  leaveDuration: string;
}
export interface IHistory {
  end_date: string;
  start_date: string;
  user: {
    hourly_rate: number;
    id: number;
    name: string;
    role: { id: number; title: string; is_shared: boolean };
    username: string;
  };
  result: {
    data: IEvent[];
  };
}
export interface IStatus {
  result: {
    status: UserStatus;
    attendanceId: number;
    longEvent: boolean;
    first_check_in_datetime: number;
    last_check_out_datetime: number;
    currentDateTimeEpoch: number;
    update_punch_dayslimit: number;
    new_punch_dayslimit: number;
  };
}

export interface IAttendance {
  project: number;
  user: number;
  check_in_datetime: number | string;
  check_out_datetime: number | string;
  edited_datetime: number | string;
  duration: number | string;
  id: number;
  attendanceProjects: any[];
}
export interface IAttendanceProject {
  attendanceId: number;
  projectId: number;
  description: string;
  duration: string;
  project: {
    id: number;
    name: string;
    status: boolean;
    description: string;
  };
}
export interface INewAttendance {
  newAttendance: IAttendance;
}
export interface IUpdateAttendance {
  updatedAttendance: IAttendance;
}
export interface IWorkTime {
  result: {
    metadata: IMetaData[];
    formattedTotalAttendance: string;
    mandatory_working_hours_month: number;
    allAtten: any[];
  };
}
interface Details {
  projectId: number;
  duration: number;
  description: string;
}
export interface IPunch {
  punchIn?: number;
  punchOut?: number;
  punchType?: string;
  detail: Details[] | null;
}

const timeSheetRepository = {
  getHistory(params: { start_date: string; end_date: string; startYearDate: number; endYearDate: number }) {
    return BaseRepository.get<IHistory>(`${resource}/history`, {
      params,
    });
  },
  userStatus() {
    return BaseRepository.get<IStatus>(`${resource}/status`);
  },
  monthlyTime(params: { start_date: string; end_date: string; startYearDate: number; endYearDate: number }) {
    return BaseRepository.get<IWorkTime>(`${resource}/calculate-period`, { params });
  },
  punchIn(projectId: number) {
    return BaseRepository.post<INewAttendance>(`${resource}/punch-in`, { project: projectId });
  },
  punchOut(punchId: number, detail?: IPunch) {
    return BaseRepository.post<any>(`${resource}/${punchId}/punch-out`, detail);
  },
  projects() {
    return BaseRepository.get(`${resourceUser}/projects`);
  },
  getReport(params: { userId: number; from: string; to: string; startYearDate: number; endYearDate: number }) {
    return BaseRepository.get(`${resource}/report`, { params });
  },
  attendanceDay(params: { date: string }) {
    return BaseRepository.get(`${resource}/calculate-day`, { params });
  },
  deleteAttendance(id: number) {
    return BaseRepository.delete(`${resource}/${id}`);
  },
  addNewPunch(data: IPunch) {
    return BaseRepository.post(`${resource}`, data);
  },
  updatePunchOut(attendanceId: number, data: IPunch) {
    return BaseRepository.put(`${resource}/${attendanceId}/punch-out`, data);
  },
  attendanceInfo(attendanceId: number) {
    return BaseRepository.get<IUpdateAttendance>(`${resource}/${attendanceId}`);
  },
  manualReport(params: {
    userIds: string;
    from: string;
    to: string;
    projectId: string;
    startYearDate: number;
    endYearDate: number;
  }) {
    let query = ``;

    if (params.projectId) {
      query = `userIds=${params.userIds}&from=${params.from}&to=${params.to}&projectId=${params.projectId}&startYearDate=${params.startYearDate}&endYearDate=${params.endYearDate}`;
    } else {
      query = `userIds=${params.userIds}&from=${params.from}&to=${params.to}&startYearDate=${params.startYearDate}&endYearDate=${params.endYearDate}`;
    }
    return BaseRepository.get(`${resource}/manual-report?${query}`);
  },
};
export default timeSheetRepository;
