import { useState, useRef, useEffect } from 'react';
import classes from './style/MultiSelectDatePicker.module.scss';
import DatePicker, { DatePickerRef, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import type { Value } from 'react-multi-date-picker';
import { useTranslation } from '../../providers/locale-provider';

interface MyComponent {
  title?: string;
  defaultValues?: string[];
  selectedValues: (selectedItem: string) => void;
}
const MultiSelectDatePicker = (props: MyComponent) => {
  const { t9n } = useTranslation();
  const datePickerRef = useRef<DatePickerRef>();
  const [values, setValues] = useState<Value | DateObject | DateObject[]>([]);

  const dateConvertor = () => {
    let selectedDates: DateObject[] = [];
    props.defaultValues?.map((value) => {
      let tempArray = value.split('-');
      selectedDates.push(
        new DateObject({ year: Number(tempArray[0]), month: Number(tempArray[1]), day: Number(tempArray[2]) })
      );
    });
    setValues(selectedDates);
  };
  useEffect(() => {
    dateConvertor();
  }, [props.defaultValues]);
  useEffect(() => {
    if (values) {
      props.selectedValues && props.selectedValues(values.toString());
    }
  }, [values]);

  let currentDate = new DateObject();

  const openDatePickerWithMonth = () => {
    datePickerRef.current?.openCalendar();
  };

  return (
    <div className={classes.datePickerContainer}>
      <span className={classes.label}>{props.title}</span>
      <div className={classes.datePickerBox}>
        <div className={classes.selectedDates}>
          <DatePicker
            ref={datePickerRef}
            multiple
            plugins={[<DatePanel style={{ minWidth: '150px' }} />]}
            value={values}
            onChange={setValues}
            style={{
              width: '100%',
              boxSizing: 'border-box',
              border: 'none',
              height: '46px',
            }}
            containerStyle={{
              width: '100%',
            }}
            minDate={'1999/01/01'}
            maxDate={'2090/12/30'}
            currentDate={currentDate}
          />
        </div>
        <div className={classes.datePickerButton} onClick={openDatePickerWithMonth}>
          <span className={classes.datePickerButtonText}>{t9n.select_on_calendar}</span>
        </div>
      </div>
    </div>
  );
};
export default MultiSelectDatePicker;
