import trash from '../../../assets/image/Icons/trash.svg';
import companyClasses from './style/CompanyBaseStyles.module.scss';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';

import Loading from '../../../components/base/Loading';
import DeleteModal from '../../../components/base/DeleteModal';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';

const companiesRepository = () => RepoFactory.get(RepoType.Companies);

interface props {
  companyId: number;
  handleDeleteCompany?: (status: boolean) => void;
}

const DeleteCompany = (props: props) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteCompanyModal, setDeleteCompanyModal] = useState(false);

  const openDeleteCompanyModal = (status: boolean) => {
    setDeleteCompanyModal(status);
  };

  const exitModal = () => setDeleteCompanyModal(false);

  const deleteCompany = () => {
    setLoading(true);
    companiesRepository()
      .deleteCompany(props.companyId)
      .then((res: AxiosResponse<any>) => {
        exitModal();
        props.handleDeleteCompany && props.handleDeleteCompany(true);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Loading loading={loading} />
      <img
        className={companyClasses.companyIcon}
        onClick={() => openDeleteCompanyModal(true)}
        src={trash}
        alt="Delete icon"
      />

      {deleteCompanyModal && (
        <DeleteModal
          handleDelete={deleteCompany}
          exitModal={exitModal}
          title={t9n.delete_company_text}
          text={t9n.delete_company_desc_text}
        />
      )}
    </>
  );
};
export default DeleteCompany;
