import ReactPaginate from 'react-paginate';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from '../../providers/locale-provider';
interface IProps {
  totalPage: number;
  handlePageChange: (selectedPage: { selected: number }) => void;
  currentPage?: number;
}

const Pagination = (props: IProps) => {
  const { t9n } = useTranslation();
  const pageCount = props.totalPage;

  const handlePageChange = (selectedPage: { selected: number }) => {
    props.handlePageChange(selectedPage);
  };

  return (
    <>
      <ReactPaginate
        previousLabel={
          <span className="d-flex align-items-center">
            <ArrowBackIcon className="me-2" />
            {t9n.table_pagination_prev}
          </span>
        }
        nextLabel={
          <span className="d-flex align-items-center">
            {t9n.table_pagination_next}
            <ArrowForwardIcon className="mx-2" />
          </span>
        }
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={3}
        pageRangeDisplayed={3}
        forcePage={props.currentPage}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName="selected"
        breakClassName="pagination-break"
        previousClassName="pagination-previous"
        nextClassName="pagination-next"
      />
    </>
  );
};
export default Pagination;
