import classes from './style/CompaniesList.module.scss';

import { useEffect, useState } from 'react';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../store/error-slice';
import { useParams, useNavigate } from 'react-router-dom';
import { UserRole } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import DeleteManager from './Manager/DeleteManager';
import Table from '../../../components/base/Table';
import { useTranslation } from '../../../providers/locale-provider';
import { useDispatch, useSelector } from 'react-redux';
import { computePageNumber } from '../../../core/helpers/pagination';
import { RootState } from '../../../store';
import { RepoType } from '../../../types/sharedTypes';
import Fullname from '../../../components/base/Fullname';
import minus from '../../../assets/image/Icons/minus.svg';
import { setShortText } from '../../../core/helpers/utils';

const managersRepository = () => RepoFactory.get(RepoType.Managers);

interface props {
  ManagerInvited: boolean;
  handleInviteManager?: (status: boolean) => void;
  companyId?: number;
}
interface IManager {
  id: number;
  name: string;
  last_name: string;
  username: string;
  email: string;
}

const ManagersList = (props: props) => {
  const { pageNumber } = useParams();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [managersList, setManagersList] = useState<IManager[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [managerDeleted, setManagerDeleted] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const handleDeleteManager = (status: boolean) => setManagerDeleted(status);
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const getManagers = (pageNumber: number) => {
    setLoading(true);
    const computedPageNumber = computePageNumber(
      managerDeleted,
      props.ManagerInvited || false,
      managersList.length,
      pageNumber,
      totalRecords,
      totalPage,
      itemsPerPage
    );
    let params = {
      page: computedPageNumber,
      pageSize: itemsPerPage,
      companyId: props?.companyId,
      role: UserRole.MANAGER,
    };

    managersRepository()
      .allManagers(params)
      .then((result: AxiosResponse<any>) => {
        setManagersList(result.data?.result?.data);
        props.handleInviteManager && props.handleInviteManager(false);
        setTotalPage(result?.data?.result?.metadata.totalPages);
        setTotalRecords(result?.data?.result?.metadata.totalRecords);
        if (props.ManagerInvited) {
          setCurrentPage(computedPageNumber);
        }
        if (managerDeleted) {
          setCurrentPage(computedPageNumber);
          setManagerDeleted(false);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (managerDeleted || props.ManagerInvited) getManagers(currentPage);
  }, [managerDeleted, props.ManagerInvited]);
  useEffect(() => {
    getManagers(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/companies/managers/${props?.companyId}/${currentPage}`);
    }
  }, [currentPage]);

  const sendDataForTable = () => {
    let dataArray: any = [];
    managersList.forEach((manager: IManager, index) => {
      dataArray.push({
        items: [
          <span className={classes.info}>
            <Fullname firstname={manager.name} lastname={manager.last_name} />
            <div className={classes.managerEmail} title={manager.email}>
              {setShortText({ text: manager.email, length: 15 }) ?? <img src={minus} alt="minus" />}
            </div>
          </span>,
          <div className={classes.actions}>
            <DeleteManager
              managerId={manager.id}
              companyId={props.companyId}
              handleDeleteManager={handleDeleteManager}
            />
          </div>,
        ],
      });
    });
    return dataArray;
  };
  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
    getManagers(SelectedPage.selected + 1);
  };
  return (
    <>
      <Loading loading={loading} />
      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        label={t9n.managers_text}
        message={t9n.no_managers}
        headers={[<span>{t9n.name_text + ' / ' + t9n.email_text}</span>, '']}
        data={sendDataForTable()}
      />
    </>
  );
};

export default ManagersList;
