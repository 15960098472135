import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../store/error-slice';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import DefultUserStatus from '../../../view/layouts/DefaultUserStatus';
import { RepoFactory } from '../../../baseRepository/Factory';
import LeaveTimeline from '../components/LeaveTimeline';
import Loading from '../../../components/base/Loading';
import SelectInput from '../../../components/base/SelectInput';
import 'vis-timeline/styles/vis-timeline-graph2d.min.css';
import classes from './style/Leave.module.scss';
import { useTranslation } from '../../../providers/locale-provider';
import { Option, Team, UserRole, RepoType } from '../../../types/sharedTypes';
import Search from '../../../components/base/Search';

export const TeamRepository = () => RepoFactory.get(RepoType.Teams);

const Leave = () => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  let userRole: string = userInfo.user.role.title;
  const companyId = useSelector((state: RootState) => userInfo.user.defaultCompanyId);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<Team[]>([]);
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const [teamOptions, setTeamOptions] = useState<Option[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [order, setOrder] = useState<Option>();

  const orderOptions: Option[] = [
    { value: '1', title: t9n.lastName_sort_descending },
    { value: '2', title: t9n.lastName_sort_ascending },
  ];

  const handleDebouncedSearchTerm = (debouncedSearchTerm: string) => setDebouncedSearchTerm(debouncedSearchTerm);

  const getAllTeams = () => {
    setLoading(true);
    let param = {
      page: 1,
      pageSize: 100,
      companyId: companyId,
    };
    TeamRepository()
      .getAllTeams(param)
      .then((res: AxiosResponse<any>) => {
        setAllTeams(res.data.data);
        setSelectedTeam(res.data.data);
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const getEmployeeTeams = () => {
    setLoading(true);
    TeamRepository()
      .getEmployeeTeams()
      .then((res: AxiosResponse<any>) => {
        setAllTeams(res.data.result.data);
        setSelectedTeam(res.data.result.data);
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleSelectTeam = (selectedItem: Option) => {
    if (selectedItem.value === 'all') setSelectedTeam(allTeams);
    else {
      const matchedTeam = allTeams.find((team) => team.id.toString() === selectedItem.value);
      matchedTeam && setSelectedTeam([matchedTeam]);
    }
  };

  const handleSort = (selectedItem: Option) => setOrder(selectedItem);

  useEffect(() => {
    if (userRole === UserRole.MANAGER) getAllTeams();
    else if (userRole === UserRole.REGULAR) getEmployeeTeams();
  }, [companyId]);

  useEffect(() => {
    const firstOptions = [{ value: 'all', title: t9n.All_teams_text }];
    const otherOptions = allTeams.map((item) => ({ value: item.id.toString(), title: item.title }));
    const newOptions = firstOptions.concat(otherOptions);
    setTeamOptions(newOptions);
  }, [allTeams]);

  return (
    <DefaultLayout>
      <Loading loading={loading} />
      <DefultUserStatus />
      {teamOptions && (
        <>
          <div className={classes.filter}>
            <div className={classes.search}>
              <Search handleDebouncedSearchTerm={handleDebouncedSearchTerm} />
            </div>
            <div className={classes.selectTeam}>
              <SelectInput
                label={t9n.team_text}
                placeHolder={t9n.team_select_placeholder_text}
                options={teamOptions}
                handleselectedOption={handleSelectTeam}
              />
            </div>
            <div className={classes.sortName}>
              <SelectInput
                label=""
                placeHolder={t9n.sort_by_text}
                options={orderOptions}
                handleselectedOption={handleSort}
              />
            </div>
          </div>
          <LeaveTimeline
            order={order ? (order.value === '1' ? 'asc' : 'desc') : ''}
            selectedTeam={selectedTeam}
            userRole={userRole}
            debouncedSearchTerm={debouncedSearchTerm}
          />
        </>
      )}
    </DefaultLayout>
  );
};
export default Leave;
