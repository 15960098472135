import { createSlice } from '@reduxjs/toolkit';
const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    itemsPerPage: 6,
  },
  reducers: {
    setItemsPerPage(state, action) {
      state.itemsPerPage = action.payload;
    },
  },
});
export const dashboardActions = dashboardSlice.actions;
export default dashboardSlice.reducer;
