import classes from './style/HeaderProfile.module.scss';
import { authActions } from '../../modules/Auth/store/auth-slice';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import logout from '../../assets/image/Icons/logout.svg';
import chevron_down_w from '../../assets/image/Icons/chevron_down_w.svg';
import chevron_up from '../../assets/image/Icons/chevron_up.svg';
import setting from '../../assets/image/Icons/setting.svg';
import checkCircle from '../../assets/image/Icons/check_circle.svg';
import LanguageIcon from '../../assets/image/Icons/language.svg';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../../providers/locale-provider';
import { capitalizeFirst, capitalizeFirstOfEach, extractFirstOfEach } from '../../core/helpers/utils';
import { IAppLocale, Language, LanguageList } from '../../locales';
import { getLocale } from '../../core/helpers/locale';
import Avatar from '@mui/material/Avatar';
import minus from '../../assets/image/Icons/minus.svg';
import { setShortText, setvalidname, capitalizeWords } from '../../core/helpers/utils';
import Fullname from '../base/Fullname';

const HeaderProfile = () => {
  const { t9n, updateLocale } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [showLanguageList, setShowLanguageList] = useState<boolean>(false);
  const [currentLanguage, setCurrentLanguage] = useState<Language>(getLocale());

  const fullname = userInfo.user.name + ' ' + userInfo.user.last_name;
  const photoUrl = 'https://iclock.online/api/' + userInfo.user.photo;

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref]);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      toggleHeaderMenu(false)();
      setShowLanguageList(false);
    }
  };

  const changeLanguage = (language: Language) => () => {
    setCurrentLanguage(language);
    updateLocale(language);
  };
  const logOutHandler = async () => {
    try {
      await dispatch(authActions.logOut());
    } catch (error) {
      console.error("Failed to log out:", error);
    } finally {
      localStorage.removeItem('redirectAfterLogin');
    }
  };
  const toggleHeaderMenu = (input: boolean) => () => setShowDropDown(input);
  const toggleLanguageList = (input: boolean) => () => setShowLanguageList(input);
  const navigateToProfile = () => navigate('/profile');

  const LANGUAGE_T9N_MAP = {
    [Language.EN]: (t9n: IAppLocale) => t9n.lang_english,
    [Language.FA]: (t9n: IAppLocale) => t9n.lang_farsi,
  };

  return (
    <div className={classes.profileInfoSection} ref={ref}>
      <div className={classes.profileWrapper} onClick={toggleHeaderMenu(!showDropDown)}>
        <div className={classes.profile}>
          <Avatar
            alt={capitalizeFirstOfEach(userInfo.user.name)}
            src={photoUrl || undefined}
            sx={{ width: 50, height: 50 }}
          />
        </div>
        <div className={classes.dropdownIcon}>
          <img src={!showDropDown ? chevron_down_w : chevron_up} alt="chevron_icon" />
        </div>
      </div>
      {showDropDown && (
        <div className={classes.dropdown}>
          <div className={classes.menuItem}>
            <div title={capitalizeWords(fullname) ?? undefined} className={classes.title}>
              {capitalizeWords(
                setvalidname(
                  userInfo.user.name && userInfo.user.last_name,
                  userInfo.user.name,
                  userInfo.user.last_name,
                  fullname
                )
              )}
            </div>
            <div className={classes.employeeEmail}>
              {setShortText({ text: userInfo.user.email, length: 25 }) ?? <img src={minus} alt="minus" />}
            </div>
          </div>
          {/* TODO - Language is disabled for now, but should be enabled later */}
          {/*<div className={classes.menuItem} onClick={toggleLanguageList(!showLanguageList)}>*/}
          {/*  <div className={classes.title}>*/}
          {/*    <img className={classes.profileIcons} src={LanguageIcon} alt="language" />*/}
          {/*    <span className={classes.profileText}>*/}
          {/*      {t9n.change_language_btn_text}*/}
          {/*      {!showLanguageList && <span className={classes.subTitle}> - {capitalizeFirst(currentLanguage)}</span>}*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*{showLanguageList &&*/}
          {/*    LanguageList.map((lang, idx) => (*/}
          {/*        <div key={idx} className={classes.langMenuItem} onClick={changeLanguage(lang)}>*/}
          {/*          <div className={classes.langTitle}>*/}
          {/*            <div>*/}
          {/*              {currentLanguage === lang && <img className={classes.profileIcons} src={checkCircle} alt="check circle" />}*/}
          {/*            </div>*/}
          {/*            <span className={classes.profileText}>{LANGUAGE_T9N_MAP[lang](t9n)}</span>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*    ))*/}
          <div className={classes.menuItem} onClick={navigateToProfile}>
            <div className={classes.title}>
              <img className={classes.menuIcon} src={setting} alt="Setting" />
              <span className={classes.menuText}>{t9n.navbar_link_setting}</span>
            </div>
          </div>
          <div className={classes.menuItem} onClick={logOutHandler}>
            <div className={classes.title}>
              <img className={classes.menuIcon} src={logout} alt="logout icon" />
              <span className={classes.menuText}>{t9n.logout_btn_text}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default HeaderProfile;
