import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import BaseTitle from '../../../components/base/BaseTitle';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import TabBar from '../../../components/base/TabBar';
import RequestList from '../components/RequestsList';
import History from '../components/History';
import classes from './style/request.module.scss';
import { useTranslation } from '../../../providers/locale-provider';
import { useParams, useNavigate } from 'react-router-dom';
import snoozeClock from '../../../assets/image/Icons/snooze.svg';
import SetTimeOff from '../components/SetTimeOff';

export enum TABS {
  REQUESTS = 'pending',
  HISTORY = 'history',
}

const Requests = () => {
  const { pageType } = useParams();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const companyId = userInfo.user.defaultCompanyId;
  const [selectedTab, setSelectedTab] = useState<TABS>(pageType);

  const tabSelectHandler = (tabValue: string) => {
    navigate(`/requests/${tabValue}/1`);
  };

  useEffect(() => {
    setSelectedTab(pageType);
  }, [pageType]);

  const TABS_MAP: Record<TABS, (props: { id: number }) => JSX.Element> = {
    [TABS.REQUESTS]: ({ id }) => <RequestList companyId={id} />,
    [TABS.HISTORY]: ({ id }) => <History companyId={id} />,
  };

  return (
    <DefaultLayout>
      <section className={classes.header}>
        <SetTimeOff />
        <div className={classes.tabBar}>
          <TabBar
            tabs={[
              { name: t9n.pending_requests_text, value: TABS.REQUESTS },
              { name: t9n.history_text, value: TABS.HISTORY },
            ]}
            onClickHandler={tabSelectHandler}
            selectedValue={selectedTab}
          />
        </div>

        {TABS_MAP[selectedTab]({ id: companyId })}
      </section>
    </DefaultLayout>
  );
};
export default Requests;
