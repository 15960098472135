import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosResponse, AxiosError } from 'axios';
import { errorActions } from '../../../store/error-slice';
import { authActions } from '../../Auth/store/auth-slice';

import classes from './style/EditProfile.module.scss';

import SettingLayout from '../../../view/layouts/SettingLayout';
import Loading from '../../../components/base/Loading';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';
import InputPassword from '../../../components/base/InputPassword';
import upload from '../../../assets/image/Icons/upload-cloud.svg';
import { capitalizeFirstOfEach } from '../../../core/helpers/utils';
import Checkbox from '../../../components/base/Checkbox';
import AvatarDetails from '../../../components/base/AvatarDetails';
import { UserRole } from '../../../types/sharedTypes';
import Info from '../../../components/base/Info';
import { isValidEmail } from '../../../core/helpers/FormValidation';

import Avatar from '@mui/material/Avatar';

const profileRepository = () => RepoFactory.get(RepoType.Profile);

const EditProfile = () => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useSelector((state: RootState) => state.auth.userInfo.user.role.title);
  const [loading, setLoading] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [optionalProject, setOptionalProject] = useState<boolean>(false);
  const [optionalProjectFlag, setOptionalProjectFlag] = useState<boolean>(false);
  const [optionalComment, setOptionalComment] = useState<boolean>(false);
  const [optionalCommentFlag, setOptionalCommentFlag] = useState<boolean>(false);
  const [showAvatarDetails, setShowAvatarDetails] = useState<boolean>(false);
  const [allowNameModification, setAllowNameModification] = useState<boolean>(true);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const inputFileRef = useRef<HTMLInputElement>(null);

  const maxFileSize = 4 * 1024 * 1024;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | DataTransfer) => {
    const files = event instanceof DataTransfer ? event.files : event.target.files;
    if (files && files[0]) {
      const file = files[0];
      const fileType = file.type;
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/gif' ||
        fileType === 'image/svg'
      ) {
        // Validate image dimensions
        const img = new Image();
        img.onload = () => {
          if (file.size > maxFileSize) {
            dispatch(errorActions.setHasError(true));
            dispatch(
              errorActions.setError({
                state: 'error',
                message: 'File size must not exceed 4MB.',
              })
            );
            setFile(null);
            setPreviewUrl(null);
          } else {
            setFile(file);
            const reader = new FileReader();
            reader.onload = () => {
              setPreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
          }
        };
        img.onerror = () => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              state: 'error',
              message: 'Invalid image file.',
            })
          );
          setFile(null);
          setPreviewUrl(null);
        };
        // Create a URL for the image for the img element to load
        img.src = URL.createObjectURL(file);
      } else {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            state: 'error',
            message: 'Only SVG, PNG, JPG or GIF files are allowed.',
          })
        );
        setFile(null);
        setPreviewUrl(null);
      }
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    const dt = e.dataTransfer;
    handleFileChange(dt);
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const getProfile = () => {
    setLoading(true);

    profileRepository()
      .getProfile()
      .then((res: AxiosResponse<any>) => {
        const user = res.data.result;
        setFirstName(user.name ?? '');
        setLastName(user.last_name ?? '');
        setEmail(user.email ?? '');
        setLoading(false);
        setOptionalProject(user.optional_punchout_modal);
        setOptionalComment(user.optional_comment_modal)
        setPreviewUrl('https://iclock.online/api/' + user.photo);
        setAllowNameModification(user.companies[0].allowNameModification);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleInputChange = (field: string, value: string) => {
    switch (field) {
      case 'email':
        setEmail(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [field]: false });
  };

  const validateForm = () => {
    const newErrors = {
      email: !isValidEmail(email) || email.length === 0,
      firstName: firstName.length === 0,
      lastName: lastName.length === 0,
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSaveClick = () => {
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    var bodyFormData = new FormData();
    bodyFormData.append('name', firstName ? firstName : '');
    bodyFormData.append('last_name', lastName ? lastName : '');
    bodyFormData.append('password', password);
    bodyFormData.append('email', email ? email : '');
    optionalProjectFlag && bodyFormData.append('optional_punchout_modal', optionalProject.toString());
    optionalCommentFlag && bodyFormData.append('optional_comment_modal', optionalComment.toString());
    file && bodyFormData.append('file', file);

    const updatedProfile = bodyFormData;
    setLoading(true);

    profileRepository()
      .editProfile(updatedProfile)
      .then((res: AxiosResponse<any>) => {
        profileRepository()
          .getProfile()
          .then((res: AxiosResponse<any>) => {
            const user = res.data.result;
            dispatch(authActions.updateProfilePhoto(user.photo));
          });
        setLoading(false);
        dispatch(authActions.updateUserName(firstName));
        dispatch(authActions.updateEmail(email));
        dispatch(authActions.updateLastName(lastName));
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const handleCheckboxChange = () => {
    setOptionalProjectFlag(true);
    setOptionalProject(!optionalProject);
  };

  const handleCommentCheckboxChange = () => {
    setOptionalCommentFlag(true);
    setOptionalComment(!optionalComment);
  };

  const onAvatarClick = () => setShowAvatarDetails(true);
  const closeAvatarModal = () => setShowAvatarDetails(false);
  
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <SettingLayout>
      <Loading loading={loading} />
      {!allowNameModification && (
        <Info text={'You are not allowed to change First Name and Last Name due to company settings.'} />
      )}
      {showAvatarDetails && <AvatarDetails src={previewUrl || undefined} exitModal={closeAvatarModal} />}
      <section className={classes.settingSection}>
        <div className={classes.setting}>
          <div className={classes.info}>
            <span className={classes.infoHeader}>{t9n.user_settings_text}</span>
            <div className={classes.avatarSection}>
              <Avatar
                alt={capitalizeFirstOfEach(firstName)}
                src={previewUrl || undefined}
                sx={{ width: 64, height: 64, cursor: 'pointer' }}
                onClick={onAvatarClick}
              />
              <div
                className={`${classes.uploadSection} ${isDragOver ? classes.dragOver : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                <img className={classes.uploadIcon} onClick={onBtnClick} src={upload} alt="upload icon" />
                <div className={classes.uploadDes}>
                  <span onClick={onBtnClick} className={classes.uploadText}>
                    Click to upload
                  </span>
                  or drag and drop
                </div>
                <div className={classes.uploadDes}>SVG, PNG, JPG or GIF (max. 4MB)</div>
              </div>
            </div>
            <div className={classes.editInfo}>
              <div className={classes.infoFields}>
                <div className={classes.fieldTitle}>
                  {t9n.first_name_text}
                  <span className={classes.star}>*</span>
                </div>
                <input
                  className={classes.settingInput}
                  type="text"
                  placeholder={t9n.first_name_text}
                  value={firstName}
                  onChange={(e) => handleInputChange('firstName', e.target.value)}
                  autoComplete="new-password"
                  readOnly={!allowNameModification}
                />
                {errors.firstName && <div className={classes.errorText}>{t9n.employee_Fname_invalid_error_text}</div>}
              </div>
              <div className={classes.infoFields}>
                <div className={classes.fieldTitle}>
                  {t9n.last_name_text}
                  <span className={classes.star}>*</span>
                </div>
                <input
                  className={classes.settingInput}
                  type="text"
                  placeholder={t9n.last_name_text}
                  value={lastName}
                  onChange={(e) => handleInputChange('lastName', e.target.value)}
                  autoComplete="new-password"
                  readOnly={!allowNameModification}
                />
                {errors.lastName && <div className={classes.errorText}>{t9n.employee_Lname_invalid_error_text}</div>}
              </div>
              <div className={classes.infoFields}>
                <div className={classes.fieldTitle}>
                  {t9n.email_text}
                  <span className={classes.star}>*</span>
                </div>
                <input
                  className={classes.settingInput}
                  type="text"
                  placeholder="email@gmail.com"
                  value={email}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                  autoComplete="new-password"
                />
                {errors.email && <div className={classes.errorText}>{t9n.invalid_email_error}</div>}
              </div>
              <div className={`${classes.infoFields} ${classes.passwordInput} position-relative`}>
                <InputPassword
                  name="Password"
                  placeholder={''}
                  title={t9n.password_text}
                  handleChange={(e) => setPassword(e.target.value)}
                  autoComplete="off"
                />
              </div>
            </div>
            {!(userRole === UserRole.SYSADMIN) && (
              <div className={classes.optionalProject}>
                <Checkbox onChange={handleCheckboxChange} checked={optionalProject} />
                <div className={classes.optionalProjectTxt}>
                  Always show me the hours information for a single optional project when I punch out
                </div>
              </div>
            )}
            {userRole === UserRole.MANAGER && (
              <div className={classes.optionalProject}>
                <Checkbox onChange={handleCommentCheckboxChange} checked={optionalComment} />
                <div className={classes.optionalProjectTxt}>
                  Always show me the comment input for time off requests.{' '}
                </div>
              </div>
            )}
          </div>

          <div className={classes.action}>
            <button className={classes.actionbtn} onClick={handleSaveClick}>
              {t9n.save_btn_text}
            </button>
          </div>
        </div>
      </section>
      <input
        className={classes.fileInput}
        type="file"
        accept=".jpg, .jpeg, .png, .gif, .svg"
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </SettingLayout>
  );
};
export default EditProfile;
