import { ReactNode, useState, useEffect } from 'react';
import classes from './style/SettingLayout.module.scss';
import Header, { ILink } from '../../components/core/Header';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserRole } from '../../types/sharedTypes';
import Sidebar from '../../components/core/Sidebar';
import EditProfileAlert from '../../components/base/EditProfileAlert';

interface LayoutProps {
  children: ReactNode;
}
const SettingLayout = ({ children }: LayoutProps) => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const [links, setLinks] = useState<ILink[]>([]);
  const isDataComplete = userInfo.user.email && userInfo.user.name && userInfo.user.last_name;

  useEffect(() => {
    const role: string = userInfo?.user?.role.title;
    let links: ILink[] = [];

    if (role === UserRole.MANAGER) {
      links.push(
        { key: 'timesheet', link: '/time-sheet' },
        { key: 'requests', link: '/requests/pending/1', otherLink: ['/requests/history/1'] },
        { key: 'reports', link: '/reports/monthly_report/1' },
        { key: 'leave', link: '/colleagues-status' }
      );
    }
    if (role === UserRole.REGULAR) {
      links.push(
        { key: 'timesheet', link: '/time-sheet' },
        { key: 'requests', link: '/time-off/pending/1' },
        { key: 'leave', link: '/colleagues-status' }
      );
    }
    if (role === UserRole.SYSADMIN) {
      links.push({ key: 'companies', link: '/companies/1' });
    }
    const uniqLinks = uniq(links, (x: any) => x.key);
    setLinks(uniqLinks);
  }, []);
  const uniq = (data: any, key: any): ILink[] => {
    const links: any[] = Array.from(new Map(data.map((x: any) => [key(x), x])).values());
    return links;
  };
  return (
    <div className={classes.DefaultLayout}>
      <Header links={links} />
      {!isDataComplete && <EditProfileAlert />}
      <div className={classes.settingLayout}>
        <Sidebar />
        <div className={classes.container}>
          <div className={`${classes.content}`}> {children}</div>
        </div>
      </div>
    </div>
  );
};
export default SettingLayout;
