import classes from './style/MonthHeader.module.scss';
interface IHeader {
  date: Date;
}

const MonthHeader = ({ date }: IHeader) => {
  const header = (date: Date) => {
    const date_ = new Date(date);
    const month = date_.toLocaleString('default', { weekday: 'long' });
    return (
      <div className={`${classes.header} d-flex align-items-center text-left px-4`}>
        <span>{month}</span>
      </div>
    );
  };

  return <>{header(date)}</>;
};
export default MonthHeader;
