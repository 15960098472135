import { OptionalUndefined } from '../../../../types/sharedTypes';

export interface Request {
  id: number;
  description: string;
  request_datetime: string;
  start_datetime: string;
  end_datetime: string;
  type: string;
  reason: string;
  approved: RequestStatus;
  request_status: string;
  request_temp_status: RequestStatus;
  status_timestamp: number;
  optional_comment_modal: boolean;
  totalAnnual: number;
  usedAnnual: number;
  dailyWorkingHour: number;
  comment: string;
  edited: boolean;
  parent: {
    type: string;
    reason: string;
    description: string;
    end_datetime: string;
    start_datetime: string;
    request_datetime: string;
  };
  user: {
    id: number;
    username: string;
    name: string;
    last_name: string;
    hourly_rate?: null;
    role: { id: number; title: string; is_shared: boolean };
    email?: string;
  };
  reviewer: {
    id: number;
    username: string;
    email: string;
    name: string;
    last_name: string;
    photo: string | null;
    defaultCompanyId: number;
    optional_punchout_modal: boolean;
    optional_comment_modal: boolean;
    employment_time: number;
    status: boolean;
  };
}

export enum RequestStatus {
  Pending = 'pending',
  Canceled = 'canceled',
  Approved = 'approved',
  UnApproved = 'unApproved',
}

export enum RequestMessage {
  Approved = 'The Request has been approved!',
  Denied = 'The Request has been denied.',
}

export enum LeaveType {
  Daily = 'daily',
  Hourly = 'hourly',
}

export interface FilterData {
  requestDate?: Date;
  leaveDateRange?: { from: Date; to: Date } | undefined;
  leaveReason?: string;
  statusFilter?: string;
}
