import classes from './style/Info.module.scss';
import InfoIcon from '../../assets/image/Icons/info-circle.svg';

interface IInfo {
  text: string;
}

const Info: React.FC<IInfo> = ({ text }) => {
  return (
    <div className={classes.infoContainer}>
      <img src={InfoIcon} alt="info" />
      <div className={classes.infoTxt}>{text}</div>
    </div>
  );
};

export default Info;
