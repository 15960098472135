import { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { Request, RequestStatus, RequestMessage } from './types/type';
import { RepoFactory } from '../../../baseRepository/Factory';
import classes from './style/RequestActions.module.scss';
import BaseButton from '../../../components/base/BaseButton';
import { errorActions } from '../../../store/error-slice';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';
import ProgressBarButton from '../../../components/base/ProgressBarButton';
import Badge from '../../../components/base/Badge';
import RequestModal from './RequestModal';

const RequestManagementRepository = () => RepoFactory.get(RepoType.RequestManagement);

interface RequestActionsProps {
  item: Request;
  handleChangeStatus: () => void;
  handleToastMessage: (Message: string) => void;
  handleShowtoast: (showToast: boolean) => void;
  handleLoading: (loading: boolean) => void;
  loading: boolean;
}

const RequestActions = (props: RequestActionsProps) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [isProgressFinished, setIsProgressFinished] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(props.item.request_temp_status !== null);
  const [showRequestModal, setShowRequestModal] = useState<boolean>();
  const [requestStatus, setRequestStatus] = useState<string>('');
  const [optionalComment, setOptionalComment] = useState<boolean>();

  const handleProgressStatus = () => setIsProgressFinished(true);
  const exitModal = () => setShowRequestModal(false);

  const handleRequestStatus = (status: string) => {
    setRequestStatus(status);
    optionalComment ? setShowRequestModal(true) : sendRequestStatus(status);
  };

  const sendRequestStatus = (status: string, comment?: string, optional_comment_modal?: string) => {
    setShowProgressBar(true);
    props.handleLoading(true);
    RequestManagementRepository()
      .updateStatus({ status, comment, optional_comment_modal}, props.item.id)
      .then((res: AxiosResponse<any>) => {
        props.handleToastMessage(status === RequestStatus.Approved ? RequestMessage.Approved : RequestMessage.Denied);
        props.handleShowtoast(true);
        props.handleChangeStatus();
      })
      .catch((error: unknown) => {
        props.handleLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const undoStatus = () => {
    props.handleLoading(true);
    RequestManagementRepository()
      .undoStatus(props.item.id)
      .then((res: AxiosResponse<Request>) => {
        setShowProgressBar(false);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        props.handleLoading(false);
      });
  };

  useEffect(() => {
    const shouldShowProgressBar = props.item.request_temp_status !== null;
    setOptionalComment(props.item.optional_comment_modal);

    setShowProgressBar(shouldShowProgressBar);
  }, [props.item]);

  return (
    <>
      {showRequestModal && optionalComment && (
        <RequestModal exitModal={exitModal} requestStatus={requestStatus} sendRequestStatus={sendRequestStatus} />
      )}
      {!props.loading &&
        (showProgressBar ? (
          <div className={classes.progressBar}>
            {props.item.request_status === RequestStatus.Approved ||
            props.item.request_temp_status === RequestStatus.Approved ? (
              <Badge title={t9n.leave_request_tab_approved} type="success"></Badge>
            ) : (
              <Badge title={t9n.Leave_request_denied} type="error"></Badge>
            )}
            <ProgressBarButton
              handleProgressStatus={handleProgressStatus}
              disabled={
                isProgressFinished ||
                props.item.request_status === RequestStatus.Approved ||
                props.item.request_status === RequestStatus.UnApproved
              }
              onClick={undoStatus}
              {...(props.item.status_timestamp && { startTime: props.item.status_timestamp })}
            />
          </div>
        ) : props.item.request_status === RequestStatus.Approved ? (
          <Badge title={t9n.leave_request_tab_approved} type="success"></Badge>
        ) : props.item.request_status === RequestStatus.UnApproved ? (
          <Badge title={t9n.Leave_request_denied} type="error"></Badge>
        ) : (
          <div className={classes.actions}>
            <BaseButton
              size="xs"
              title={t9n.leave_request_approve}
              color="primary-btn"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={() => handleRequestStatus(RequestStatus.Approved)}></BaseButton>
            <BaseButton
              size="xs"
              title={t9n.leave_request_deny}
              color="secondaryRed"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={() => handleRequestStatus(RequestStatus.UnApproved)}></BaseButton>
          </div>
        ))}
    </>
  );
};
export default RequestActions;
