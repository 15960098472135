import trash from '../../../assets/image/Icons/trash.svg';
import teamClasses from './style/TeamBaseStyles.module.scss';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import DeleteModal from '../../../components/base/DeleteModal';
import { useTranslation } from '../../../providers/locale-provider';

const TeamRepository = () => RepoFactory.get(RepoType.Teams);

interface props {
  teamId: number;
  handleDeleteTeam?: (status: boolean) => void;
}

const DeleteTeam = (props: props) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);

  const openDeleteTeamModal = (status: boolean) => {
    setDeleteTeamModal(status);
  };

  const exitModal = () => setDeleteTeamModal(false);

  const deleteTeam = () => {
    setLoading(true);
    TeamRepository()
      .deleteTeam(props.teamId)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        exitModal();
        props.handleDeleteTeam && props.handleDeleteTeam(true);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  return (
    <>
      <Loading loading={loading} />

      <img className={teamClasses.icon} onClick={() => openDeleteTeamModal(true)} src={trash} alt="Delete icon" />

      {deleteTeamModal && (
        <DeleteModal
          handleDelete={deleteTeam}
          exitModal={exitModal}
          title={t9n.delete_team_text}
          text={t9n.delete_team_desc_text}
        />
      )}
    </>
  );
};
export default DeleteTeam;
