import classes from './style/DetailsRequest.module.scss';
import { IRequest } from '../repository/RequestRepository';
import { useState } from 'react';
import Modal from '../../../components/base/Modal';
import { convertDate } from '../../../core/helpers/FormatDate';
import { LeaveType } from '../types/type';
import { useTranslation } from '../../../providers/locale-provider';
import { REQUESTS_LEAVE_REASON_T9N_MAP, REQUESTS_LEAVE_TYPE_T9N_MAP } from '../pages/Requests';
import more from '../../../assets/image/Icons/more-square.svg';
interface props {
  request: IRequest;
  handleDetailsRequest?: (status: boolean) => void;
}
const DetailsRequest = (props: props) => {
  const { t9n } = useTranslation();
  const [detailsRequestModal, setDetailsRequestModal] = useState(false);

  const openDetailsRequestModal = () => setDetailsRequestModal(true);
  const exitModal = () => setDetailsRequestModal(false);

  return (
    <>
      <img src={more} onClick={() => openDetailsRequestModal()} alt="info icon" />
      {detailsRequestModal && (
        <Modal
          header={t9n.details_text}
          exitAction={exitModal}
          body={
            <div className={`w-100`}>
              <div className={`d-flex gap-3`} key={1}>
                <p className={`${classes.label}`}>{t9n.date_text} :</p>
                <span className={`${classes.value}`}>
                  {convertDate(
                    'MMM D,YYYY',
                    props.request.request_datetime ? +props.request.request_datetime * 1000 : new Date().getTime()
                  )}
                </span>
              </div>
              <div className={`d-flex gap-3`} key={2}>
                <p className={`${classes.label}`}>{t9n.time_off_reason_text} :</p>
                <span className={`${classes.value}`}>{REQUESTS_LEAVE_REASON_T9N_MAP[props.request.reason](t9n)}</span>
              </div>
              <div className={`d-flex gap-3`} key={3}>
                <p className={`${classes.label}`}>{t9n.timeOff_type_text} :</p>
                <span className={`${classes.value}`}>{REQUESTS_LEAVE_TYPE_T9N_MAP[props.request.type](t9n)}</span>
              </div>
              <div className={`d-flex gap-3`} key={4}>
                <p className={`${classes.label}`}>{t9n.timeOff_duration_text} :</p>
                {props.request.type === LeaveType.Daily ? (
                  <span className={`${classes.value}`}>
                    {convertDate('MMM D,YYYY', +props.request.start_datetime * 1000)} -
                    {convertDate('MMM D,YYYY', +props.request.end_datetime * 1000)}
                  </span>
                ) : (
                  <span className={`${classes.value}`}>
                    {convertDate('HH:mm', +props.request.start_datetime * 1000)} -
                    {convertDate('HH:mm', +props.request.end_datetime * 1000)}
                  </span>
                )}
              </div>
              {props.request.description ? (
                <div className={`d-flex gap-3`} key={5}>
                  <p className={`${classes.label}`}>{t9n.leave_description_text} :</p>
                  <span className={`${classes.value}`}>{props.request.description}</span>
                </div>
              ) : undefined}
            </div>
          }></Modal>
      )}
    </>
  );
};
export default DetailsRequest;
