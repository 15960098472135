import '../../../assets/style/scss/_variables.scss';

import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import { useTranslation } from '../../../providers/locale-provider';
import BaseButton from '../../../components/base/BaseButton';
import Toast from '../../../components/base/Toast';

const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);

interface IArchiveEmployee {
  employeeId: number;
  handleArchiveEmployee: (status: boolean) => void;
  isArchive: boolean;
}

const ArchiveEmployee = ({ employeeId, handleArchiveEmployee, isArchive }: IArchiveEmployee) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);

  const handleToastClose = () => setShowToast(false);

  const archiveProject = () => {
    setLoading(true);
    const body = { status: !isArchive };
    EmployeesRepository()
      .updateEmployee(body, employeeId)
      .then((response: AxiosResponse) => {
        setLoading(false);
        handleArchiveEmployee(true);
        setShowToast(true);
      })
      .catch((error: AxiosError) => {
        setLoading(false);
        dispatch(errorActions.setHasError(true));
        dispatch(errorActions.setError({ errorMessage: error.message }));
      });
  };

  return (
    <>
      <Loading loading={loading} />
      {showToast && (
        <Toast
          message={isArchive ? t9n.archive_employee_success_msg : t9n.unArchive_employee_success_msg}
          onClose={handleToastClose}
        />
      )}
      <BaseButton
        title={isArchive ? t9n.archive_text : t9n.unarchive_text}
        color="white-gray"
        onClickHandler={archiveProject}
      />
    </>
  );
};
export default ArchiveEmployee;
