import DefaultLayout from '../../../view/layouts/DefaultLayout';
import AddCompany from '../components/AddCompany';
import CompaniesList from '../components/CompaniesList';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { RepoFactory } from '../../../baseRepository/Factory';
import classes from './style/Companies.module.scss';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../store/error-slice';
import { AxiosError, AxiosResponse } from 'axios';
import { Manager, UserRole, RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import { useParams } from 'react-router-dom';
import BaseButton from '../../../components/base/BaseButton';
import { useTranslation } from '../../../providers/locale-provider';
import Plus from '../../../assets/image/Icons/Plus.svg';
import { useNavigate } from 'react-router';


const ManagerRepository = () => RepoFactory.get(RepoType.Managers);

const Companies = () => {
  const { pageNumber } = useParams();
  const { t9n } = useTranslation();
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const dispatch = useDispatch();
  const newDate = new Date();
  const [newCompanyAdded, setNewCompanyAdded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [managerList, setManagerList] = useState<Manager[]>([]);

  const ButtonIcon = () => {
    return <img src={Plus} alt="Plus" className={classes.ButtonIcon} />;
  };

  const handleNewCompany = (status: boolean) => {
    setNewCompanyAdded(status);
  };

  const getAllManagers = () => {
    setLoading(true);
    ManagerRepository()
      .getManagers({ pageNumber: 1, pageSize: 200, role: UserRole.MANAGER })
      .then((res: AxiosResponse<any>) => {
        const data = res.data.result.data;
        setManagerList(data);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllManagers();
  }, []);

  return (
    <DefaultLayout>
      <Loading loading={loading} />
      <div className={classes.companiesLayout}>
        <div className={classes.userHeader}>
          <BaseButton
            title={t9n.add_company_btn_text}
            color="primary"
            hasLeftIcon={true}
            icon={ButtonIcon()}
            onClickHandler={() => {navigate(`/companies/new`)}}
          />
        </div>
        <CompaniesList newCompanyAdded={newCompanyAdded} handleNewCompany={handleNewCompany} />
      </div>
    </DefaultLayout>
  );
};
export default Companies;
