import { Route, Routes } from 'react-router-dom';
import { Page404 } from '../pages/Page404';

const Page404Router = () => {
  return (
    <Routes>
      <Route path="/*" exact element={<Page404 />} />
    </Routes>
  );
};
export default Page404Router;
