export const CALENDAR_TYPE: Record<string, { value: string; title: string }> = {
  solar: { value: '1', title: 'solar' },
  hijri: { value: '2', title: 'hijri' },
  gregorian: { value: '3', title: 'gregorian' },
};
export enum WEEK_DAYS {
  Sunday = '0',
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
}
export const WEEK_DAY_TYPE: Record<string, { value: string; title: string }> = {
  '0': { value: '0', title: 'Sunday' },
  '1': { value: '1', title: 'Monday' },
  '2': { value: '2', title: 'Tuesday' },
  '3': { value: '3', title: 'Wednesday' },
  '4': { value: '4', title: 'Thursday' },
  '5': { value: '5', title: 'Friday' },
  '6': { value: '6', title: 'Saturday' },
};
