import Table from '../../../components/base/Table';
import EditPen from '../../../assets/image/Icons/EditPen.svg';
import classes from './style/TeamList.module.scss';
import teamClasses from './style/TeamBaseStyles.module.scss';
import arrow_narrow_down from '../../../assets/image/Icons/arrow_narrow_down.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import Loading from '../../../components/base/Loading';
import DeleteTeam from './DeleteTeam';
import Search from '../../../components/base/Search';
import Toast from '../../../components/base/Toast';
import { convertDate } from '../../../core/helpers/FormatDate';
import { Team, RepoType } from '../../../types/sharedTypes';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import { computePageNumber } from '../../../core/helpers/pagination';
import { useTranslation } from '../../../providers/locale-provider';
import { Employee } from '../../../types/sharedTypes';
import AddTeam from './AddTeam';

const TeamRepository = () => RepoFactory.get(RepoType.Teams);

interface props {
  newTeamAdded?: boolean;
  handleNewTeam?: (status: boolean) => void;
  companyId: number;
  employeesList: Employee[];
}

const TeamsList = (props: props) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageNumber } = useParams();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [teamsList, setTeamsList] = useState<Team[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [teamDeleted, setTeamDeleted] = useState<boolean>(false);
  const [isNameAcsending, setNameIsAcsending] = useState<boolean>(true);
  const [isDateAcsending, setDateIsAcsending] = useState<boolean>(true);
  const [showToast, setShowToast] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const handleDeleteTeam = (status: boolean) => setTeamDeleted(status);
  const handleToastClose = () => setShowToast(false);

  const handleDebouncedSearchTerm = (debouncedSearchTerm: string) => {
    setCurrentPage(1);
    setDebouncedSearchTerm(debouncedSearchTerm);
  };

  const getAllTeams = (pageNumber: number) => {
    setLoading(true);
    const computedPageNumber = computePageNumber(
      teamDeleted,
      props.newTeamAdded || false,
      teamsList.length,
      pageNumber,
      totalRecords,
      totalPage,
      itemsPerPage
    );
    let param = {
      page: computedPageNumber,
      pageSize: itemsPerPage,
      search: debouncedSearchTerm,
      companyId: props.companyId,
    };
    TeamRepository()
      .getAllTeams(param)
      .then((res: AxiosResponse<any>) => {
        setTeamsList(res.data.data);
        setTotalPage(res?.data?.metadata?.totalPages);
        setTotalRecords(res?.data?.metadata?.totalRecords);
        props.handleNewTeam && props.handleNewTeam(false);
        if (props.newTeamAdded) {
          setCurrentPage(computedPageNumber);
        }
        if (teamDeleted) {
          setCurrentPage(computedPageNumber);
          setShowToast(true);
          setTeamDeleted(false);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
  };

  const sortListwithName = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let sort = [];

    if (isNameAcsending) {
      setNameIsAcsending(false);
      sort = teamsList.sort((a: Team, b: Team) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
    } else {
      setNameIsAcsending(true);
      sort = teamsList.sort((a: Team, b: Team) => (a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1));
    }

    setTeamsList([...sort]);
  };

  const sortListwithDate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let sort = [];

    if (isDateAcsending) {
      setDateIsAcsending(false);
      sort = teamsList.sort((a: Team, b: Team) => (a.createdAt < b.createdAt ? -1 : 1));
    } else {
      setDateIsAcsending(true);
      sort = teamsList.sort((a: Team, b: Team) => (a.createdAt > b.createdAt ? -1 : 1));
    }

    setTeamsList([...sort]);
  };
  useEffect(() => {
    getAllTeams(currentPage);
  }, [props.companyId, debouncedSearchTerm, pageNumber]);
  useEffect(() => {
    if (teamDeleted || props.newTeamAdded) {
      getAllTeams(currentPage);
    }
  }, [teamDeleted, props.newTeamAdded]);
  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);
  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/teams/${currentPage}`);
    }
  }, [currentPage]);

  const sendDataForTable = () => {
    let dataArray: any = [];

    if (teamsList.length) {
      teamsList.forEach((item: Team) => {
        dataArray.push({
          items: [
            <span>
              <Link className={classes.projectName} to={`/teams/edit/${item.id}`}>
                {item.title}
              </Link>
            </span>,
            <span className={teamClasses.tableText}>{convertDate('MMM D, YYYY', item.createdAt)}</span>,
            <div className={classes.actions}>
              <DeleteTeam teamId={item.id} handleDeleteTeam={handleDeleteTeam} />
              <div className={classes.editPen}>
                <Link to={`/teams/edit/${item.id}`}>
                  <img src={EditPen} alt="edit icon" />
                </Link>
              </div>
            </div>,
          ],
        });
      });
    }
    return dataArray;
  };

  return (
    <>
      <Loading loading={loading} />
      {showToast && <Toast message={'The Team has been deleted.'} onClose={handleToastClose} />}
      <div className={classes.teamHeader}>
        <Search handleDebouncedSearchTerm={handleDebouncedSearchTerm} />
        <AddTeam handleNewTeam={props.handleNewTeam} companyId={props.companyId} employeesList={props.employeesList} />
      </div>

      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        label={''}
        message={debouncedSearchTerm ? t9n.nothing_found_text : t9n.no_teams}
        headers={[
          <span className={classes.headerText}>
            <img
              className={`${isNameAcsending ? classes.sortIconAsc : classes.sortIcondesc}`}
              src={arrow_narrow_down}
              alt="arrow_narrow_down"
              onClick={(e) => sortListwithName(e)}
            />
            {t9n.team_name_text}
          </span>,
          <span className={classes.headerText}>
            <img
              className={`${isDateAcsending ? classes.sortIconAsc : classes.sortIcondesc}`}
              src={arrow_narrow_down}
              alt="arrow_narrow_down"
              onClick={(e) => sortListwithDate(e)}
            />
            {t9n.created_date_text}
          </span>,
          '',
        ]}
        data={sendDataForTable()}
      />
    </>
  );
};
export default TeamsList;
