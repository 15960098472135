import { useState, useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { convertDate, different, getLastYearDate, getCurrentYearDate } from '../../../core/helpers/FormatDate';
import { convertTime } from '../../TimeSheet/actions/timesheet';
import { setShortText } from '../../../core/helpers/utils';
import { Request, RequestStatus, LeaveType, FilterData } from './types/type';
import { RepoFactory } from '../../../baseRepository/Factory';
import requestClasses from './style/RequestBase.module.scss';
import Table from '../../../components/base/Table';
import Loading from '../../../components/base/Loading';
import Toast from '../../../components/base/Toast';
import { errorActions } from '../../../store/error-slice';
import { RootState } from '../../../store';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';
import RequestActions from './RequestActions';
import { useParams, useNavigate } from 'react-router-dom';
import RequestFilter from './RequestFilter';
import minus from '../../../assets/image/Icons/minus.svg';
import Fullname from '../../../components/base/Fullname';
import info from '../../../assets/image/Icons/info-orange.svg';
import EditedRequestInfo from './EditedRequestInfo';

const RequestManagementRepository = () => RepoFactory.get(RepoType.RequestManagement);

interface RequestFilterProps {
  companyId: number;
}

const RequestList = (props: RequestFilterProps) => {
  const { pageNumber } = useParams();
  const { pageType } = useParams();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [loading, setLoading] = useState<boolean>(false);
  const [requestsList, setRequestsList] = useState<Request[]>([]);
  const [isStatusChanged, setIsStatusChanged] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [filterData, setFilterData] = useState<FilterData>({
    requestDate: undefined,
    leaveDateRange: undefined,
    statusFilter: undefined,
  });

  const handleFilter = (filterData: FilterData) => {
    setCurrentPage(1);
    setFilterData(filterData);
  };

  const handleShowToast = (showToast: boolean) => setShowToast(showToast);
  const handleToastMessage = (message: string) => setToastMessage(message);
  const handleLoading = (loading: boolean) => setLoading(loading);

  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
    getAllRequests(SelectedPage.selected + 1);
  };

  const handleChangeStatus = () => setIsStatusChanged(true);

  const getAllRequests = (pageNumber: number) => {
    const start = getLastYearDate().start;
    const end = getCurrentYearDate().end;
    let param = {
      page: pageNumber,
      pageSize: itemsPerPage,
      status: RequestStatus.Pending,
      company: props.companyId,
      start: filterData.leaveDateRange ? filterData.leaveDateRange.from.getTime() / 1000 : start,
      end: filterData.leaveDateRange ? filterData.leaveDateRange.to.getTime() / 1000 : end,
      createdAt: filterData.requestDate ? filterData.requestDate.getTime() / 1000 + 12600 : undefined,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    setLoading(true);
    RequestManagementRepository()
      .getAllRequest(param)
      .then((res: AxiosResponse<any>) => {
        setRequestsList(res.data.data);
        setTotalPage(res?.data?.metadata?.totalPages);
        isStatusChanged && setIsStatusChanged(false);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllRequests(currentPage);
  }, [isStatusChanged, pageNumber]);
  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/requests/${pageType}/${currentPage}`);
    }
  }, [currentPage]);

  useEffect(() => {
    getAllRequests(currentPage);
  }, [filterData]);

  const sendDataForTable = () => {
    return requestsList.map((request: Request) => {
      return {
        items: [
          <span className={requestClasses.info}>
            <Fullname firstname={request.user.name} lastname={request.user.last_name} />
            <div className={requestClasses.employeeEmail} title={request.user.email}>
              {setShortText({ text: request.user.email, length: 15 }) ?? <img src={minus} alt="minus" />}
            </div>
          </span>,
          <span className={`${requestClasses.tableText} ${requestClasses.timeInfo}`}>
            <div>
              <p className={requestClasses.tableText}>{convertTime(request.request_datetime)}</p>
              {convertDate('MMM D, YYYY', +request.request_datetime * 1000)}
            </div>
            <EditedRequestInfo request={request}/>
          </span>,
          <span className={requestClasses.tableText}>
            {request.type === LeaveType.Hourly && (
              <p className={requestClasses.tableText}>{convertTime(request.start_datetime)}</p>
            )}
            {convertDate('MMM D,YYYY', +request.start_datetime * 1000)}
          </span>,
          <span className={requestClasses.tableText}>
            {request.type === LeaveType.Hourly && (
              <p className={requestClasses.tableText}>{convertTime(request.end_datetime)}</p>
            )}

            {convertDate('MMM D,YYYY', +request.end_datetime * 1000)}
          </span>,
          <span className={requestClasses.tableText}>
            {different(+request.start_datetime * 1000, +request.end_datetime * 1000, request.type)(t9n)}
          </span>,
          <span title={request.reason} className={requestClasses.tableText}>
            {setShortText({ text: request.reason, length: 15 })}
          </span>,
          <span title={request.description} className={requestClasses.tableText}>
            {setShortText({ text: request.description, length: 15, defaultValue: '-' })}
          </span>,
          <span className={requestClasses.tableText}>
            {Math.ceil(request.usedAnnual / request.dailyWorkingHour)} / {request.totalAnnual}
          </span>,

          <RequestActions
            item={request}
            handleChangeStatus={handleChangeStatus}
            handleToastMessage={handleToastMessage}
            handleShowtoast={handleShowToast}
            handleLoading={handleLoading}
            loading={loading}
          />,
        ],
        key: request.id.toString(),
      };
    });
  };

  return (
    <>
      <Loading loading={loading} />
      {showToast && <Toast message={toastMessage} onClose={() => handleShowToast(false)} />}
      <RequestFilter handleFilter={handleFilter} />
      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        textCenter={true}
        leftAlignedColumnIndex={0}
        label={t9n.requests_text}
        message={t9n.no_request_text}
        headers={[
          <span className={requestClasses.tableHeader}>{t9n.Employee_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.request_date_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.start_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.end_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.timeOff_duration_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.time_off_reason_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.description_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.annual_timeOff_text}</span>,
          '',
        ]}
        data={sendDataForTable()}
      />
    </>
  );
};
export default RequestList;
