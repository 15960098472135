import classes from './style/EditProfileAlert.module.scss';
import Info from '../../assets/image/Icons/red-info.svg';
import Modal from './Modal';
import BaseButton from './BaseButton';
import { useTranslation } from '../../providers/locale-provider';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const EditProfileAlert: React.FC = () => {
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  let location = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);

  const navigateToProfile = () => {
    navigate('/profile');
  };

  useEffect(() => {
    if (location.pathname !== '/profile') {
      setShowModal(true);
    }
  }, [location]);

  return showModal ? (
    <Modal
      size="sm"
      disableHeader={true}
      header={<img src={Info} alt="info" />}
      exitAction={navigateToProfile}
      body={
        <div className={classes.modalBody}>
          <img className={classes.infoIcon} src={Info} alt="Info icon" />
          <p className={classes.alertText}>
            We noticed that you haven't completed your profile yet. To continue using this application, please provide
            the required information.
          </p>
        </div>
      }
      action={
        <div className={classes.buttonBox}>
          <div className={classes.deleteButton}>
            <BaseButton
              hasBlock={true}
              size="lg"
              title={t9n.complete_profile_text}
              color="primary"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={navigateToProfile}></BaseButton>
          </div>
        </div>
      }
    />
  ) : null;
};

export default EditProfileAlert;
