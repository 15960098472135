import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import classes from './style/EditCompany.module.scss';
import TextArea from '../../../components/base/TextArea';
import upload from '../../../assets/image/Icons/upload-cloud.svg';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import Avatar from '@mui/material/Avatar';
import { capitalizeFirstOfEach } from '../../../core/helpers/utils';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import { useLocation } from 'react-router-dom';

const companiesRepository = () => RepoFactory.get(RepoType.Companies);

interface MyComponentProps {
  handleEditCompony?: (status: boolean) => void;
}

const EditCompany = (props: MyComponentProps) => {
  const { t9n } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split('/');
  const companyId = paths[paths.length - 1];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const nameOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => setName(e.currentTarget.value);
  const addressOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => setAddress(e.currentTarget.value);
  const descriptionOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => setDescription(e.currentTarget.value);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const maxFileSize = 4 * 1024 * 1024;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | DataTransfer) => {
    const files = event instanceof DataTransfer ? event.files : event.target.files;
    if (files && files[0]) {
      const file = files[0];
      const fileType = file.type;
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/gif' ||
        fileType === 'image/svg'
      ) {
        // Validate image dimensions
        const img = new Image();
        img.onload = () => {
          if (file.size > maxFileSize) {
            dispatch(errorActions.setHasError(true));
            dispatch(
              errorActions.setError({
                state: 'error',
                message: 'File size must not exceed 4MB.',
              })
            );
            setFile(null);
            setPreviewUrl(null);
          } else {
            setFile(file);
            const reader = new FileReader();
            reader.onload = () => {
              setPreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(file);
          }
        };
        img.onerror = () => {
          dispatch(errorActions.setHasError(true));
          dispatch(
            errorActions.setError({
              state: 'error',
              message: 'Invalid image file.',
            })
          );
          setFile(null);
          setPreviewUrl(null);
        };
        // Create a URL for the image for the img element to load
        img.src = URL.createObjectURL(file);
      } else {
        dispatch(errorActions.setHasError(true));
        dispatch(
          errorActions.setError({
            state: 'error',
            message: 'Only SVG, PNG, JPG or GIF files are allowed.',
          })
        );
        setFile(null);
        setPreviewUrl(null);
      }
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    const dt = e.dataTransfer;
    handleFileChange(dt);
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const showErrorMessage = (message: string) => {
    setLoading(false);
    dispatch(errorActions.setHasError(true));
    dispatch(
      errorActions.setError({
        message: message,
        statusCode: 0,
      })
    );
  };

  const getComany = () => {
    companiesRepository()
      .getByIdCompany(companyId)
      .then((result: AxiosResponse<any>) => {
        const company = result.data;
        setName(company.name);
        setDescription(company.description);
        setAddress(company.address);
        setPreviewUrl('https://iclock.online/api/' + company.photo);
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editCompany = (e: React.FormEvent) => {
    var bodyFormData = new FormData();
    bodyFormData.append('name', name ? name : '');
    bodyFormData.append('description', description ? description : '');
    bodyFormData.append('address', address ? address : '');
    file && bodyFormData.append('photo', file);

    const updatedCompony = bodyFormData;

    e.preventDefault();
    if (name.length) {
      setLoading(true);

      companiesRepository()
        .editCompany(updatedCompony, companyId)
        .then((response: AxiosResponse) => {
          setLoading(false);
          props.handleEditCompony && props.handleEditCompony(true);
        })
        .catch((error: AxiosError) => {
          setLoading(false);
          dispatch(errorActions.setError({ errorMessage: error.message }));
        });
    } else {
      showErrorMessage('Enter Your Company Name!');
      setName(name);
    }
  };

  useEffect(() => {
    companyId && getComany();
  }, [companyId]);

  return (
    <DefaultLayout>
      <Loading loading={loading} />
      {companyId && (
        <section className={classes.settingSection}>
          <div className={classes.setting}>
            <div className={classes.info}>
              <span className={classes.infoHeader}>{t9n.edit_company_btn_text}</span>
              <div className={classes.avatarSection}>
                <Avatar
                  alt={capitalizeFirstOfEach(name)}
                  src={previewUrl || undefined}
                  sx={{ width: 64, height: 64, cursor: 'pointer' }}
                />
                <div
                  className={`${classes.uploadSection} ${isDragOver ? classes.dragOver : ''}`}
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}>
                  <img className={classes.uploadIcon} onClick={onBtnClick} src={upload} alt="upload icon" />
                  <div className={classes.uploadDes}>
                    <span onClick={onBtnClick} className={classes.uploadText}>
                      Click to upload
                    </span>
                    or drag and drop
                  </div>
                  <div className={classes.uploadDes}>SVG, PNG, JPG or GIF (max. 4MB)</div>
                </div>
              </div>
              <div className={classes.editInfo}>
                <div className={classes.infoFields}>
                  <span className={classes.fieldTitle}>{t9n.company_name_placeholder_text}</span>
                  <input
                    className={classes.settingInput}
                    type="text"
                    placeholder={t9n.company_name_placeholder_text}
                    value={name}
                    onChange={nameOnChangeHandler}
                    autoComplete="new-password"
                  />
                </div>
                <div className={classes.infoFields}>
                  <span className={classes.fieldTitle}>{t9n.address_text}</span>
                  <input
                    className={classes.settingInput}
                    type="text"
                    placeholder={t9n.address_placeholder_text}
                    value={address}
                    onChange={addressOnChangeHandler}
                    autoComplete="new-password"
                  />
                </div>
                <div className={`${classes.description}`}>
                  <TextArea
                    name="description"
                    placeholder={t9n.leave_description_placeholder_text}
                    title={t9n.leave_description_text}
                    value={description}
                    required={false}
                    rows={5}
                    handleChange={descriptionOnChangeHandler}
                  />
                </div>
              </div>
            </div>

            <div className={classes.action}>
              <button className={classes.actionbtn} onClick={editCompany}>
                {t9n.save_btn_text}
              </button>
            </div>
          </div>
        </section>
      )}
      <input
        className={classes.fileInput}
        type="file"
        accept=".jpg, .jpeg, .png, .gif, .svg"
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </DefaultLayout>
  );
};

export default EditCompany;
