import BaseRepository from '../../../baseRepository/BaseRepository';
const resourceUser = '/users';
const resourceLeave = '/leaves';
export interface IRequest {
  id?: number;
  request_datetime?: string;
  start_datetime: string;
  end_datetime: string;
  type: string;
  reason: string;
  request_status: string;
  description: string;
  comment: string;
  comment_read_receipt: boolean;
  reviewer: {
    id: number;
    username: string;
    email: string;
    name: string;
    last_name: string;
    photo: string | null;
    defaultCompanyId: number;
    optional_punchout_modal: boolean;
    optional_comment_modal: boolean;
    employment_time: number;
    status: boolean;
  };
}
const RequestRepository = {
  createRequest(body: IRequest) {
    return BaseRepository.post(`${resourceLeave}`, body);
  },
  getRequests(params: { status: string; page: number; pageSize: number; startYearDate: number; endYearDate: number }) {
    return BaseRepository.get(`${resourceUser}/leaves`, { params });
  },
  getRequestById(id: number) {
    return BaseRepository.get(`${resourceUser}/leaves/${id}`);
  },
  cancelRequest(id: number) {
    return BaseRepository.patch(`${resourceUser}/leaves/${id}`);
  },
  annualLeaves(params: { startYearDate: number; endYearDate: number }) {
    return BaseRepository.get(`${resourceUser}/annual-leaves`, { params });
  },
  setCommentsStatus(params: { status: string }) {
    const { status } = params;
    return BaseRepository.post(`${resourceUser}${resourceLeave}/send-read-receipt?status=${status}`);
  },
  updateRequest(id: number, body: IRequest) {
    return BaseRepository.patch(`${resourceUser}/leaves/${id}/update`, body);
  },
};
export default RequestRepository;
