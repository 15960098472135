import classes from './style/Page404.module.scss';
import page404 from '../../../assets/image/Images/page404.png';

export const Page404 = () => {
  return (
    <section className={`w-100 h-100 d-flex flex-column align-items-center justify-content-center`}>
      <img src={page404} alt="Page 404" className={classes.image404} />
      <div className={`text-center ${classes.content}`}>
        <h1>404</h1>
        <h2>The page not found</h2>
      </div>
    </section>
  );
};
