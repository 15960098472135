import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/auth/profile';

const profileRepository = {
  getProfile() {
    return BaseRepository.get(resource);
  },

  editProfile(body:FormData) {
    return BaseRepository.patchForm(resource, body);
  },
};
export default profileRepository;
