import classes from './style/toolbar.module.scss';
import moment from 'jalali-moment';
import NextIcon from '../../../../assets/image/Icons/chevron_right.svg';
import BackIcon from '../../../../assets/image/Icons/chevron_left.svg';
const ToolBar = (toolbar: any) => {

  const goToBack = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    toolbar.onNavigate('prev');
  };

  const goToNext = () => {
    toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    toolbar.onNavigate('next');
  };

  const label = () => {
    const date = moment(toolbar.date);
    return (
      <span>
        <b>{date.format('MMMM')}</b>
        <span> {date.format('YYYY')}</span>
      </span>
    );
  };

  return (
    <div className={`${classes.header} d-flex text-center`}>
      <div className="col col-start">
        <div onClick={() => goToBack()}>
          <img className={`${classes.icon} d-flex justify-start`} src={BackIcon} alt="Next Month" />
          {/* <ArrowBackIosNewIcon /> */}
        </div>
      </div>
      <div className="col col-center">
        <span>{label()}</span>
      </div>
      <div className="col col-end" onClick={() => goToNext()}>
        <div className="d-flex flex-row-reverse">
          <img className={classes.icon} src={NextIcon} alt="Prev Month" />
        </div>
      </div>
    </div>
  );
};
export default ToolBar;
