import classes from './style/Toast.module.scss';
import checkCircle from '../../assets/image/Icons/check_circle.svg';
import ErrorIcon from '@mui/icons-material/Error';
import { useState, useEffect } from 'react';

const TOAST_DURATION = 3000;

interface ToastProps {
  message: string;
  onClose?: () => void;
  type?: String;
}

const Toast = (props: ToastProps) => {
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState('toast');

  const classNameComputed = () => {
    let tempName = name;

    //Not implemented yet
    if (props.type === 'succeed') {
      tempName = tempName + ' succeed-toast ';
    } else {
      tempName = tempName + ' error-toast ';
    }

    setName(tempName);
  };

  useEffect(() => {
    classNameComputed();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (props.onClose) props.onClose();
    }, TOAST_DURATION);

    return () => clearTimeout(timer);
  }, [props.onClose]);

  return visible ? (
    <div className={classes.toastContainer}>
      <div className={classes.content}>
        {props.type === 'error' ? (
          <ErrorIcon className={classes.errorIcon} />
        ) : (
          <img src={checkCircle} alt="check circle" />
        )}
        <div className={classes.toast}>{props.message}</div>
      </div>
    </div>
  ) : null;
};

export default Toast;
