import { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import Badge from '../../../components/base/Badge';
import Loading from '../../../components/base/Loading';
import Table from '../../../components/base/Table';
import { RepoFactory } from '../../../baseRepository/Factory';
import { convertDate, different, getCurrentYearDate, getLastYearDate } from '../../../core/helpers/FormatDate';
import { errorActions } from '../../../store/error-slice';
import { convertTime } from '../../TimeSheet/actions/timesheet';
import { Request, LeaveType, RequestStatus, FilterData } from './types/type';
import requestClasses from './style/RequestBase.module.scss';
import classes from './style/History.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTranslation } from '../../../providers/locale-provider';
import { useParams, useNavigate } from 'react-router-dom';
import { RepoType } from '../../../types/sharedTypes';
import RequestFilter from './RequestFilter';
import { setShortText } from '../../../core/helpers/utils';
import minus from '../../../assets/image/Icons/minus.svg';
import Fullname from '../../../components/base/Fullname';
import EditedRequestInfo from './EditedRequestInfo';

const RequestManagementRepository = () => RepoFactory.get(RepoType.RequestManagement);

interface HistoryProps {
  companyId: number;
}

export const requestsStatus: RequestStatus[] = [
  RequestStatus.Approved,
  RequestStatus.UnApproved,
  RequestStatus.Pending,
  RequestStatus.Canceled,
];

const History = (props: HistoryProps) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const { pageNumber } = useParams();
  const { pageType } = useParams();
  const navigate = useNavigate();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [loading, setLoading] = useState<boolean>(false);
  const [isStatusChanged, setIsStatusChanged] = useState<boolean>(false);
  const [requests, setRequestsList] = useState<Request[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [statusFilter, setStatusFilter] = useState([requestsStatus[0], requestsStatus[1], requestsStatus[3]].join(','));
  const [filterData, setFilterData] = useState<FilterData>({
    requestDate: undefined,
    leaveDateRange: undefined,
    statusFilter: undefined,
  });

  const handleFilter = (filterData: FilterData) => {
    setCurrentPage(1);
    setFilterData(filterData);
  };

  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
    getAllRequests(SelectedPage.selected + 1);
  };

  const getAllRequests = (pageNumber: number) => {
    const start = getLastYearDate().start;
    const end = getCurrentYearDate().end;
    let param = {
      page: pageNumber,
      pageSize: itemsPerPage,
      status: filterData.statusFilter ?? statusFilter,
      company: props.companyId,
      start: filterData.leaveDateRange ? filterData.leaveDateRange.from.getTime() / 1000 : start,
      end: filterData.leaveDateRange ? filterData.leaveDateRange.to.getTime() / 1000 : end,
      createdAt: filterData.requestDate ? filterData.requestDate.getTime() / 1000 + 12600 : undefined,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    setLoading(true);
    RequestManagementRepository()
      .getAllRequest(param)
      .then((res: AxiosResponse<any>) => {
        setRequestsList(res.data.data);
        setTotalPage(res.data.metadata.totalPages);
        isStatusChanged && setIsStatusChanged(false);
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  useEffect(() => {
    getAllRequests(currentPage);
  }, [isStatusChanged, pageNumber, filterData]);
  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/requests/${pageType}/${currentPage}`);
    }
  }, [currentPage]);

  const sendDataForTable = () => {
    return requests.map((request: Request) => {
      return {
        items: [
          <span className={requestClasses.info}>
            <Fullname firstname={request.user.name} lastname={request.user.last_name} />
            <div className={requestClasses.employeeEmail} title={request.user.email}>
              {setShortText({ text: request.user.email, length: 15 }) ?? <img src={minus} alt="minus" />}
            </div>
          </span>,
          <span className={`${requestClasses.tableText} ${requestClasses.timeInfo}`}>
            <div>
              <p className={requestClasses.tableText}>{convertTime(request.request_datetime)}</p>
              {convertDate('MMM D, YYYY', +request.request_datetime * 1000)}
            </div>
            {request.request_status === RequestStatus.UnApproved && <EditedRequestInfo request={request} />}
          </span>,
          <span className={requestClasses.tableText}>
            {request.type === LeaveType.Hourly && (
              <p className={requestClasses.tableText}>{convertTime(request.start_datetime)}</p>
            )}
            {convertDate('MMM D,YYYY', +request.start_datetime * 1000)}
          </span>,
          <span className={requestClasses.tableText}>
            {request.type === LeaveType.Hourly && (
              <p className={requestClasses.tableText}>{convertTime(request.end_datetime)}</p>
            )}

            {convertDate('MMM D,YYYY', +request.end_datetime * 1000)}
          </span>,
          <span title={request.reason} className={requestClasses.tableText}>
            {setShortText({ text: request.reason, length: 15 })}
          </span>,
          <span title={request.description} className={requestClasses.tableText}>
            {setShortText({ text: request.description, length: 15, defaultValue: '-' })}
          </span>,
          <div className={classes.actions}>
            {request.request_status === RequestStatus.Approved ? (
              <Badge title={t9n.leave_request_tab_approved} type="success"></Badge>
            ) : request.request_status === RequestStatus.Canceled ? (
              <Badge title={t9n.leave_request_tab_canceled} type="warrning"></Badge>
            ) : (
              <Badge title={t9n.denied_text} type="error"></Badge>
            )}
          </div>,
        ],
        key: request.id.toString(),
      };
    });
  };

  return (
    <>
      <Loading loading={loading} />
      <RequestFilter handleFilter={handleFilter} hasLeaveStatus={true} />
      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        leftAlignedColumnIndex={0}
        textCenter={true}
        label={t9n.history_text}
        message={t9n.no_request_text}
        showMore={true}
        requests={requests}
        headers={[
          <span className={requestClasses.tableHeader}>{t9n.employee_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.request_date_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.start_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.end_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.time_off_reason_text}</span>,
          <span className={requestClasses.tableHeader}>{t9n.description_text}</span>,
          '',
          '',
        ]}
        data={sendDataForTable()}
      />
    </>
  );
};
export default History;
