import classes from './style/AddEmployee.module.scss';
import employeeClasses from './style/EmployeeBaseStyles.module.scss';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { isValidEmail } from '../../../core/helpers/FormValidation';
import Modal from '../../../components/base/Modal';
import BaseButton from '../../../components/base/BaseButton';
import Input from '../../../components/base/Input';
import SelectInput from '../../../components/base/SelectInput';
import Plus from '../../../assets/image/Icons/Plus.svg';
import Loading from '../../../components/base/Loading';
import Toast from '../../../components/base/Toast';
import { Option, RepoType, Optional, UserRole } from '../../../types/sharedTypes';
import { useTranslation } from '../../../providers/locale-provider';
import { isDefined } from '../../../core/helpers/utils';
import InputPassword from '../../../components/base/InputPassword';
import DatePicker from '../../../components/base/DatePicker';
import { DatePickerPosition } from '../../../components/base/DatePicker';

const ButtonIcon = () => {
  return <img src={Plus} alt="Plus" className={classes.ButtonIcon} />;
};

interface MyComponentProps {
  handleNewEmployee?: (status: boolean) => void;
  companyId: number;
}

const AddEmployee = (props: MyComponentProps) => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const [addEmployeeModal, setAddEmployeeModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [addByEmail, setAddByEmail] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [role, setRole] = useState<Optional<UserRole>>(null);
  const [usernameInValidMsg, setUsernameInValidMsg] = useState<string>('');
  const [showToast, setShowToast] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [errors, setErrors] = useState({
    username: false,
    usernameContainSpace: false,
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    role: false,
  });

  const handleSelectedDate = (date: Date) => setSelectedDate(date);

  const handleInputChange = (field: string, value: string) => {
    let tempErrors = { ...errors };
    switch (field) {
      case 'username':
        setUsername(value);
        if (!value.includes(' ')) tempErrors = { ...tempErrors, usernameContainSpace: false };
        break;
      case 'email':
        setEmail(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [field]: false });
    if (username.includes(' ')) {
      setErrors({ ...errors, usernameContainSpace: false });
    }
  };

  const exitModal = () => setAddEmployeeModal(false);
  const handleToastClose = () => setShowToast(false);

  const openAddEmployeeModal = () => {
    setAddEmployeeModal(true);
    resetInfo();
  };

  const handleSelectedOption = (selectedItem: Option) => {
    setRole(selectedItem.value as UserRole);
    setErrors({ ...errors, role: false });
  };

  const validateForm = () => {
    const usernameContainSpace = username.includes(' ');
    const usernameLengthError = username.length === 0;
    const newErrors = {
      username: addByEmail ? false : usernameLengthError,
      usernameContainSpace: addByEmail ? false : usernameContainSpace,
      email: addByEmail ? !isValidEmail(email) : false,
      firstName: firstName.length === 0,
      lastName: lastName.length === 0,
      password: addByEmail ? false : password.length === 0,
      role: !isDefined(role),
    };

    !addByEmail && usernameLengthError && setUsernameInValidMsg(t9n.employee_username_invalid_error_text);
    !addByEmail && usernameContainSpace && setUsernameInValidMsg(t9n.name_contain_apaces_error_text);

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const resetInfo = () => {
    setUsername('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setRole(null);
    resetErrors();
  };

  const resetErrors = () => {
    setErrors({
      username: false,
      usernameContainSpace: false,
      firstName: false,
      lastName: false,
      email: false,
      password: false,
      role: false,
    });
  };

  const changeInvitationStrategy = () => {
    addByEmail ? setEmail('') : setUsername('');
    setAddByEmail(!addByEmail);
    resetErrors();
  };

  const inviteEmployeeByUserName = () => {
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const data = {
      username,
      name: firstName,
      last_name: lastName,
      password,
      role,
      companies: [props.companyId],
      employment_time: selectedDate ? Math.floor(selectedDate.getTime() / 1000) : null,
    };
    RepoFactory.get(RepoType.Employees)
      .createUser(data)
      .then((res: AxiosResponse<any>) => {
        setAddEmployeeModal(false);
        props.handleNewEmployee && props.handleNewEmployee(true);

        resetInfo();
      })
      .catch((error: unknown) => {
        setAddEmployeeModal(false);
        if (error instanceof AxiosError && error.response?.data) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const inviteEmployeeByEmail = () => {
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const data = {
      email,
      name: firstName,
      last_name: lastName,
      role,
      companies: [props.companyId],
      employment_time: selectedDate ? Math.floor(selectedDate.getTime() / 1000) : null,
    };
    RepoFactory.get(RepoType.Employees)
      .createUserByEmail(data)
      .then((res: AxiosResponse<any>) => {
        setAddEmployeeModal(false);
        props.handleNewEmployee && props.handleNewEmployee(true);
        setShowToast(true);
        resetInfo();
      })
      .catch((error: unknown) => {
        setAddEmployeeModal(false);
        if (error instanceof AxiosError && error.response?.data) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Loading loading={loading} />
      {showToast && <Toast message={t9n.invite_success_msg_text} onClose={handleToastClose} />}
      <BaseButton
        title={t9n.invite_employee_btn_text}
        color="primary"
        hasLeftIcon={true}
        icon={<ButtonIcon />}
        onClickHandler={openAddEmployeeModal}
      />

      {addEmployeeModal && (
        <Modal
          header={t9n.invite_employee_btn_text}
          exitAction={exitModal}
          bodyOverFlow={true}
          body={
            <div className={classes.modalBody}>
              <div className={employeeClasses.inputSection}>
                <Input
                  name={addByEmail ? 'email' : 'username'}
                  placeholder={addByEmail ? 'Email' : 'Username'}
                  value={addByEmail ? email : username}
                  title={addByEmail ? t9n.email_text : t9n.username_text}
                  required={true}
                  handleChange={(e) => handleInputChange(addByEmail ? 'email' : 'username', e.currentTarget.value)}
                  isInvalid={addByEmail ? errors.email : errors.username || errors.usernameContainSpace}
                  inValidmsg={
                    !addByEmail
                      ? usernameInValidMsg
                      : email
                      ? t9n.invalid_email_error
                      : t9n.employee_email_invalid_error_text
                  }
                />
                <span className={employeeClasses.typeTitle} onClick={changeInvitationStrategy}>
                  {addByEmail ? t9n.manager_invite_with_username : t9n.manager_invite_with_email}
                </span>
              </div>
              <div className={employeeClasses.details}>
                <Input
                  name="firstName"
                  placeholder={t9n.first_name_text}
                  title={t9n.first_name_text}
                  required={true}
                  handleChange={(e) => handleInputChange('firstName', e.currentTarget.value)}
                  isInvalid={errors.firstName}
                  inValidmsg={t9n.employee_Fname_invalid_error_text}
                />
                <Input
                  name="lastName"
                  placeholder={t9n.last_name_text}
                  title={t9n.last_name_text}
                  required={true}
                  handleChange={(e) => handleInputChange('lastName', e.currentTarget.value)}
                  isInvalid={errors.lastName}
                  inValidmsg={t9n.employee_Lname_invalid_error_text}
                />
                {!addByEmail && (
                  <>
                    <InputPassword
                      required={true}
                      name="Password"
                      placeholder={'********'}
                      title={t9n.password_text}
                      handleChange={(e) => handleInputChange('password', e.currentTarget.value)}
                      isInvalid={errors.password}
                      inValidmsg={t9n.employee_password_invalid_error_text}
                    />
                  </>
                )}

                <SelectInput
                  label={t9n.role_text}
                  placeHolder={t9n.role_select_placeholder}
                  options={[
                    { value: UserRole.MANAGER, title: t9n.manager_text },
                    { value: UserRole.REGULAR, title: t9n.regular_text },
                  ]}
                  handleselectedOption={handleSelectedOption}
                  isInvalid={errors.role}
                  inValidmsg={t9n.role_invalid_error_text}
                />

                <div className={classes.startDate}>
                  <label>{t9n.start_date_text}</label>
                  <DatePicker
                    datePickerPosition={DatePickerPosition.TOP_Right}
                    handleSelectedDate={handleSelectedDate}
                    selectedDate={selectedDate}
                  />
                </div>
              </div>
            </div>
          }
          action={
            <div className={employeeClasses.buttonBox}>
              <BaseButton
                size={'lg'}
                hasBlock={true}
                title={t9n.add_text}
                color="primary"
                onClickHandler={addByEmail ? inviteEmployeeByEmail : inviteEmployeeByUserName}></BaseButton>
            </div>
          }></Modal>
      )}
    </>
  );
};
export default AddEmployee;
