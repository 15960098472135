import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/teams';

const TeamRepository = {
    createTeam(body: { title: string; companyId: number; userIds: Array<number> }) {
      return BaseRepository.post(`${resource}`, body);
    },
    editTeam(body: { title: string; userIds?: Array<number>; }, id: string) {
      return BaseRepository.patch(`${resource}/${id}`, body);
    },
    getTeam(id: string) {
      return BaseRepository.get(`${resource}/${id}`);
    },
    getEmployeeTeams(){
      return BaseRepository.get(`/users${resource}`);
    },
    deleteTeam(id: string) {
      return BaseRepository.delete(`${resource}/${id}`);
    },
    getAllTeams(params: { page: number; pageSize: number; search: string; companyId: number }) {
      return BaseRepository.get(`${resource}`, {
        params,
      });
    },
    getTeamEmployees(params: { page: number; pageSize: number; search: string; }, id: string) {
      return BaseRepository.get(`${resource}/${id}/employees`, {params});
    },
    addEmployees(body: { userId: number }, id: string) {
      return BaseRepository.post(`${resource}/${id}/employees`, body);
    },
    deleteEmployee(body: { userId: number }, id: string) {
      return BaseRepository.delete(`${resource}/${id}/employees`, { data: body });
    },
    createEmployee(body: { userId: number }) {
      return BaseRepository.post(`/employees`, body);
    },
    getUsersOfProject( body: { page: number; pageSize: number } , projectId: number) {
      return BaseRepository.get(`${resource}/${projectId}/employees`);
    }
  };
  export default TeamRepository;
