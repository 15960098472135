import classes from './style/Managers.module.scss';
import { useState } from 'react';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import ManagersList from '../components/ManagersList';
import InviteManager from '../components/InviteManager';
import { useParams } from 'react-router-dom';

const Managers = () => {
  const { companyId } = useParams();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const newDate = new Date();
  const [ManagerInvite, setManagerInvite] = useState<boolean>(false);
  const handleInviteManager = (status: boolean) => {
    setManagerInvite(status);
  };

  return (
    <DefaultLayout>
      <div className={classes.managersLayout}>
        <div className={classes.userHeader}>
          <InviteManager handleInviteManager={handleInviteManager} companyId={companyId} />
        </div>
        <ManagersList ManagerInvited={ManagerInvite} handleInviteManager={handleInviteManager} companyId={companyId} />
      </div>
    </DefaultLayout>
  );
};
export default Managers;
