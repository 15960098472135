import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
  isAuthenticated: boolean;
  userInfo: {
    exp: number;
    iat: number;
    nbf: number;
    exp_duration: number;
    user: {
      id: number;
      username: string;
      name: string;
      last_name: string;
      email: string;
      hourly_rate: number | null;
      role: { id: number; title: string; is_shared: boolean };
      defaultCompanyId: number;
      photo: string;
    };
  };
}

const initialState: AuthState = {
  isAuthenticated: false,
  userInfo: {
    exp: 0,
    iat: 0,
    nbf: 0,
    exp_duration: 0,
    user: {
      id: 0,
      username: '',
      name: '',
      last_name: '',
      email: '',
      hourly_rate: null,
      role: { id: 0, title: '', is_shared: false },
      defaultCompanyId: 0,
      photo: '',
    },
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated(state: AuthState, action) {
      state.isAuthenticated = action.payload;
    },
    setUserInfo(state: AuthState, action) {
      var JWTtoken = action.payload.split('.')[1];
      var base64 = JWTtoken.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      state.userInfo = JSON.parse(jsonPayload);
    },
    logOut(state: AuthState) {
      state.userInfo = {
        exp: 0,
        iat: 0,
        nbf: 0,
        exp_duration: 0,
        user: {
          id: 0,
          username: '',
          name: '',
          last_name: '',
          email: '',
          hourly_rate: null,
          role: { id: 0, title: '', is_shared: false },
          defaultCompanyId: 0,
          photo: '',
        },
      };
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.setItem('isLoggedIn', 'false');
    },
    updateUserName(state: AuthState, action) {
      state.userInfo.user.name = action.payload;
    },
    updateLastName(state: AuthState, action) {
      state.userInfo.user.last_name = action.payload;
    },
    updateEmail(state: AuthState, action) {
      state.userInfo.user.email = action.payload;
    },
    updateProfilePhoto(state: AuthState, action) {
      state.userInfo.user.photo = action.payload;
    },
  },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
