import { Route, Routes } from 'react-router-dom';
import Companies from '../pages/Companies';
import Managers from '../pages/Managers';
import EditCompany from '../components/EditCompany';
import AddCompany from '../components/AddCompany';
const CompaniesRouter = () => {
  return (
    <Routes>
      <Route path="/:pageNumber" exact element={<Companies />} />
      <Route path="/new" exact element={<AddCompany />} />
      <Route path="edit/:pageNumber" exact element={<EditCompany />} />
      <Route path="/managers/:companyId/:pageNumber" exact element={<Managers />} />
    </Routes>
  );
};

export default CompaniesRouter;
