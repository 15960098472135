import Router from './router';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import ErrorHandling from './core/helpers/ErrorHandling';
import ReloadAlert from './components/base/ReloadAlert';
import EditProfileAlert from './components/base/EditProfileAlert';
function App() {
  const hasError = useSelector((state: RootState) => state.error.hasError);
  

  return (
    <>
      {hasError && <ErrorHandling />}
      <ReloadAlert />
      <Router />
    </>
  );
}
export default App;
