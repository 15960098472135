import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/projects';
const resourceUser = '/users/projects';

const projectRepository = {
  createProject(body: { name: string; description: string; companyId: number; userIds: Array<number> }) {
    return BaseRepository.post(`${resource}`, body);
  },
  editProject(body: { name: string; description: string; companyId: number; userIds: Array<number> }, id: string) {
    return BaseRepository.patch(`${resource}/${id}`, body);
  },
  updateEmployeeDescription(body: { userId: number; description: number }, id: string) {
    return BaseRepository.patch(`${resource}/${id}/employees`, body);
  },
  getByIdProject(id: string) {
    return BaseRepository.get(`${resource}/${id}`);
  },
  deleteProject(id: string) {
    return BaseRepository.delete(`${resource}/${id}`);
  },
  getAllProjects(params: { page: number; pageSize: number; search: string; companyId: number; status: boolean }) {
    return BaseRepository.get(`${resource}`, {
      params,
    });
  },
  getAllProjectEmployees(params: { page: number; pageSize: number; search: string; }, id: string) {
    return BaseRepository.get(`${resource}/${id}/employees`, { params });
  },
  assignEmployees(body: { userId: number; description: number }, id: string) {
    return BaseRepository.post(`${resource}/${id}/employees`, body);
  },
  deleteEmployee(body: { userId: number }, id: string) {
    return BaseRepository.delete(`${resource}/${id}/employees`, { data: body });
  },
  userProjects() {
    return BaseRepository.get(`${resourceUser}`);
  },
  createEmployee(body: { userId: number }) {
    return BaseRepository.post(`/employees`, body);
  },
  getUsersOfProject(body: { page: number; pageSize: number }, projectId: number) {
    return BaseRepository.get(`${resource}/${projectId}/employees`);
  },
};
export default projectRepository;
