import classes from './style/Shift.module.scss';
import SettingLayout from '../../../view/layouts/SettingLayout';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import BaseTitle from '../../../components/base/BaseTitle';
import SettingForm from '../components/SettingForm';
const Shift = () => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  return (
    <SettingLayout>
      <section className={classes.header}>
        <BaseTitle username={userInfo.user.name} />
      </section>
      <SettingForm />
    </SettingLayout>
  );
};
export default Shift;
