import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from '../../../../providers/locale-provider';

import { RootState } from '../../../../store';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { errorActions } from '../../../../store/error-slice';
import { Team, Employee, RepoType } from '../../../../types/sharedTypes';

import classes from './style/EditTeam.module.scss';
import layoutclasses from '../../pages/style/Team.module.scss';
import teamClasses from '../style/TeamBaseStyles.module.scss';

import DefaultLayout from '../../../../view/layouts/DefaultLayout';
import BaseTitle from '../../../../components/base/BaseTitle';
import AddEmployee from './AddEmployee';
import Table from '../../../../components/base/Table';
import EditableField from './EditableField';
import DeleteEmployee from './DeleteEmployee';
import Loading from '../../../../components/base/Loading';
import Toast from '../../../../components/base/Toast';
import Search from '../../../../components/base/Search';
import minus from '../../../../assets/image/Icons/minus.svg';
import SettingLayout from '../../../../view/layouts/SettingLayout';
import Fullname from '../../../../components/base/Fullname';
import { setShortText } from '../../../../core/helpers/utils';

interface TeamEmployee {
  userId: number;
  teamId: number;
  user: Employee;
}

const TeamRepository = () => RepoFactory.get(RepoType.Teams);

const EditProject = () => {
  const { t9n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  const [loading, setLoading] = useState<boolean>(false);
  const [team, setTeam] = useState<Team>();
  const [teamEmplyeesList, setTeamEmplyeesList] = useState<TeamEmployee[]>();
  const [employeesList, setEmployeesList] = useState<Employee[]>();
  const [employeeDeleted, setEmployeeDeleted] = useState<boolean>(false);
  const [addNewEmployee, setAddNewEmployee] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const handleNewEmployeeAdd = (status: boolean) => setAddNewEmployee(status);
  const handleDeleteEmployee = (status: boolean) => setEmployeeDeleted(status);
  const handleToastClose = () => setShowToast(false);
  const handleDebouncedSearchTerm = (debouncedSearchTerm: string) => setDebouncedSearchTerm(debouncedSearchTerm);

  const getTeam = () => {
    setLoading(true);

    TeamRepository()
      .getTeam(id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setTeam(res.data.showTeam);
        addNewEmployee && setAddNewEmployee(false);
        if (employeeDeleted) {
          setEmployeeDeleted(false);
          setShowToast(true);
        }
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const getTeamEmployees = () => {
    setLoading(true);

    TeamRepository()
      .getTeamEmployees({ page: 1, pageSize: 100, search: debouncedSearchTerm }, id)
      .then((res: AxiosResponse<any>) => {
        setTeamEmplyeesList(res.data);
        setLoading(false);
        employeeDeleted && setEmployeeDeleted(false);
        addNewEmployee && setAddNewEmployee(false);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  const getEmployeesList = () => {
    const employeesList: Employee[] = [];
    if (teamEmplyeesList?.length) {
      teamEmplyeesList.forEach((teamEmplyee: TeamEmployee) => {
        employeesList.push(teamEmplyee.user);
      });
    }
    setEmployeesList(employeesList);
  };

  useEffect(() => {
    getTeam();
  }, [addNewEmployee, employeeDeleted]);

  useEffect(() => {
    getTeamEmployees();
  }, [addNewEmployee, employeeDeleted, debouncedSearchTerm]);

  useEffect(() => {
    getEmployeesList();
  }, [teamEmplyeesList]);

  const sendDataForTable = () => {
    let dataArray: any = [];

    if (teamEmplyeesList?.length) {
      teamEmplyeesList.forEach((teamEmplyee: TeamEmployee, index) => {
        dataArray.push({
          items: [
            <span className={teamClasses.info}>
              <Fullname firstname={teamEmplyee.user.name} lastname={teamEmplyee.user.last_name} />
              <div className={teamClasses.employeeEmail} title={teamEmplyee.user.email}>
                {setShortText({ text: teamEmplyee.user.email, length: 15 }) ?? <img src={minus} alt="minus" />}
              </div>
            </span>,
            <div className={classes.actions}>
              <DeleteEmployee teamId={id} employeeId={teamEmplyee.userId} handleDeleteEmployee={handleDeleteEmployee} />
            </div>,
          ],
        });
      });
    }
    return dataArray;
  };

  return (
    <SettingLayout>
      <Loading loading={loading} />
      {showToast && <Toast message={'The Employee has been deleted.'} onClose={handleToastClose} />}
      <div className={`${layoutclasses.projectLayout}`}>
        <div className={classes.editTeamHeader}>
          <Search handleDebouncedSearchTerm={handleDebouncedSearchTerm} />
          {employeesList && (
            <AddEmployee handleNewEmployeeAdd={handleNewEmployeeAdd} team={team} employeesList={employeesList} />
          )}
        </div>
        {team && (
          <>
            <div className={classes.teamInfo}>
              <EditableField projectId={id} value={team.title} isName={true} />
            </div>

            <Table
              buttonHeader={false}
              label={''}
              message={debouncedSearchTerm ? t9n.nothing_found_text : t9n.no_employees}
              headers={[<span className={classes.headerText}>{t9n.employee_text}</span>, '']}
              data={sendDataForTable()}
            />
          </>
        )}
      </div>
    </SettingLayout>
  );
};
export default EditProject;
