import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../store/error-slice';
import {
  IMetaData,
  INewAttendance,
  IStatus,
  IUpdateAttendance,
  IWorkTime,
} from '../../modules/TimeSheet/repository/timeSheetRepository';
import BaseButton from '../../components/base/BaseButton';
import { RepoFactory } from '../../baseRepository/Factory';
import ClockIcon from '../../assets/image/Icons/clockIcon.svg';
import Loading from '../../components/base/Loading';
import classes from './style/DefaultUserStatus.module.scss';
import { UserStatus } from '../../modules/Auth/types/type';
import { useTranslation } from '../../providers/locale-provider';
import { UserRole } from '../../types/sharedTypes';
import { setStatus, setPunchId } from '../../store/attendanceSlice';
import { RepoType } from '../../types/sharedTypes';
import PunchOut from '../../modules/TimeSheet/components/PunchOut/punchOut';
import { getCurrentYearDate } from '../../core/helpers/FormatDate';
import { getFirstDayOfYear, getLastDayOfYear } from '../../modules/TimeSheet/actions/timesheet';

export const TeamRepository = () => RepoFactory.get(RepoType.Teams);
const timeSheetRepository = () => RepoFactory.get(RepoType.Attendance);

const DefaultUserStatus = () => {
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const attendance = useSelector((state: RootState) => state.attendanceSlice);
  const [attendanceId, setAttendanceId] = useState<number>();
  const [startTime, setStartTime] = useState<number>(0);
  const [punchOut, setPunchOut] = useState<boolean>(false);
  const [showPunchBtn, setShowPunchBtn] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const punchIn = () => {
    setLoading(true);
    timeSheetRepository()
      .punchIn()
      .then((result: AxiosResponse<INewAttendance>) => {
        dispatch(setStatus(UserStatus.In));
        dispatch(setPunchId(result.data.newAttendance.id));
        getHistoryAttendaces();
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const userStatus = () => {
    timeSheetRepository()
      .userStatus()
      .then(
        ({
          data: {
            result: { status, attendanceId },
          },
        }: AxiosResponse<IStatus>) => {
          dispatch(setStatus(status));
          if (status === UserStatus.In && attendanceId) {
            dispatch(setPunchId(attendanceId));
          }
          setShowPunchBtn(true);
        }
      );
  };

  const handlePunchOut = (status: boolean) => setPunchOut(status);
  const getHistoryAttendaces = () => {
    let params = {
      start_date: getFirstDayOfYear(),
      end_date: getLastDayOfYear(),
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };

    timeSheetRepository()
      .monthlyTime(params)
      .then((result: AxiosResponse<IWorkTime>) => {
        const data = result.data.result.allAtten;
        if (data.length > 0) {
          const lastAttendance = data[data.length - 1];
          setStartTime(Number(lastAttendance.checkIn));
        }
        userStatus();
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };
  useEffect(() => {
    getHistoryAttendaces();
  }, []);
  return (
    <>
      <Loading loading={loading} />
      <div className={classes.statusHeader}>
        {userInfo?.user?.role.title === UserRole.REGULAR && (
          <>
            {showPunchBtn && (
              <div className={`col-3 ${classes.statusBtn}`}>
                {attendance.status === UserStatus.In ? (
                  <PunchOut startTime={startTime} punchId={attendance.punchId} handlePunchOut={handlePunchOut} />
                ) : (
                  <BaseButton
                    title={t9n.punch_in_btn_text}
                    hasLeftIcon={true}
                    icon={<img src={ClockIcon} alt="clockIcon" />}
                    onClickHandler={punchIn}></BaseButton>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default DefaultUserStatus;
