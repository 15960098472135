import BaseRepository from '../../../baseRepository/BaseRepository';
import { Optional, UserRole } from '../../../types/sharedTypes';

const EmployeeRepository = {
  getMyCompanies() {
    return BaseRepository.get('/users/companies');
  },
  getEmployee(id: string) {
    return BaseRepository.get(`/employees/${id}`);
  },
  editEmployee(body: { name: string; last_name: string; role: Optional<UserRole>; email: string }, id: string) {
    return BaseRepository.patch(`/users/${id}`, body);
  },
  getCompanyUsers(
    params: { page: number; pageSize: number; search: string; sortBy?: string; order?: string },
    companyId: number
  ) {
    return BaseRepository.get(`/companies/${companyId}/users`, { params });
  },
  createUser(data: {
    username: string;
    name: string;
    last_name: string;
    password: string;
    confirmPassword: string;
    role: Optional<UserRole>;
    companies?: number[];
    employment_time: string;
  }) {
    return BaseRepository.post('/employees', data);
  },
  createUserByEmail(data: {
    email: string;
    name: string;
    last_name: string;
    companies?: number[];
    employment_time: string;
  }) {
    return BaseRepository.post('/employees/invite', data);
  },
  updateEmployee(
    body: {
      status: boolean;
      name: string;
      last_name: string;
      email: string;
      password: string;
      role: string;
      employment_time: string;
    },
    id: string
  ) {
    return BaseRepository.patch(`/employees/${id}`, body);
  },
};
export default EmployeeRepository;
