import classes from './style/Setting.module.scss';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import BaseTitle from '../../../components/base/BaseTitle';
import SettingForm from '../components/SettingForm';
const Setting = () => {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  return (
    <DefaultLayout>
      <section className={classes.header}>
        <BaseTitle username={userInfo.user.name} />
      </section>
      <SettingForm />
    </DefaultLayout>
  );
};
export default Setting;
