import { useState, useEffect } from 'react';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../../providers/locale-provider';
import { useParams, useNavigate } from 'react-router-dom';

import DefaultLayout from '../../../view/layouts/DefaultLayout';
import classes from './style/Employees.module.scss';

import BaseTitle from '../../../components/base/BaseTitle';
import AddEmployee from '../components/AddEmployee';
import EmployeesList from '../components/EmployeesList';
import TabBar from '../../../components/base/TabBar';
import SettingLayout from '../../../view/layouts/SettingLayout';

export enum EMPLOYEESTABS {
  EMPLOYEESLIST = 'employeeList',
  ARCHIVED = 'archived',
}

const Employees = () => {
  const { pageType } = useParams();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const [newEmployeeAdded, setNewEmployeeAdded] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<EMPLOYEESTABS>(pageType);
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const companyId = userInfo.user.defaultCompanyId;

  const handleNewEmployee = (status: boolean) => setNewEmployeeAdded(status);
  const tabSelectHandler = (tabValue: string) => navigate(`/employees/${tabValue}/1`);

  useEffect(() => {
    setSelectedTab(pageType);
  }, [pageType]);
  const TABS_MAP: Record<EMPLOYEESTABS, () => JSX.Element> = {
    [EMPLOYEESTABS.EMPLOYEESLIST]: () => (
      <EmployeesList companyId={companyId} newEmployeeAdded={newEmployeeAdded} handleNewEmployee={handleNewEmployee} />
    ),
    [EMPLOYEESTABS.ARCHIVED]: () => (
      <EmployeesList companyId={companyId} newEmployeeAdded={newEmployeeAdded} handleNewEmployee={handleNewEmployee} />
    ),
  };

  return (
    <SettingLayout>
      {' '}
      <div className={classes.employeeLayout}>
        <BaseTitle
          username={userInfo.user.name}
          hasButton={true}
          button={<AddEmployee companyId={companyId} handleNewEmployee={handleNewEmployee} />}
        />
        <div className={classes.tabBar}>
          <TabBar
            tabs={[
              { name: t9n.active_text, value: EMPLOYEESTABS.EMPLOYEESLIST },
              { name: t9n.archived_text, value: EMPLOYEESTABS.ARCHIVED },
            ]}
            onClickHandler={tabSelectHandler}
            selectedValue={selectedTab}
          />
        </div>
        {TABS_MAP[selectedTab]()}
      </div>
    </SettingLayout>
  );
};
export default Employees;
