import { useState, useEffect } from 'react';
import ProjectList from '../components/ProjectList';
import classes from './style/Project.module.scss';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import BaseTitle from '../../../components/base/BaseTitle';
import AddProject from '../components/AddProject';
import { AxiosError, AxiosResponse } from 'axios';
import { RepoFactory } from '../../../baseRepository/Factory';
import { errorActions } from '../../../store/error-slice';
import { useDispatch } from 'react-redux';
import { Employee, RepoType } from '../../../types/sharedTypes';
import Loading from '../../../components/base/Loading';
import { useParams, useNavigate } from 'react-router-dom';
import TabBar from '../../../components/base/TabBar';
import { useTranslation } from '../../../providers/locale-provider';
import SettingLayout from '../../../view/layouts/SettingLayout';

export enum PROJECTSTABS {
  PROJECTSLIST = 'projectsList',
  ARCHIVED = 'archived',
}

const EmployeesRepository = () => RepoFactory.get(RepoType.Employees);

const Project = () => {
  const { pageType } = useParams();
  const navigate = useNavigate();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);
  const companyId = userInfo.user.defaultCompanyId;
  const [loading, setLoading] = useState<boolean>(false);
  const [employeesList, setEmployeesList] = useState<Employee[]>([]);
  const [newProjectAdded, setNewProjectAdded] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<PROJECTSTABS>(pageType);

  const handleNewProject = (status: boolean) => setNewProjectAdded(status);
  const tabSelectHandler = (tabValue: string) => navigate(`/projects/${tabValue}/1`);

  const getAllEmployees = () => {
    EmployeesRepository()
      .getCompanyUsers({ page: 1, pageSize: 100 }, companyId)
      .then((res: AxiosResponse<any>) => {
        setLoading(true);
        const data = res.data.data;
        setEmployeesList(data);
        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);

        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  useEffect(() => {
    setSelectedTab(pageType);
  }, [pageType]);
  const TABS_MAP: Record<PROJECTSTABS, () => JSX.Element> = {
    [PROJECTSTABS.PROJECTSLIST]: () => (
      <ProjectList newProjectAdded={newProjectAdded} handleNewProject={handleNewProject} companyId={companyId} />
    ),
    [PROJECTSTABS.ARCHIVED]: () => (
      <ProjectList newProjectAdded={newProjectAdded} handleNewProject={handleNewProject} companyId={companyId} />
    ),
  };

  useEffect(() => {
    getAllEmployees();
  }, [companyId]);

  return (
    <SettingLayout>
      <Loading loading={loading} />
      <div className={classes.projectLayout}>
        <BaseTitle
          username={userInfo.user.name}
          hasButton={true}
          button={
            <AddProject handleNewProject={handleNewProject} companyId={companyId} employeesList={employeesList} />
          }
        />
        <div className={classes.tabBar}>
          <TabBar
            tabs={[
              { name: t9n.tabs_link_projects, value: PROJECTSTABS.PROJECTSLIST },
              { name: t9n.archived_text, value: PROJECTSTABS.ARCHIVED },
            ]}
            onClickHandler={tabSelectHandler}
            selectedValue={selectedTab}
          />
        </div>
        {TABS_MAP[selectedTab]()}
      </div>
    </SettingLayout>
  );
};
export default Project;
