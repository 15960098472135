import { Route, Routes } from 'react-router-dom';
import Shift from '../pages/Shift';

const ShiftRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Shift />} />
    </Routes>
  );
};
export default ShiftRouter;