import passwordClasses from './style/ResetPassword.module.scss';
import Input from '../../../../components/base/Input';
import BaseButton from '../../../../components/base/BaseButton';
import { useState } from 'react';
import { RepoFactory } from '../../../../baseRepository/Factory';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../../../store/error-slice';
import { AxiosError, AxiosResponse } from 'axios';
import InputPassword from '../../../../components/base/InputPassword';
import Loading from '../../../../components/base/Loading';
import { useTranslation } from '../../../../providers/locale-provider';
import { RepoType } from '../../../../types/sharedTypes';
import { isValidEmail, validatePassword } from '../../../../core/helpers/FormValidation';
import AuthLayout from '../../../../view/layouts/AuthLayout';

const authRepository = () => RepoFactory.get(RepoType.Auth);

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t9n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isInValidPass, setIsInValidPass] = useState<boolean>(false);
  const [emailError, setEmailError] = useState(false);
  const [matchPasswordError, setMatchPasswordError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [inValidmsg, setInValidmsg] = useState<string>();
  const emialOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setEmailError(false);
    setEmail(e.currentTarget.value);
  };
  const otpOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setOtpError(false);
    setOtp(e.currentTarget.value);
  };
  const passwordOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setIsInValidPass(false);
    setPassword(e.currentTarget.value);
  };
  const confirmPasswordOnChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    setMatchPasswordError(false);
    setConfirmPassword(e.currentTarget.value);
  };
  const goToNextPage = async (link: string) => navigate(link);

  const validateForm = () => {
    setIsInValidPass(false);
    setEmailError(false);
    setMatchPasswordError(false);
    setOtpError(false);
    if (!email.length || !isValidEmail(email)) {
      !email.length ? setInValidmsg(t9n.enter_emial_text) : setInValidmsg(t9n.invalid_email_error);
      setEmailError(true);
      return false;
    }
    if (!otp.length) {
      setOtpError(true);
      return false;
    }
    if (!validatePassword(password)) {
      setIsInValidPass(true);
      return false;
    }
    if (password !== confirmPassword) {
      setMatchPasswordError(true);
      return false;
    }
    return true;
  };

  const changePass = async () => {
    if (validateForm()) {
      setLoading(true);
      let body = {
        email,
        otp,
        password,
      };
      authRepository()
        .changePass(body)
        .then(async (res: AxiosResponse<any>) => {
          goToNextPage('/');
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError) {
            if (error?.response?.status === 404) {
              setInValidmsg(t9n.email_not_found);
              setEmailError(true);
            } else {
              dispatch(errorActions.setHasError(true));
              dispatch(errorActions.setError(error.response?.data));
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <AuthLayout headerText={t9n.reset_password_text} subHeader={t9n.strong_password_text}>
      <div className={passwordClasses.formSection}>
        <Loading loading={loading} />
        <Input
          name="email"
          placeholder={t9n.email_placeholder_text}
          title={t9n.email_text}
          handleChange={emialOnChangeHandler}
          isInvalid={emailError}
          inValidmsg={inValidmsg}
        />
        <Input
          name="OTP"
          placeholder="OTP"
          title="OTP"
          handleChange={otpOnChangeHandler}
          isInvalid={otpError}
          inValidmsg={t9n.otp_error_text}
        />
        <InputPassword
          name="newPassword"
          placeholder={t9n.enter_new_password_text}
          title={t9n.new_password_text}
          handleChange={passwordOnChangeHandler}
          isInvalid={isInValidPass}
          inValidmsg={t9n.check_strong_password_text}
        />
        <InputPassword
          name="confirmPassword"
          placeholder={t9n.confirm_new_password_placeholder}
          title={t9n.confirm_new_password_text}
          handleChange={confirmPasswordOnChangeHandler}
          isInvalid={matchPasswordError}
          inValidmsg={t9n.password_not_match_text}
        />
        <BaseButton title={t9n.reset_password_text} hasBlock={true} onClickHandler={changePass} />
      </div>
    </AuthLayout>
  );
};
export default ResetPassword;
