/**
 *   <TabBar
          tabs={[
            { name: 'Request', value: '1' },
            { name: 'List', value: '2' },
          ]}
          onClickHandler={tabSelectHandler}
          selectedValue={selectedTab}
        />
 */
import classes from './style/TabBar.module.scss';
import { TimeOffType } from '../../types/sharedTypes';
interface TabComponent {
  tabs: { name: string; value: string }[];
  onClickHandler: (tabValue: string) => void;
  selectedValue: string;
  commetCounter?: { approved: number; Denied: number };
}
const TabBar = (props: TabComponent) => {
  const changeTab = (value: string) => {
    props.onClickHandler(value);
  };

  return (
    <div className={classes.horizontalTab}>
      {props.tabs.map((tab, index) => {
        return (
          <div
            key={index}
            className={props.selectedValue === tab.value ? classes.selectedTab : classes.tab}
            onClick={(e) => changeTab(tab.value)}>
            <span className={props.selectedValue === tab.value ? classes.selectedTabText : classes.tabText}>
              {tab.name}
            </span>
            {props.commetCounter &&
              ((tab.name === 'Approved' && props.commetCounter.approved !== 0) || tab.name === 'Denied' && props.commetCounter.Denied !== 0) && (
                <div
                  className={`${classes.commentCounter} ${
                    props.selectedValue === tab.value && classes.selectedCommentCounter
                  }`}>
                  {tab.value === TimeOffType.Approved ? props.commetCounter.approved : props.commetCounter.Denied}
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};
export default TabBar;
