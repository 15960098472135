import Modal from './Modal';
import classes from './style/AvatarDetails.module.scss';
import { SelectedAvatarDetails } from '../../modules/Leave/components/LeaveTimeline';

interface IAvatarDetails {
  exitModal: () => void;
  src: string | undefined;
  selectedAvatarDetails?: SelectedAvatarDetails;
}
const AvatarDetails = (props: IAvatarDetails) => {
  return (
    <Modal
      header={'Details'}
      body={
        <div className={classes.modalBody}>
          <div className={classes.avatarSection}>
            <img className={classes.avatar} src={props.src} alt="avatar" />
          </div>
          {props.selectedAvatarDetails && <div className={classes.detailsSection}>
            <div className={classes.name}>{props.selectedAvatarDetails?.name}</div>
            <div className={classes.email}>{props.selectedAvatarDetails?.email}</div>
            <div className={`${props.selectedAvatarDetails?.active ? classes.active : classes.inactive}`}>{props.selectedAvatarDetails?.active ? 'Active' : 'Inactive'}</div>
          </div>}
        </div>
      }
      exitAction={props.exitModal}
    />
  );
};

export default AvatarDetails;
