import classes from './style/EmptyTable.module.scss';

const EmptyTable = (props: { message: string }) => {
  return (
    <tr>
      <td>
        <div className={`${classes.empty} d-flex align-items-center justify-content-center`}>
          <p>{props.message}</p>
        </div>
      </td>
    </tr>

  );
};
export default EmptyTable;
