import classes from './style/Fullname.module.scss';
import { setShortText, capitalizeWords } from '../../core/helpers/utils';
import minus from '../../assets/image/Icons/minus.svg';

interface IFullname {
  firstname: string;
  lastname: string;
  length?: number;
}
const Fullname = ({ firstname, lastname }: IFullname) => {
  const isFullname = firstname && lastname;
  const fullname = (firstname ? firstname + ' ' : '') + (lastname ? lastname : '');

  

  const setvalidname = () => {
    if (isFullname) {
      return capitalizeWords(setShortText({ text: fullname, length: 15 }));
    } else if (firstname) {
      return capitalizeWords(setShortText({ text: firstname, length: 15 }));
    } else if (lastname) {
      return capitalizeWords(setShortText({ text: lastname, length: 15 }));
    } else {
      return <img src={minus} alt="minus" />;
    }
  };
  return (
    <div className={classes.fullname} title={capitalizeWords(fullname)?? undefined}>
      {setvalidname()}
    </div>
  );
};
export default Fullname;
