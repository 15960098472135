import BaseRepository from '../../../baseRepository/BaseRepository';
const resource = '/companies';

const SettingRepository = {
  getSetting(id: string) {
    return BaseRepository.get(`${resource}/${id}/setting`);
  },

  updateSetting(
    id: string,
    body: {
      name: string;
      address: string;
      calender_type: string;
      duration: number; //mili second
      weekly_off_days: number[];
      start_time: string; // hh:mm
      flextime: string;
      holidays: string[];
    }
  ) {
    return BaseRepository.patch(`${resource}/${id}/setting`, body);
  },
};
export default SettingRepository;
