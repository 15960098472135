import Backdrop from '@mui/material/Backdrop';
import ClockLoader from 'react-spinners/ClockLoader';
import {useTranslation} from "../../providers/locale-provider";

interface LoadingComponent {
  loading: boolean;
}

const Loading = (props: LoadingComponent) => {
    const {t9n} = useTranslation();
    return (
        props.loading ? (
            <Backdrop open={props.loading}>
                <ClockLoader size={80} color="#eee" />
                <p className={`waiting-text`}>{t9n.loading_text}</p>
            </Backdrop>
        ) : null
    );
};
export default Loading;
