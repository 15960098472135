export const monthlyColumnWidths = [
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 120 },
  { wpx: 120 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 120 },
  { wpx: 90 },
  { wpx: 90 },
  { wpx: 120 },
];
