import { ReactNode } from 'react';
import classes from './style/AuthLayout.module.scss';
import clock from '../../assets/image/logo/clock-watch-square.svg';

interface LayoutProps {
  children: ReactNode;
  subHeader: string;
  headerText: string;
}
const AuthLayout = ({ children, subHeader, headerText}: LayoutProps) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={classes.authLayout}>
      <div className={classes.blueEllipse}></div>
      <div className={classes.orangeEllipse}></div>
      <div className={classes.purpleEllipse}></div>
      <div className={classes.formLayer}>
        <div className={` d-flex login`}>
          <div className={classes.login}>
            <div className={classes.header}>
              <div className={classes.logo}>
                <img src={clock} alt="clock logo"></img>
              </div>
              <div className={classes.welcomeText}>
                <div className={classes.header}>
                  <h1>{headerText}</h1>
                </div>
                <div className={classes.subHeader}>
                  <h6>{subHeader}</h6>
                </div>
              </div>
            </div>
            {children}
            <div className={classes.copyRight}>
              <span>© Rayka Clock {currentYear} - With ❤️ to All Colleagues </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;
