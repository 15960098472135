import { Route, Routes } from 'react-router-dom';
import Requests from '../pages/Requests';

const RequestsManagementRouter = () => {
  return (
    <Routes>
      <Route path="/:pageType/:pageNumber" exact element={<Requests />} />
    </Routes>
  );
};
export default RequestsManagementRouter;
