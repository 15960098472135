import { Route, Routes } from 'react-router-dom';
import EditProfile from '../pages/EditProfile';

const ProfileRouter = () => {
  return (
    <Routes>
      <Route path="/" exact element={<EditProfile />} />
    </Routes>
  );
};

export default ProfileRouter;
