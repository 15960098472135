import classes from './style/Auth.module.scss';
import Input from '../../../components/base/Input';
import BaseButton from '../../../components/base/BaseButton';
import { useEffect, useState } from 'react';
import { RepoFactory } from '../../../baseRepository/Factory';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../store/auth-slice';
import { errorActions } from '../../../store/error-slice';
import { AxiosError, AxiosResponse } from 'axios';
import InputPassword from '../../../components/base/InputPassword';
import Loading from '../../../components/base/Loading';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';
import AuthLayout from '../../../view/layouts/AuthLayout';
import { validatePassword } from '../../../core/helpers/FormValidation';
import info from '../../../assets/image/Icons/info-circle.svg';

const authRepository = () => RepoFactory.get(RepoType.Auth);

const RegisterAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t9n } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [password, setPassword] = useState('');
  const [allowNameModification, setAllowNameModification] = useState<boolean>(true);
  const [usernameInValidMsg, setUsernameInValidMsg] = useState<string>('');
  const [errors, setErrors] = useState({
    username: false,
    usernameContainSpace: false,
    firstName: false,
    lastName: false,
    password: false,
  });

  const setInfo = (token: string) => {
    var JWTtoken = token.split('.')[1];
    var base64 = JWTtoken.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    setFirstName(JSON.parse(jsonPayload).name);
    setLastName(JSON.parse(jsonPayload).last_name);
    setAllowNameModification(JSON.parse(jsonPayload).allowNameModification);
  };

  const handleInputChange = (field: string, value: string) => {
    switch (field) {
      case 'username':
        setUsername(value);
        break;
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastname':
        setLastName(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
    setErrors({ ...errors, [field]: false });
    if (username.includes(' ')) {
      setErrors({ ...errors, usernameContainSpace: false });
    }
  };
  const validateForm = () => {
    const usernameContainSpace = username.includes(' ');
    const usernameLengthError = username.length === 0;
    const newErrors = {
      username: username.length === 0,
      usernameContainSpace: usernameContainSpace,
      firstName: firstName.length === 0,
      lastName: lastName.length === 0,
      password: password.length === 0 || !validatePassword(password),
    };
    usernameLengthError && setUsernameInValidMsg(t9n.employee_username_invalid_error_text);
    usernameContainSpace && setUsernameInValidMsg(t9n.username_contain_apaces_error_text);

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const resetInfo = () => {
    setUsername('');
    setFirstName('');
    setLastName('');
    setPassword('');

    setErrors({
      username: false,
      usernameContainSpace: false,
      firstName: false,
      lastName: false,
      password: false,
    });
  };
  const goToNextPage = async (link: string) => {
    navigate(link);
  };
  const confirmAccount = async () => {
    if (!validateForm()) {
      setLoading(false);
      return;
    }

    setLoading(true);
    let body = {
      username,
      name: firstName,
      last_name: lastName,
      password,
      token,
    };

    await authRepository()
      .confirmAccount(body)
      .then(async (res: AxiosResponse<any>) => {
        setLoading(false);
        resetInfo();
        goToNextPage('/');
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  useEffect(() => {
    token && setInfo(token);
  }, [token]);

  return (
    <AuthLayout headerText={t9n.register_account_text} subHeader={t9n.login_page_subtitle}>
      <div className={classes.formSection}>
        <Loading loading={loading} />
        <Input
          name={'username'}
          type={'text'}
          placeholder={t9n.username_placeholder}
          title={t9n.username_text}
          value={username}
          handleChange={(e) => handleInputChange('username', e.currentTarget.value)}
          isInvalid={errors.username || errors.usernameContainSpace}
          inValidmsg={usernameInValidMsg}
        />
        {!allowNameModification && (
          <div className={classes.infoText}>
            <img src={info} alt="" /> You are not allowed to change First Name and Last Name due to company settings.
          </div>
        )}
        <Input
          name={'name'}
          type={'text'}
          required={true}
          placeholder={t9n.first_name_text}
          title={t9n.first_name_text}
          value={firstName}
          handleChange={(e) => handleInputChange('firstName', e.currentTarget.value)}
          isInvalid={errors.firstName}
          inValidmsg={t9n.enter_first_name}
          readOnly={!allowNameModification}
        />
        <Input
          name="lastName"
          type={'text'}
          title={t9n.last_name_text}
          required={true}
          placeholder={t9n.last_name_text}
          value={lastName}
          handleChange={(e) => handleInputChange('lastname', e.currentTarget.value)}
          isInvalid={errors.lastName}
          inValidmsg={t9n.enter_last_name}
          readOnly={!allowNameModification}
        />
        <InputPassword
          name="newPassword"
          placeholder={t9n.password_text}
          title={t9n.password_text}
          handleChange={(e) => handleInputChange('password', e.currentTarget.value)}
          isInvalid={errors.password}
          inValidmsg={t9n.check_strong_password_text}
        />

        <BaseButton title={t9n.login_btn_text} hasBlock={true} onClickHandler={confirmAccount} />
      </div>
    </AuthLayout>
  );
};
export default RegisterAccount;
