import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import DefaultLayout from '../../../view/layouts/DefaultLayout';
import DefaultUserStatus from '../../../view/layouts/DefaultUserStatus';
import classes from './style/request.module.scss';
import { RepoFactory } from '../../../baseRepository/Factory';
import { AxiosError, AxiosResponse } from 'axios';
import { errorActions } from '../../../store/error-slice';
import TabBar from '../../../components/base/TabBar';
import Tooltip from '@mui/material/Tooltip';
import PendingRequestList from '../components/PendingRequestsList';
import UnapprovedRequestList from '../components/UnapprovedRequestsList';
import ApprovedRequestList from '../components/ApprovedRequestsList';
import CanceledRequestList from '../components/CanceledRequestsList';
import { useTranslation } from '../../../providers/locale-provider';
import Loading from '../../../components/base/Loading';
import { convertToTime, getCurrentYearDate } from '../../../core/helpers/FormatDate';
import { requestActions } from '../store/request-slice';
import { useNavigate, useParams } from 'react-router-dom';
import { IAppLocale, Language } from '../../../locales';
import { RepoType, TimeOffType } from '../../../types/sharedTypes';
import { RequestStatus } from '../../RequestsManagement/components/types/type';

const requestsRepository = () => RepoFactory.get(RepoType.Requests);

const Requests = () => {
  const navigate = useNavigate();
  const { pageType } = useParams();
  const { pageNumber } = useParams();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [selectedTab, setSelectedTab] = useState(pageNumber);
  const [prevTab, setPrevTab] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [comments, setComment] = useState({ approved: 0, Denied: 0 });
  const tabItems = [
    { name: t9n.leave_request_tab_pending, value: TimeOffType.Pending },
    { name: t9n.leave_request_tab_denied, value: TimeOffType.Denied },
    { name: t9n.leave_request_tab_approved, value: TimeOffType.Approved },
    { name: t9n.leave_request_tab_canceled, value: TimeOffType.Canceled },
  ];
  const TABS_MAP: Record<string, JSX.Element> = {
    [TimeOffType.Pending]: <PendingRequestList />,
    [TimeOffType.Denied]: <UnapprovedRequestList />,
    [TimeOffType.Approved]: <ApprovedRequestList />,
    [TimeOffType.Canceled]: <CanceledRequestList />,
  };

  const handleComments = (type: TimeOffType) => {
    setLoading(true);
    let params = {
      status: type === TimeOffType.Approved ? RequestStatus.Approved : RequestStatus.UnApproved,
      page: 1,
      pageSize: itemsPerPage,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };
    requestsRepository()
      .getRequests(params)
      .then((result: AxiosResponse<any>) => {
        setLoading(false);
        const commentsNumber = result.data?.result.numberOfUnreadComments;
        setComment((prevComments) => {
          if (type === TimeOffType.Approved) {
            return { ...prevComments, approved: commentsNumber };
          } else if (type === TimeOffType.Denied) {
            return { ...prevComments, Denied: commentsNumber };
          }
          return prevComments;
        });
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  useEffect(() => {
    setSelectedTab(pageType);
    handleComments(TimeOffType.Approved);
    handleComments(TimeOffType.Denied);
  }, [pageType]);

  const tabSelectHandler = (tabValue: string) => {
    navigate(`/time-off/${tabValue}/1`);
  };
  const [annual, setAnnual] = useState<{ totalAnnual: number; usedAnnual: number }>({ totalAnnual: 0, usedAnnual: 0 });
  const [infoAnnual, setInfoAnnual] = useState<{ day: number; hours: number; minutes: number }>({
    day: 0,
    hours: 0,
    minutes: 0,
  });

  const getAnnualLeaves = () => {
    setLoading(true);
    const params = {
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };
    requestsRepository()
      .annualLeaves(params)
      .then((result: AxiosResponse<any>) => {
        const res = result.data?.result;
        const time = convertToTime(result.data.result.usedAnnual);
        const dailyWorkingHour = result.data.result.dailyWorkingHour / 60;
        let day = Math.floor(time.hours / dailyWorkingHour);
        const minute = time.minutes;
        setInfoAnnual({ day: day, hours: time.hours % dailyWorkingHour, minutes: minute });

        if (minute || time.hours % dailyWorkingHour) {
          day = day + 1;
        }
        setAnnual({ totalAnnual: res.totalAnnual, usedAnnual: day });

        setLoading(false);
      })
      .catch((error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      });
  };

  useEffect(() => {
    getAnnualLeaves();
  }, []);

  return (
    <DefaultLayout>
      <Loading loading={loading} />
      <section className={`${classes.header} mb-4 mt-4`}>
        <DefaultUserStatus />
        <div className={`d-flex mb-3`}>
          <div className={`${classes.cardHeader} col-6 pe-2`}>
            <div>
              <p className={`${classes.value} mb-1`}>{annual?.totalAnnual}</p>
              <p className={`${classes.title} mb-0`}>{t9n.annual_timeOff_text}</p>
            </div>
          </div>
          <div className={`${classes.cardHeader} col-6 pe-2`}>
            <div>
              <Tooltip
                title={
                  <section>
                    <h6 className="text-center text-sm">{t9n.used_annual_leave_text}</h6>
                    <div className="d-flex">
                      <p className="me-2 mb-0">{infoAnnual.day + 'day '}</p>
                      <p className="me-2 mb-0">{infoAnnual.hours + 'h '}</p>
                      <p className="mb-0">{infoAnnual.minutes + 'm '}</p>
                    </div>
                  </section>
                }
                placement="bottom-start"
                arrow>
                <section>
                  <p className={`${classes.value} mb-1`}>{annual?.usedAnnual} d</p>
                  <p className={`${classes.title} mb-0`}>{t9n.used_annual_leave_text}</p>
                </section>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={`mb-3`}>
          {true ? (
            <TabBar
              tabs={tabItems}
              onClickHandler={tabSelectHandler}
              selectedValue={selectedTab}
              commetCounter={comments}
            />
          ) : (
            <TabBar tabs={tabItems} onClickHandler={tabSelectHandler} selectedValue={selectedTab} />
          )}
        </div>
        {TABS_MAP[selectedTab]}
      </section>
    </DefaultLayout>
  );
};
export default Requests;

export const REQUESTS_LEAVE_REASON_T9N_MAP: Record<string, (t9n: IAppLocale) => string> = {
  ['Vacation']: (t9n) => t9n.leave_reason_vacation,
  ['Sick Leave']: (t9n) => t9n.leave_reason_sick,
  ['Academic']: (t9n) => t9n.leave_reason_academic,
  ['Other']: (t9n) => t9n.leave_reason_other,
};

export const REQUESTS_LEAVE_TYPE_T9N_MAP: Record<string, (t9n: IAppLocale) => string> = {
  ['daily']: (t9n) => t9n.leave_type_daily,
  ['hourly']: (t9n) => t9n.leave_type_hourly,
};
