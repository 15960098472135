import classes from './style/RequestList.module.scss';
import Table from '../../../components/base/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { convertDate, different, getCurrentYearDate } from '../../../core/helpers/FormatDate';
import { convertTime } from '../../TimeSheet/actions/timesheet';
import { RepoFactory } from '../../../baseRepository/Factory';
import { IRequest } from '../repository/RequestRepository';
import { AxiosResponse, AxiosError } from 'axios';
import { errorActions } from '../../../store/error-slice';
import DetailsRequest from './DetailsRequest';
import DeleteRequest from './DeleteRequest';
import Toast from '../../../components/base/Toast';
import Loading from '../../../components/base/Loading';
import { LeaveType } from '../types/type';
import { useTranslation } from '../../../providers/locale-provider';
import { RootState } from '../../../store';
import { requestActions } from '../store/request-slice';
import { computePageNumber } from '../../../core/helpers/pagination';
import { REQUESTS_LEAVE_REASON_T9N_MAP, REQUESTS_LEAVE_TYPE_T9N_MAP } from '../pages/Requests';
import { useNavigate, useParams } from 'react-router-dom';
import { RepoType } from '../../../types/sharedTypes';

const requestsRepository = () => RepoFactory.get(RepoType.Requests);

const PendingRequestList = () => {
  const navigate = useNavigate();
  const { pageType } = useParams();
  const { pageNumber } = useParams();
  const { t9n } = useTranslation();
  const dispatch = useDispatch();
  const newRequestAdded = useSelector((state: RootState) => state.request.newRequestAdded);
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [requestsList, setRequestsList] = useState<IRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [detailsRequest, setDetailsRequest] = useState<boolean>(false);
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [showToast, setShowToast] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const handleToastClose = () => setShowToast(false);
  const getRequests = (pageNumber: number) => {
    setLoading(true);
    const computedPageNumber = computePageNumber(
      deleteRequest,
      newRequestAdded,
      requestsList.length,
      pageNumber,
      totalRecords,
      totalPage,
      itemsPerPage,
      true
    );
    let params = {
      status: 'pending',
      page: computedPageNumber,
      pageSize: itemsPerPage,
      startYearDate: getCurrentYearDate().start,
      endYearDate: getCurrentYearDate().end,
    };
    requestsRepository()
      .getRequests(params)
      .then((result: AxiosResponse<any>) => {
        const data = result.data?.result?.data;
        setRequestsList(data);
        setTotalPage(result.data?.result?.metadata?.totalPages);
        setTotalRecords(result?.data?.result?.metadata?.totalRecords);
        if (deleteRequest) {
          setDeleteRequest(false);
          setShowToast(true);
          setCurrentPage(computedPageNumber);
        }
        if (newRequestAdded) {
          dispatch(requestActions.setNewRequestAdded(false));
          setCurrentPage(computedPageNumber);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
  };

  const handleDetailsRequest = (status: boolean) => setDetailsRequest(status);
  const handleDeleteRequest = (status: boolean) => setDeleteRequest(status);

  useEffect(() => {
    getRequests(currentPage);
  }, [pageNumber]);
  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/time-off/${pageType}/${currentPage}`);
    }
  }, [currentPage]);

  useEffect(() => {
    if (deleteRequest) getRequests(currentPage);
  }, [deleteRequest]);

  useEffect(() => {
    if (newRequestAdded) getRequests(currentPage);
  }, [newRequestAdded]);

  const sendDataForTable = () => {
    let dataArray: any = [];
    requestsList.forEach((item: IRequest, index) => {
      dataArray.push({
        items: [
          <span className={`text-center`}>
            <p className={`text-center`}>{convertTime(item.request_datetime)}</p>
            {convertDate('MMM D, YYYY', item.request_datetime ? +item.request_datetime * 1000 : 0)}
          </span>,
          <span className={`text-center`}>
            {item.type === LeaveType.Hourly && <p className={`text-center`}>{convertTime(item.start_datetime)}</p>}
            {convertDate('MMM D,YYYY', +item.start_datetime * 1000)}
          </span>,
          <span className={`text-center`}>
            {item.type === LeaveType.Hourly && <p className={`text-center`}>{convertTime(item.end_datetime)}</p>}

            {convertDate('MMM D,YYYY', +item.end_datetime * 1000)}
          </span>,
          <span className={`text-center`}>
            {different(+item.start_datetime * 1000, +item.end_datetime * 1000, item.type)(t9n)}
          </span>,
          <span className={`text-center`}>{REQUESTS_LEAVE_REASON_T9N_MAP[item.reason](t9n)}</span>,
          <div className={classes.actions}>
            <DeleteRequest requestId={item.id!} handleDeleteRequest={handleDeleteRequest} />
            <DetailsRequest request={item} handleDetailsRequest={handleDetailsRequest} />
          </div>,
        ],
      });
    });
    return dataArray;
  };
  return (
    <>
      {showToast && <Toast message={'The Request has been canceled.'} onClose={handleToastClose} />}
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <Table
          currentPage={currentPage - 1}
          totalPage={totalPage}
          changePage={handleChangePage}
          buttonHeader={true}
          textCenter={true}
          label={t9n.requests_text}
          message={t9n.no_request_text}
          headers={[
            <span className={classes.tableHeader}>{t9n.request_date_text}</span>,
            <span className={classes.tableHeader}>{t9n.start_text}</span>,
            <span className={classes.tableHeader}>{t9n.end_text}</span>,
            <span className={classes.tableHeader}>{t9n.timeOff_duration_text}</span>,
            <span className={classes.tableHeader}>{t9n.time_off_reason_text}</span>,
            '',
          ]}
          data={sendDataForTable()}
        />
      )}
    </>
  );
};
export default PendingRequestList;
