import moment from 'jalali-moment';
import { isDefined } from '../../../core/helpers/utils';
import { Optional, OptionalOrMissing } from '../../../types/sharedTypes';
export const getTodayDate = () => {
  const today = new Date();
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const day = today.getDate();
  const yearAndMonth = ' ' + month + ' ' + year;
  return { day, yearAndMonth };
};
export const getLastDayOfYear = (): String => {
  const currentYear = new Date();
  const dt = new Date(currentYear.getFullYear(), new Date().getMonth(), new Date().getDate());

  return (
    dt.getFullYear() + '-' + String(dt.getMonth() + 1).padStart(2, '0') + '-' + String(dt.getDate()).padStart(2, '0')
  );
};
export const getFirstDayOfYear = (): String => {
  const currentYear = new Date();
  const dt = new Date(currentYear.getFullYear(), 0, 1);
  return (
    dt.getFullYear() -
    1 +
    '-' +
    String(dt.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(dt.getDate()).padStart(2, '0')
  );
};
export const convertTime = (timestamp: OptionalOrMissing<string>) => {
  if (!isDefined(timestamp)) {
    return;
  }

  const date = new Date(+timestamp * 1000);
  const hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  return hours + ':' + minutes + ' ';
};
export const getDate = (time?: string) => {
  let date = new Date();
  if (isDefined(time)) {
    date = new Date(Number(time) * 1000);
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return year + '-' + month + '-' + day;
};
export const currentMonth = () => {
  let m = moment();
  moment.locale('fa');
  const startOfMonth = m.startOf('month').format('YYYY-MM-DD');
  const endOfMonth = m.endOf('month').format('YYYY-MM-DD');
  moment.locale('en');
  const start = moment.from(startOfMonth, 'fa', 'YYYY/MM/DD').format('YYYY-MM-DD');

  const end = moment.from(endOfMonth, 'fa', 'YYYY/MM/DD').format('YYYY-MM-DD');
  return {
    start_date: start,
    end_date: end,
  };
};
export const compareDate = (timeA: string, timeB: string) => {
  const dateA = getDate(timeA);
  const dateB = getDate(timeB);

  return dateA === dateB;
};
