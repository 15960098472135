export enum LeaveType {
  Daily = 'daily',
  Hourly = 'hourly',
}
export const LEAVE_TYPE_DATA: Record<string, { value: string; title: string }> = {
  daily: { value: 'daily', title: 'Daily' },
  hourly: { value: 'hourly', title: 'Hourly' },
};
export enum LeaveStatus {
  Pending = 'pending',
  Approved = 'approved',
  Canceled = 'canceled',
}

export enum LeaveReason {
  Academic = 'Academic',
  SickLeave = 'Sick Leave',
  Vacation = 'Vacation',
  Other = 'Other',
}
