/**
 *   <MultiSelectInput
          label='Name'
          placeHolder='Select an item ...'
          options={
            [
              { value: 'one', title: 'Name1' },
              { value: '2', title: 'Name2' },
              { value: '3', title: 'Name3' },
            ]
          }
        />
 */
import classes from './style/MultiSelectInput.module.scss';
import close from '../../assets/image/Icons/close.svg';
import search from '../../assets/image/Icons/search.svg';
import chevron_down from '../../assets/image/Icons/chevron_down.svg';
import { useEffect, useState, useRef } from 'react';
import { Option } from '../../types/sharedTypes';

interface MyComponentProps {
  label: string;
  placeHolder?: string;
  options?: Option[];
  defaultOptions?: Option[];
  disabledSearchIcon?: boolean;
  selectedOptions?: (selectedItem: Option[]) => void;
}

const SelectInput = (props: MyComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Option[]>([]);
  const [availableOptions, setAvailableOptions] = useState<Option[]>([]);

  useEffect(() => {
    setSelectedItem(props.defaultOptions ? props.defaultOptions : []);
    const optionsTemp = props.options
      ? props.options.filter((item) => {
          let selectedItemFalg = false;
          selectedItem.forEach((SelectItem) => {
            if (SelectItem.value === item.value) selectedItemFalg = true;
          });
          return !selectedItemFalg;
        })
      : [];
    setAvailableOptions(optionsTemp);
  }, [props.options, props.defaultOptions]);
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref]);
  const changeShowOptionStatus = () => {
    setShowOptions(!showOptions);
  };
  const seletedItemHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, selectItem: Option) => {
    let selectedItemTemp = selectedItem;
    selectedItemTemp.push(selectItem);
    setSelectedItem(selectedItemTemp);
    props.selectedOptions && props.selectedOptions(selectedItemTemp);
    let optionsTemp = availableOptions
      .filter((item) => item.value !== selectItem.value)
      .map((item) => {
        return item;
      });
    setAvailableOptions(optionsTemp);
    changeShowOptionStatus();
  };
  const removeSelectedItem = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, removeItem: Option) => {
    let optionsTemp = availableOptions;
    optionsTemp.push(removeItem);
    setSelectedItem(optionsTemp);
    let selectedItemTemp = selectedItem
      .filter((item) => item.value !== removeItem.value)
      .map((item) => {
        return item;
      });
    setSelectedItem(selectedItemTemp);
    props.selectedOptions && props.selectedOptions(selectedItemTemp);
  };
  const searchData = (e: React.FormEvent<HTMLInputElement>) => {
    setShowOptions(true);
    let searchOptionsTemp = props.options
      ?.filter((item) => item.title.includes(e.currentTarget.value))
      .map((item) => {
        return item;
      });
    let availableOptionsTemp: Option[] = [];
    searchOptionsTemp?.forEach((item) => {
      let selectedItemFalg = false;
      selectedItem.forEach((SelectItem) => {
        if (SelectItem.value === item.value) selectedItemFalg = true;
      });
      if (!selectedItemFalg) {
        availableOptionsTemp.push(item);
      }
    });
    setAvailableOptions(availableOptionsTemp);
  };
  return (
    <div className={classes.selectedInput} ref={ref}>
      <div className={`${classes.lable} mb-1`}>{props.label}</div>
      <div className={classes.selectInput}>
        <div className={selectedItem?.length ? classes.selectedText : classes.textSection}>
          {!props.disabledSearchIcon && (
            <div className={classes.searchIcon}>
              <img src={search} alt="search icon" />
            </div>
          )}

          {selectedItem?.length > 0 ? (
            <div className={classes.selectedItemList}>
              {selectedItem?.map((item, index) => {
                return (
                  <div key={index} className={classes.selectedItemBadge}>
                    <span> {item.title}</span>
                    <div className={classes.closeIcon} onClick={(e) => removeSelectedItem(e, item)}>
                      <img src={close} alt="close icon" />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <input className={classes.inputSection} placeholder={props.placeHolder} onChange={(e) => searchData(e)} />
          )}
          <div className={classes.iconSection} onClick={(e) => changeShowOptionStatus()}>
            <img src={chevron_down} alt="chevron_down icon" />
          </div>
        </div>
      </div>
      {showOptions && (
        <div className={classes.optionsBox}>
          {availableOptions.length > 0 ? (
            availableOptions.map((item, index) => {
              return (
                <div onClick={(e) => seletedItemHandler(e, item)} key={index} className={`${classes.item} }`}>
                  <span>{item.title}</span>
                </div>
              );
            })
          ) : (
            <div>Data not found</div>
          )}
        </div>
      )}
    </div>
  );
};
export default SelectInput;
