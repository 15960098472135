import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import classes from './style/ReloadAlert.module.scss';
import Info from '../../assets/image/Icons/red-info.svg';
import Modal from './Modal';
import BaseButton from './BaseButton';
import { useTranslation } from '../../providers/locale-provider';

const ReloadAlert: React.FC = () => {
  const reloadAlert = useSelector((state: RootState) => state.reloadAlert);
  const { t9n } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  if (!reloadAlert.showAlert) return null;

  return (
    <Modal
      size="sm"
      disableHeader={true}
      header={<img src={Info} alt="info" />}
      exitAction={handleReload}
      body={
        <div className={classes.modalBody}>
          <img className={classes.infoIcon} src={Info} alt="Info icon" />
          <p className={classes.alertText}>
            You {reloadAlert.loginAlert ? 'logged in' : 'logged out'} with another tab or window. Reload to refresh your
            session.
          </p>
        </div>
      }
      action={
        <div className={classes.buttonBox}>
          <div className={classes.deleteButton}>
            <BaseButton
              hasBlock={true}
              size="lg"
              title={t9n.reload_text}
              color="primary"
              hasRightIcon={false}
              hasLeftIcon={false}
              onClickHandler={handleReload}></BaseButton>
          </div>
        </div>
      }
    />
  );
};

export default ReloadAlert;
