import Table from '../../../components/base/Table';
import classes from './style/CompaniesList.module.scss';
import { useState } from 'react';
import { RepoFactory } from '../../../baseRepository/Factory';
import DeleteCompany from './DeleteCompany';
import EditCompany from './EditCompany';
import Loading from '../../../components/base/Loading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '../../../store/error-slice';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../../../store';
import { computePageNumber } from '../../../core/helpers/pagination';
import { useTranslation } from '../../../providers/locale-provider';
import { RepoType } from '../../../types/sharedTypes';
import { useParams } from 'react-router-dom';
import edit from '../../../assets/image/Icons/edit.svg';

const companiesRepository = () => RepoFactory.get(RepoType.Companies);
interface props {
  newCompanyAdded: boolean;
  handleNewCompany?: (status: boolean) => void;
}
const CompaniesList = (props: props) => {
  const { pageNumber } = useParams();
  const { t9n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = useSelector((state: RootState) => state.dashboard.itemsPerPage);
  const [loading, setLoading] = useState<boolean>(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [companyDeleted, setCompanyDeleted] = useState<boolean>(false);
  const [companyEdited, setCompanyEdited] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(pageNumber);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const handleDeleteCompany = (status: boolean) => {
    setCompanyDeleted(status);
  };

  const handleEditCompany = (status: boolean) => {
    setCompanyEdited(status);
  };

  const getAllCompanies = (pageNumber: number) => {
    setLoading(true);
    const computedPageNumber = computePageNumber(
      companyDeleted,
      props.newCompanyAdded || false,
      companiesList.length,
      pageNumber,
      totalRecords,
      totalPage,
      itemsPerPage
    );
    let params = {
      page: computedPageNumber,
      pageSize: itemsPerPage,
    };
    companiesRepository()
      .getAllCompanies(params)
      .then((res: AxiosResponse<any>) => {
        setTotalRecords(res?.data?.metadata?.totalRecords);
        setCompaniesList(res?.data?.data);
        setTotalPage(res?.data?.metadata.totalPages);
        setCurrentPage(computedPageNumber);
        if (props.handleNewCompany) {
          props.handleNewCompany(false);
        }
        if (companyDeleted) {
          setCurrentPage(computedPageNumber);
          setCompanyDeleted(false);
        }
        if (companyEdited) {
          setCurrentPage(computedPageNumber);
          setCompanyEdited(false);
        }
      })
      .catch((error: unknown) => {
        if (error instanceof AxiosError) {
          dispatch(errorActions.setHasError(true));
          dispatch(errorActions.setError(error.response?.data));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getAllCompanies(currentPage);
  }, [companyEdited, pageNumber]);
  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);
  useEffect(() => {
    if (currentPage !== pageNumber) {
      navigate(`/companies/${currentPage}`);
    }
  }, [currentPage]);
  useEffect(() => {
    if (companyDeleted || props.newCompanyAdded) getAllCompanies(currentPage);
  }, [companyDeleted, props.newCompanyAdded]);

  const sendDataForTable = () => {
    interface item {
      id: number;
      name: string;
      description: string;
    }

    let dataArray: any = [];

    companiesList.forEach((item: item) => {
      dataArray.push({
        items: [
          <span className={`cursor-pointer`} onClick={() => navigate(`/companies/managers/${item?.id}/1`)}>
            {item.name}
          </span>,
          <span className={`cursor-pointer`} onClick={() => navigate(`/companies/managers/${item?.id}/1`)}>
            {item.description}
          </span>,
          <div className={classes.actions}>
            <DeleteCompany companyId={item.id} handleDeleteCompany={handleDeleteCompany} />
            <div className={classes.editPen}>
              <img
                className={classes.companyIcon}
                onClick={() => navigate(`/companies/edit/${item?.id}`)}
                src={edit}
                alt="edit icon"
              />
            </div>
          </div>,
        ],
      });
    });
    return dataArray;
  };
  const handleChangePage = (SelectedPage: { selected: number }) => {
    setCurrentPage(SelectedPage.selected + 1);
    getAllCompanies(SelectedPage.selected + 1);
  };
  return (
    <>
      <Loading loading={loading} />

      <Table
        currentPage={currentPage - 1}
        totalPage={totalPage}
        changePage={handleChangePage}
        buttonHeader={false}
        label={t9n.navbar_link_companies}
        message={t9n.no_companies}
        headers={[<span>{t9n.name_text}</span>, <span>{t9n.leave_description_text}</span>, '']}
        data={sendDataForTable()}
      />
    </>
  );
};
export default CompaniesList;
